import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FileDisplay from "../../../Helpers/FileDisplay";
import {
  approveArticle,
  getArticleById,
  rejectArticle,
} from "../../../Api/createNews";
import { toast } from "react-toastify";

const ManageNewsTable = ({ data, setData, onPageChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [rejectedItemId, setRejectedItemId] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [newsData, setNewsData] = useState(data);
  const filteredData = data?.filter(
    (item) =>
      item?.title &&
      item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const handlePageChange = ({ selected }) => {
    onPageChange({ selected }); // Pass the page change event to the parent
  };

  const paginatedData = filteredData?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleReject = (id) => {
    setRejectedItemId(id);
    setShowModal(true);
  };
  // const handleApproval = (id) => {
  //   setApprovedItemId(id);
  //   setShowModal(true);
  // };

  const confirmReject = async () => {
    try {
      await rejectArticle(rejectedItemId);

      console.log("Before deletion:", newsData);
      // Update the state to remove the deleted item
      setData((prevData) =>
        prevData?.filter((item) => item?._id !== rejectedItemId)
      );
      toast.success(`News with ID ${rejectedItemId} rejected successfully`);
      // Reset the rejectedItemId and close the modal
      setRejectedItemId(null);
      setShowModal(false);
    } catch (error) {
      toast.error("Error rejecting news:", error);
      console.error("Error rejecting news:", error);
    }
  };
  const handleApproval = async (approvedItemId) => {
    try {
      await approveArticle(approvedItemId);
      // Update the state to remove the approved item
      setData((prevData) =>
        prevData?.filter((item) => item?._id !== approvedItemId)
      );
      toast.success(`News with ID ${approvedItemId} approved successfully`);
    } catch (error) {
      toast.error("Error approving news:", error);
      console.error("Error approving news:", error);
    }
  };

  const handleNewsClick = async (id) => {
    setIsLoading(true);
    try {
      const response = await getArticleById(id);
      setCurrentNews(response.data);
      setShowNews(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news details:", error);
      setIsLoading(false);
    }
  };

  const closeNewsModal = () => {
    setShowNews(false);
    setCurrentNews(null);
  };

  function formatDate(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString)?.toLocaleDateString(
      undefined,
      options
    );

    return formattedDate;
  }

  {
    isLoading && <div className="text-center">Loading...</div>;
  }
  return (
    <div>
      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      Reject News
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to reject this news article?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={confirmReject}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-xs"
                >
                  Reject
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showNews && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-[80%]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                {isLoading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                      {currentNews?.title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Category: {currentNews?.newsCategory}
                      </p>
                      <p className="text-sm text-gray-500">
                        Subcategory: {currentNews?.subCategory}
                      </p>
                      <p className="text-sm text-gray-500">
                        Author: {currentNews?.authorName}
                      </p>
                      <p className="text-sm text-gray-500">
                        Status: {currentNews?.status}
                      </p>
                      <p className="text-sm text-gray-500">
                        Live Update Type: {currentNews?.liveUpdateType}
                      </p>
                      <p className="text-sm text-gray-500">
                        Tags: {currentNews?.tags?.join(", ")}
                      </p>
                      <p className="text-sm text-gray-500">
                        Created At:{" "}
                        {new Date(currentNews?.createdAt).toLocaleDateString()}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentNews?.editorText,
                        }}
                        className="max-h-64 overflow-y-auto article-text ml-2 md:ml-16 mr-2 md:mr-16 mt-4"
                      ></div>
                      {currentNews?.file && (
                        <div className="mt-4">
                          <div className="image-box bg-gray-100">
                            {currentNews && currentNews?.length > 0 && (
                              <FileDisplay file={currentNews?.file} />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <button
                        onClick={closeNewsModal}
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search news..."
            className="border p-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Title
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Category
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Author
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Status
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Published Date
              </th>
              <th className="py-2 px-4 border-b text-center pl-12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b max-w-32 truncate sm:text-sm">
                  <button
                    onClick={() => handleNewsClick(item?._id)}
                    className="text-blue-500 hover:underline"
                  >
                    {item?.title}
                  </button>
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {item?.newsCategory}
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {item?.authorName}
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {item?.status}
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {formatDate(item?.createdAt)}
                </td>
                <td className="py-2 px-4 border-b flex self-end justify-end">
                  <button onClick={() => handleApproval(item?._id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 sm:size-3 text-blue-500 hover:underline"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                    <div className="sm:text-xs text-blue-500 hover:underline">
                      Approve
                    </div>
                  </button>
                  <button
                    onClick={() => handleReject(item?._id)}
                    className="text-red-500 hover:underline flex"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 sm:size-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                    <div className="sm:text-xs">Reject</div>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageNewsTable;
