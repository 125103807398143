import React from "react";
import SmallHorizontalCard from "../components/Cards/SmallHorizontalCard";
import MidCard from "../components/Cards/MidCard";
import BigCard from "../components/Cards/BigCard";
import UseNewsFetch from "../Common/FetchNews/UseNewsFetch";
import getDateString from "../Helpers/DateString";

const FourthSection = () => {
  const {
    data: midCards,
    loading: loading1,
    error: error1,
  } = UseNewsFetch("News", null, null, null, 1, "desc");
  const {
    data: smallHorizontalCard,
    loading: loading4,
    error: error4,
  } = UseNewsFetch("Nigeria", null, null, null, 4, "desc");
  const {
    data: bigCard,
    loading: loading5,
    error: error5,
  } = UseNewsFetch("Style", null, null, null, 1, "desc");
  const {
    data: smallHorizontalCard2,
    loading: loading3,
    error: error6,
  } = UseNewsFetch("World", null, null, null, 2, "desc");
  const {
    data: midCards2,
    loading: loading7,
    error: error7,
  } = UseNewsFetch("Health", null, null, null, 1, "desc");
  const {
    data: smallHorizontalCard3,
    loading: loading8,
    error: error8,
  } = UseNewsFetch("World", null, null, null, 1, "desc");

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/3 p-4">
        {midCards?.map((card, index) => (
          <MidCard
            key={index}
            link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
              card?._id
            }`}
            imageSrc={card?.file}
            text={card?.title}
            tags={card?.tags}
            loading={loading3}
            db={true}
            video={card?.video}
          />
        ))}
        {smallHorizontalCard &&
          smallHorizontalCard?.map((card, index) => (
            <SmallHorizontalCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
            />
          ))}
      </div>
      <div className="w-full md:w-2/3 p-4">
        {bigCard &&
          bigCard?.map((card, index) => (
            <BigCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
            />
          ))}
        {smallHorizontalCard2 &&
          smallHorizontalCard2?.map((card, index) => (
            <SmallHorizontalCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
            />
          ))}
      </div>
      <div className="w-full md:w-1/3 p-4">
        {midCards2?.map((card, index) => (
          <MidCard
            key={index}
            link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
              card?._id
            }`}
            imageSrc={card?.file}
            text={card?.title}
            tags={card?.tags}
            loading={loading3}
            db={true}
          />
        ))}
        {smallHorizontalCard3 &&
          smallHorizontalCard3?.map((card, index) => (
            <SmallHorizontalCard
              key={`cat1-${index}`}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
            />
          ))}
      </div>
    </div>
  );
};

export default FourthSection;
