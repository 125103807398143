import client from "./client";

export const getArticle = async (articleId) => {
  try {
    const { data } = await client.get(`/getNewsByArticleId/${articleId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getRelatedNews = async (articleId, tags, category) => {
  try {
    const response = await client.get(`/related-news`, {
      params: {
        articleId,
        tags,
        category,
      },
    });
    return response?.data?.relatedNews;
  } catch (error) {
    console.error("Error fetching related news:", error);
    return [];
  }
};
export const getNewsByTags = async (tags, limit = 10, page = 1) => {
  try {
    const response = await client.get(`/newsByTags`, {
      params: {
        tags,
        limit,
        page,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error fetching news by tags:", error);
    return [];
  }
};

export const getNewsAndBuzz = async () => {
  try {
    const response = await client.get(`/news-and-buzz`);
    return response?.data?.newsAndBuzz;
  } catch (error) {
    console.error("Error fetching News & Buzz articles:", error);
    return [];
  }
};

export const getUpNextArticles = async (articleId) => {
  try {
    const response = await client.get(`/up-next/${articleId}`);
    return response?.data?.upNextArticles;
  } catch (error) {
    console.error("Error fetching Up Next articles:", error);
    return [];
  }
};
export const getAllLiveUpdate = async () => {
  try {
    const { data } = await client.get(`/getAllLiveUpdates`);
    return data;
  } catch (error) {
    console.error("Error fetching Up Next articles:", error);
    return [];
  }
};
