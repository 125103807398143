import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoDisplay from "../../Helpers/VideoDisplay";
import { Video } from "react-feather";
import FileDisplay from "../../Helpers/FileDisplay";
import TextLoader from "../../Helpers/TextLoader";
import TruncateText from "../../Helpers/helper";

const RelatedCard = ({ link, imageSrc, tags, text, loading, video }) => {
  const [videoDuration, setVideoDuration] = useState(null);

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  return (
    <Link to={link}>
      <div className=" mt-2 pt-4">
        <div className="m-0 p-0">
          <div className="relative group border-b border-b-[#e6e6e6] mb-3 pb-3 flex max-w-full">
            <div className="w-full items-center text-center">
              {imageSrc ? (
                video ? (
                  <div className="relative object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                    <VideoDisplay
                      image={imageSrc}
                      video={video} // Pass video object for VideoDisplay
                      className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                      handleLoadedMetadata={handleLoadedMetadata}
                      showControls={false}
                    />
                    {videoDuration && (
                      <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                        <Video className="mr-1 text-white" size={11} />
                        <span className="text-white font-bold text-xs">
                          {videoDuration}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                    <FileDisplay
                      file={{
                        ...imageSrc,
                        isVideo: !!video,
                        duration: video?.duration,
                      }}
                      // file={imageSrc} // Pass file object for FileDisplay
                      className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                    />
                  </div>
                )
              ) : (
                <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                  <img
                    src={imageSrc}
                    alt=""
                    className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-fit">
            <h4 className="text-base self-start hover:underline hover:text-gray-700">
              {loading ? (
                <TextLoader className="mx-auto text-center !bg-transparent" />
              ) : (
                TruncateText(text, 8)
              )}
            </h4>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default RelatedCard;
