import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getBiographyDetails } from "../../Api/apis";
import { toast } from "react-toastify";
import { Facebook, Instagram } from "react-feather";
import { XIcon } from "react-share";
import { formatDateOfBirth } from "../../Helpers/helper";
import Related from "./Related";

const Biography = () => {
  const { bioId } = useParams();
  const [bioDetails, setBioDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the biography details by bioId
  const fetchBioDetails = async () => {
    try {
      setLoading(true);
      const response = await getBiographyDetails(bioId);
      // console.log("BioData::", response);
      setBioDetails(response);
    } catch (err) {
      toast.error("Error fetching biography details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBioDetails();
  }, [bioId]);

  if (loading) return <div>Loading...</div>;
  // Ensure bioDetails has the expected structure
  if (!bioDetails) return <div>No biography details found.</div>;

  return (
    <>
      <div className="flex flex-col md:flex-row items-start bg-black text-white h-auto">
        {/* Left Section: Person Details */}
        <div className="md:w-1/2 px-6 py-8 md:py-12">
          <Link
            to="/biographies"
            className="text-gray-400 hover:text-white text-sm mb-6 block"
          >
            ← BACK TO BIOGRAPHIES
          </Link>
          {bioDetails?.stageName && (
            <h1 className="text-4xl font-bold mb-4">{bioDetails?.stageName}</h1>
          )}

          <div className="space-y-4">
            {bioDetails?.realName && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Real Name
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.realName}
                </p>
              </div>
            )}
            {bioDetails?.alias && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm  md:w-1/3 w-[27%]">
                  Alias Name
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.alias}
                </p>
              </div>
            )}
            {bioDetails?.dateOfBirth && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Date of Birth
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {formatDateOfBirth(bioDetails?.dateOfBirth)}
                </p>
              </div>
            )}
            {bioDetails?.hometown && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Hometown
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.hometown}
                </p>
              </div>
            )}
            {bioDetails?.label && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Label
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.label}
                </p>
              </div>
            )}
            {bioDetails?.position && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Position
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.label}
                </p>
              </div>
            )}
            {bioDetails?.niche && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Niche
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.niche}
                </p>
              </div>
            )}
            {bioDetails?.genre && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Genre
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.genre}
                </p>
              </div>
            )}
            {bioDetails?.club && (
              <div className="flex justify-start items-center">
                <p className="text-gray-400 uppercase text-sm md:w-1/3 w-[27%]">
                  Club
                </p>
                <p className="font-semibold md:w-2/3 w-96">
                  {bioDetails?.club}
                </p>
              </div>
            )}

            <div className="md:flex space-x-3 items-center justify-end">
              <div className="flex space-x-3 items-center">
                {bioDetails?.socialMedia?.twitter ||
                  (bioDetails?.socialMedia?.x && (
                    <a
                      href={
                        bioDetails?.socialMedia?.twitter ||
                        bioDetails?.socialMedia?.x
                      }
                      className="text-white text-2xl"
                    >
                      <XIcon size={40} />
                    </a>
                  ))}
                {bioDetails?.socialMedia?.facebook && (
                  <a
                    href={bioDetails?.socialMedia?.facebook}
                    className="text-white text-2xl"
                  >
                    <Facebook size={28} color="blue" />
                  </a>
                )}
                {bioDetails?.socialMedia?.instagram && (
                  <a
                    href={bioDetails?.socialMedia?.instagram}
                    className="text-white text-2xl"
                  >
                    <Instagram size={28} color="Red" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Section: Image */}
        <div className="md:w-3/4 md:!h-96 h-[32rem] w-full bg-red-600 flex items-center justify-center md:rounded-bl-full rounded-tl-full">
          <img
            src={bioDetails?.image}
            alt={bioDetails?.realName}
            className="object-cover h-96 w-full md:h-auto md:w-[45rem]"
          />
        </div>
      </div>
      <div className="text-left items-start">
        <div className="flex justify-start items-start pt-16 pl-6 pb-8 md:pl-16">
          <h2 className="text-5xl font-bold">Biography</h2>
        </div>
        <div className="flex flex-col md:w-9/12 gap-8 items-center justify-center px-6 py-8 md:px-16 md:py-24">
          <p>{bioDetails?.bio}</p>
        </div>
      </div>
      <Related
        realName={bioDetails?.realName}
        stageName={bioDetails?.stageName}
      />
    </>
  );
};

export default Biography;
