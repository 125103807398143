import React, { useState } from "react";
import { Link } from "react-router-dom";
import FileDisplay from "../../Helpers/FileDisplay";
import TextLoader from "../../Helpers/TextLoader";
import TextLoaderError from "../../Helpers/TextLoaderError";
import { Video } from "react-feather";
import VideoDisplay from "../../Helpers/VideoDisplay";

const BigCard = ({ link, imageSrc, tag, text, loading, error, video }) => {
  const [videoDuration, setVideoDuration] = useState(null);

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };
  return (
    <Link to={link}>
      <div className="max-w-screen mx-auto mb-3 ">
        <div className="relative group">
          {/* {imageSrc ? (
            <FileDisplay
              fileName={imageSrc}
              className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
            />
          ) : (
            <img
              src={imageSrc}
              alt=""
              className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
            />
          )} */}
          {imageSrc ? (
            video ? (
              <div className="relative">
                <VideoDisplay
                  image={imageSrc}
                  video={video} // Pass video object for VideoDisplay
                  className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                  handleLoadedMetadata={handleLoadedMetadata}
                  showControls={false}
                />
                {videoDuration && (
                  <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                    <Video className="mr-1 text-white" size={11} />
                    <span className="text-white font-bold text-xs">
                      {videoDuration}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <FileDisplay
                file={{
                  ...imageSrc,
                  isVideo: !!video,
                  duration: video?.duration,
                }}
                // file={imageSrc} // Pass file object for FileDisplay
                className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
              />
            )
          ) : (
            <img
              src={imageSrc}
              alt=""
              className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
            />
          )}

          {tag ? (
            <div className="absolute bottom-0 left-0 bg-white text-red-600 text-xs px-2 py-1 font-bold">
              {tag}
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <h4 className="text-xl font-bold mb-2 mt-2 hover:underline hover:text-gray-700">
            {loading ? (
              <TextLoader className="mx-auto text-center !bg-transparent" />
            ) : (
              text
            )}
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default BigCard;
