import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../Api/auth";
import { Loader } from "react-feather";
import { useAuth } from "../Hooks";

export default function Logout() {
  const [loading, setLoading] = useState(false);
  const { setAuthState } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      setLoading(true);
      await logout();
      setAuthState({ token: null, role: null }); // Clear auth state
      localStorage.removeItem("authToken");
      localStorage.removeItem("userRole");
      setLoading(false);
      navigate("/login"); // Redirect to login page
      //   window.location.reload();
    } catch (error) {
      setLoading(false);
      console.error("Error logging out:", error);
    }
  };
  return (
    <button
      onClick={handleLogout}
      className="flex justify-center py-1 px-2 ml-3 border border-transparent text-md font-bold rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
    >
      {loading ? <Loader className="animate-spin" /> : "Logout"}
    </button>
  );
}
