import React from "react";
import MidCard from "../components/Cards/MidCard";
import TextOnly from "../components/Cards/TextOnly";
import ColumnHead from "../Common/ColumnHead/ColumnHead";
import UseNewsFetch from "../Common/FetchNews/UseNewsFetch";
import getDateString from "../Helpers/DateString";

const EntertainmentSection = () => {
  const {
    data: midCards,
    loading: loading1,
    error: error1,
  } = UseNewsFetch("Entertainment", "Videos", null, null, 1, "desc");
  const {
    data: textOnly,
    loading: loading2,
    error: error2,
  } = UseNewsFetch(null, null, "Music", null, 4, "desc");
  const {
    data: midCards2,
    loading: loading3,
    error: error3,
  } = UseNewsFetch("Entertainment", "Celebrity", null, null, 1, "desc");
  const {
    data: textOnly2,
    loading: loading4,
    error: error4,
  } = UseNewsFetch("Entertainment", "Songs", null, null, 5, "desc");
  const {
    data: midCards3,
    loading: loading5,
    error: error5,
  } = UseNewsFetch("Entertainment", "Music", null, null, 1, "desc");
  const {
    data: textOnly3,
    loading: loading6,
    error: error6,
  } = UseNewsFetch("Entertainment", "BBN", null, null, 4, "desc");

  return (
    <div>
      <div className="flex flex-wrap">
        {/* First Column (2 parts) */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-4">
          <ColumnHead columnHeadTag="VIDEOS" />
          {midCards &&
            midCards?.map((card, index) => (
              <MidCard
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                db={true}
                video={card?.video}
                bioName={card?.name?.stageName}
                bioId={card?.name?._id}
              />
            ))}
          {textOnly &&
            textOnly?.map((card, index) => (
              <TextOnly
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                text={card?.title}
                // loading={loading6}
              />
            ))}
        </div>

        {/* Second Column (1 part) */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-4">
          {/* Content for the second column */}
          <ColumnHead columnHeadTag="CELEBRITY" />
          {midCards2 &&
            midCards2?.map((card, index) => (
              <MidCard
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                bioName={card?.name?.stageName}
                bioId={card?.name?._id}
                db={true}
                video={card?.video}
              />
            ))}
          {textOnly2 &&
            textOnly2?.map((card, index) => (
              <TextOnly
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                text={card?.title}
                // loading={loading6}
              />
            ))}
        </div>

        {/* Third Column (1 part) */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-4">
          {/* Content for the third column */}
          <ColumnHead columnHeadTag="MUSIC" />
          {midCards3 &&
            midCards3?.map((card, index) => (
              <MidCard
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                bioName={card?.name?.stageName}
                bioId={card?.name?._id}
                db={true}
                video={card?.video}
              />
            ))}
          {textOnly3 &&
            textOnly3?.map((card, index) => (
              <TextOnly
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                text={card?.title}
                // loading={loading6}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default EntertainmentSection;
