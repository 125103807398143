import React from "react";

const VideoDisplay = ({
  video,
  image,
  handleLoadedMetadata,
  showControls = true,
  playVideo,
}) => {
  if (!video || !video?.url) {
    return null; // Handle case where video or video URL is missing
  }

  return (
    <div className="video-display">
      <video
        poster={image?.url}
        src={video?.url}
        className="video-player"
        autoPlay={playVideo}
        muted
        playsInline
        loop
        controls={showControls}
        onLoadedMetadata={handleLoadedMetadata}
      />
    </div>
  );
};

export default VideoDisplay;
