import React, { useState, useEffect } from "react";
import axios from "axios";
import client from "../../Api/client";

const SiteSettings = () => {
  const [settings, setSettings] = useState({});
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    siteTitle: "",
    siteDescription: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await client.get("/settings/get", {
          withCredentials: true,
        });
        setSettings(response.data);
        setFormData({
          siteTitle: response.data.siteTitle || "",
          siteDescription: response.data.siteDescription || "",
        });
      } catch (error) {
        console.error("Error fetching settings", error);
      }
    };

    fetchSettings();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("siteTitle", formData.siteTitle);
    data.append("siteDescription", formData.siteDescription);
    if (file) {
      data.append("siteLogo", file);
    }

    try {
      await client.put("/settings/update", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      alert("Settings updated successfully");
    } catch (error) {
      console.error("Error updating settings", error);
      alert("Error updating settings");
    }
  };

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8">
      <h1 className="text-2xl font-bold mb-6">Update Settings</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <label
            htmlFor="siteTitle"
            className="block text-lg font-medium mb-2 sm:mb-0 sm:w-1/3"
          >
            Site Title:
          </label>
          <input
            id="siteTitle"
            type="text"
            name="siteTitle"
            value={formData.siteTitle}
            onChange={handleChange}
            className="flex-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <label
            htmlFor="siteDescription"
            className="block text-lg font-medium mb-2 sm:mb-0 sm:w-1/3"
          >
            Site Description:
          </label>
          <textarea
            id="siteDescription"
            name="siteDescription"
            value={formData.siteDescription}
            onChange={handleChange}
            rows="4"
            className="flex-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <label
            htmlFor="siteLogo"
            className="block text-lg font-medium mb-2 sm:mb-0 sm:w-1/3"
          >
            Site Logo:
          </label>
          <input
            id="siteLogo"
            type="file"
            onChange={handleFileChange}
            className="flex-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
          />
        </div>
        <button
          type="submit"
          className="w-full sm:w-auto bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Update Settings
        </button>
      </form>
    </div>
  );
};

export default SiteSettings;
