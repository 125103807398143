import React from "react";
import { Helmet } from "react-helmet-async";
import { useSiteSettings } from "../Hooks/Settings";

const PrivacyPolicy = () => {
  const { siteTitle } = useSiteSettings();
  return (
    <div className="privacy-policy container mx-auto p-4">
      <Helmet>
        <title>
          {siteTitle}
          {`- Privacy Policy`}
        </title>
        <meta property="og:site_title" content={siteTitle} />
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Last updated: [Sep/11/2024]</p>

      <h2 className="text-xl font-semibold mt-6 mb-2">Introduction</h2>
      <p className="mb-4">
        Welcome to News Vist, your privacy is important to us. This Privacy
        Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website www.newsvist.com and use our
        services. By accessing or using our site, you agree to the collection
        and use of information in accordance with this policy.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        Information We Collect
      </h2>
      <p className="mb-4">
        We collect various types of information to provide and improve our
        services:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Personal Information:</strong> This includes information you
          provide to us such as your name, email address, and other contact
          details.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use our site,
          including IP address, browser type, pages visited, and time spent on
          the site.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies and
          similar technologies to enhance your experience and gather usage data.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        How We Use Your Information
      </h2>
      <p className="mb-4">We use the information we collect to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Improve our website and tailor content to your interests.</li>
        <li>
          Send newsletters, marketing communications, and updates, if you have
          opted in.
        </li>
        <li>Respond to your inquiries, comments, and support requests.</li>
        <li>Analyze usage patterns to enhance our services.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        Disclosure of Your Information
      </h2>
      <p className="mb-4">We may share your information with:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Service Providers:</strong> Third-party vendors who assist
          with website operations, analytics, and marketing.
        </li>
        <li>
          <strong>Legal Requirements:</strong> To comply with legal obligations
          or protect our rights, privacy, safety, or property.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">Security</h2>
      <p className="mb-4">
        We employ various security measures to protect your personal
        information. However, no online transmission or storage method is
        completely secure, so we cannot guarantee absolute security.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">Your Rights</h2>
      <p className="mb-4">
        Depending on your location, you may have certain rights regarding your
        personal information, including:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Accessing and updating your personal information.</li>
        <li>Requesting deletion of your personal data.</li>
        <li>Opting out of marketing communications.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        Changes to This Privacy Policy
      </h2>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy or need further
        assistance, please contact us at:
      </p>
      <p className="mb-4">
        <strong>Email:</strong> verboxupdates@gmail.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
