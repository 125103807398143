import React, { useEffect, useState } from "react";
import { getRelatedNews } from "../Api/news";
import getDateString from "../Helpers/DateString";
import RelatedCard from "../components/Cards/RelatedCard";

const RelatedArticle = ({ articleId, tags, category }) => {
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      try {
        const response = await getRelatedNews(articleId, tags, category);
        setRelatedArticles(response);
      } catch (error) {
        console.error("Error fetching related articles:", error);
      }
    };

    fetchRelatedArticles();
  }, [articleId, tags, category]);

  return (
    <>
      {relatedArticles && relatedArticles?.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Related Articles</h2>
          <div className="grid md:grid-cols-5 grid-cols-2 gap-4">
            {relatedArticles?.map((article, index) => (
              <RelatedCard
                key={index}
                link={`/${getDateString(article?.createdAt)}/${
                  article?.newsCategory
                }/${article?._id}`}
                imageSrc={article?.file}
                text={article?.title}
                video={article?.video}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default RelatedArticle;
