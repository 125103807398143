import React, { useEffect, useState } from "react";
import { getNewsAndBuzz } from "../Api/news";
import TagSmallCard from "../components/TagSmallCard";
import getDateString from "../Helpers/DateString";

const NewsAndBuzz = () => {
  const [newsAndBuzzArticles, setNewsAndBuzzArticles] = useState([]);

  useEffect(() => {
    const fetchNewsAndBuzz = async () => {
      try {
        const response = await getNewsAndBuzz();
        setNewsAndBuzzArticles(response);
      } catch (error) {
        console.error("Error fetching News & Buzz articles:", error);
      }
    };

    fetchNewsAndBuzz();
  }, []);

  return (
    <div className="mt-8">
      <div className="">
        {newsAndBuzzArticles?.map((article, index) => (
          <TagSmallCard
            key={index}
            link={`/${getDateString(article?.createdAt)}/${
              article?.newsCategory
            }/${article?._id}`}
            imageSrc={article?.file}
            text={article?.title}
            video={article?.video}
          />
        ))}
      </div>
    </div>
  );
};

export default NewsAndBuzz;
