import axios from "axios";
import React, { useEffect, useState } from "react";
import { commonModalClasses } from "../../utils/theme";
import { useNavigate } from "react-router-dom";
import { deleteImageFiles } from "../../Api/apis";
import { Loader } from "react-feather";
import client from "../../Api/client";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const navigate = useNavigate();

  const fetchImages = async () => {
    try {
      setLoading(true);
      const res = await client.get(`/image-gallery?page=${page}&limit=10`);
      setImages(res.data.images);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = async (id) => {
    try {
      setBusy(true);
      await deleteImageFiles(id);
      fetchImages();
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setBusy(false);
    }
  };

  const newImage = () => {
    navigate("/admin/news-management/Image-upload");
  };

  useEffect(() => {
    fetchImages();
  }, [page]);

  return (
    <div className={"mt-24 w-fit ml-10 " + commonModalClasses}>
      <h2 className="text-3xl font-bold -mb-5">Image Gallery</h2>
      <button
        className="px-2 bg-blue-500 text-white rounded-md"
        onClick={newImage}
      >
        {"<<Upload New Image"}
      </button>
      {loading ? (
        <div className="w-full flex justify-center text-center items-center">
          <Loader className="animate-spin" />
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-4">
          {images &&
            images?.map((image, index) => (
              <div key={index} className="relative">
                {busy ? (
                  <div className="w-full flex justify-center text-center items-center">
                    <Loader className="animate-spin" />
                  </div>
                ) : (
                  <>
                    <img
                      src={image?.files[0]?.url}
                      alt={image?.altText}
                      className="w-64 h-auto"
                    />
                    <button
                      className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                      onClick={() => deleteImage(image?._id)}
                    >
                      X
                    </button>
                  </>
                )}
              </div>
            ))}
        </div>
      )}

      <div className="flex justify-center mt-4">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          Previous
        </button>
        <span className="mx-4">
          Page {page} of {totalPages}
        </span>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Gallery;
