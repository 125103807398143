import React, { useEffect, useState } from "react";
import { fetchApprovedNewsList, getArticleById } from "../../../Api/createNews";
import Pagination from "./Pagination";
import FileDisplay from "../../../Helpers/FileDisplay";

const ApprovedNews = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showNews, setShowNews] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const itemsPerPage = 5;

  const filteredData = newsData?.filter(
    (item) =>
      item?.title &&
      item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const paginatedData = filteredData?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  useEffect(() => {
    fetchNewsData();
  }, [currentPage]);

  const fetchNewsData = async () => {
    setIsLoading(true);
    try {
      const { news, totalPages } = await fetchApprovedNewsList(
        currentPage,
        itemsPerPage
      );
      setIsLoading(false);
      console.log("news::", news);
      setNewsData(news);
      setTotalPages(totalPages);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching news data:", error);
    }
  };

  const handleNewsClick = async (id) => {
    setIsLoading(true);
    try {
      const response = await getArticleById(id);
      setCurrentNews(response);
      setShowNews(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news details:", error);
      setIsLoading(false);
    }
  };

  const closeNewsModal = () => {
    setShowNews(false);
    setCurrentNews(null);
  };

  function formatDate(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString)?.toLocaleDateString(
      undefined,
      options
    );

    return formattedDate;
  }

  {
    isLoading && <div className="text-center">Loading...</div>;
  }

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="mt-12 mx-4">
      <h1 className="text-3xl font-bold mb-4">Approved News</h1>
      {showNews && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-[80%]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                {isLoading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                      {currentNews?.title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Category: {currentNews?.newsCategory}
                      </p>
                      <p className="text-sm text-gray-500">
                        Subcategory: {currentNews?.subCategory}
                      </p>
                      <p className="text-sm text-gray-500">
                        Author: {currentNews?.authorName}
                      </p>
                      <p className="text-sm text-gray-500">
                        Status: {currentNews?.status}
                      </p>
                      <p className="text-sm text-gray-500">
                        Live Update Type: {currentNews?.liveUpdateType}
                      </p>
                      <p className="text-sm text-gray-500">
                        Tags: {currentNews?.tags?.join(", ")}
                      </p>
                      <p className="text-sm text-gray-500">
                        Created At:{" "}
                        {new Date(currentNews?.createdAt).toLocaleDateString()}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentNews?.editorText,
                        }}
                        className="max-h-64 overflow-y-auto article-text ml-2 md:ml-16 mr-2 md:mr-16 mt-4"
                      ></div>
                      {currentNews?.file && (
                        <div className="mt-4">
                          <div className="image-box bg-gray-100">
                            <FileDisplay file={currentNews?.file} />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <button
                        onClick={closeNewsModal}
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search news..."
            className="border p-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Title
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Category
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Author
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Status
              </th>
              <th className="py-2 px-4 border-b text-center sm:text-sm">
                Published Date
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b max-w-32 truncate sm:text-sm">
                  <button
                    onClick={() => handleNewsClick(item?._id)}
                    className="text-blue-500 hover:underline"
                  >
                    {item?.title}
                  </button>
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {item?.newsCategory}
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {item?.authorName}
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {item?.status}
                </td>
                <td className="py-2 px-4 border-b sm:text-sm">
                  {formatDate(item?.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination pageCount={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default ApprovedNews;
