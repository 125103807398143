import React, { useEffect, useState } from "react";
import { getUsers, updateUserStatus } from "../../Api/auth";
import { toast } from "react-toastify";
import BulkUserActions from "./BulkUserActions";

const AccountStatusControl = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const res = await getUsers();
      setUsers(res);
    } catch (error) {
      console.error("Error fetching users", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  const updateStatus = async (userId, status) => {
    try {
      await updateUserStatus(userId, status);
      toast.success("Account status updated successfully");
      setUsers((prevUsers) =>
        prevUsers?.map((user) =>
          user?._id === userId ? { ...user, status } : user
        )
      );
    } catch (error) {
      console.error("Error updating account status", error);
      toast.error("Error updating account status", error);
    }
  };

  const filteredUsers = users?.filter((user) => {
    const username = user.username || "";
    const email = user.email || "";

    return (
      username?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  });

  return (
    <div className="p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-4">Manage User Account Status</h2>
      <input
        type="text"
        placeholder="Search users..."
        className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Username
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Email
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Status
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user, index) => (
                <tr key={index} className="overflow-x-auto h-auto">
                  <td className="px-4 py-2 border-b border-gray-200">
                    {user.username}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200">
                    {user.email}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200">
                    <select
                      value={user.status}
                      onChange={(e) => updateStatus(user._id, e.target.value)}
                      className="px-2 py-1 border rounded-md focus:outline-none"
                      disabled={user.role === "Admin"}
                    >
                      <option value="active">Active</option>
                      <option value="suspended">Suspended</option>
                      <option value="deactivated">Deactivated</option>
                    </select>
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200">
                    <button
                      onClick={() => updateStatus(user._id, user.status)}
                      className="px-4 py-1 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                      disabled={user.role === "Admin"}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <BulkUserActions users={users} />
    </div>
  );
};

export default AccountStatusControl;
