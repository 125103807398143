import React from "react";

export default function TruncateText(text, maxWords) {
  const words = text.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "...";
  }
  return text;
}

export const getPoster = (images = []) => {
  const { length } = images;

  if (!length) return null;

  // if poster has more than 2 items then selecting second poster.
  if (length > 2) return images[2];

  // other wise the first one
  return images[0];
};

// utils/dateUtils.js

export const formatDateOfBirth = (dob) => {
  const date = new Date(dob);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Calculate age
  const currentYear = new Date().getFullYear();
  const birthYear = date.getFullYear();
  const age = currentYear - birthYear;

  return `${formattedDate} - Age ${age}`;
};
