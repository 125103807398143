import React from "react";
import HorizontalAd from "../components/HorizontalAd";

const ThirdAdv = () => {
  return (
    <>
      <div className="h-64 bg-white flex items-center justify-center flex-col">
        {/* <img src="/images/ThirdAdv/thirdadv.jpg" alt="" /> */}
        <HorizontalAd />
        {/* <p className="text-zinc-600 text-xs self-start mt-1 ml-15%">
          Advertisement
        </p> */}
      </div>
    </>
  );
};

export default ThirdAdv;
