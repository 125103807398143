import React, { useEffect, useState } from "react";
import { getUsers } from "../../Api/auth";
import axios from "axios";
import client from "../../Api/client";

const BulkUserActions = ({ users }) => {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectUser = (userId) => {
    setSelectedUserIds((prevIds) =>
      prevIds?.includes(userId)
        ? prevIds?.filter((id) => id !== userId)
        : [...prevIds, userId]
    );
  };

  const updateBulkStatus = async () => {
    try {
      await client.put("/bulk-update-status", {
        userIds: selectedUserIds,
        status,
      });
      alert("User statuses updated");
    } catch (error) {
      console.error("Error updating bulk statuses", error);
    }
  };

  //   const filteredUsers = users.filter((user) =>
  //     user.username.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  const filteredUsers = users?.filter((user) => {
    const username = user.username || "";

    return username?.toLowerCase()?.includes(searchTerm?.toLowerCase());
  });

  return (
    <>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold text-center mb-6">
          Bulk User Actions
        </h2>

        <div className="mb-4">
          <input
            type="text"
            placeholder="Search with username..."
            className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Select
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Username
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user, index) => (
                <tr key={index} className="overflow-x-auto h-auto">
                  <td className="px-4 py-2 border-b border-gray-200">
                    <input
                      type="checkbox"
                      checked={selectedUserIds?.includes(user?._id)}
                      onChange={() => handleSelectUser(user?._id)}
                    />
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200">
                    {user.username}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200">
                    {user.email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="active">Active</option>
            <option value="suspended">Suspended</option>
            <option value="deactivated">Deactivated</option>
          </select>
          <button
            onClick={updateBulkStatus}
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
            disabled={!selectedUserIds.length || !status}
          >
            Update Status
          </button>
        </div>
      </div>
    </>
  );
};

export default BulkUserActions;
