import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteUsers, getUserAnalytics, getUsers } from "../Api/auth";
import { toast } from "react-toastify";

const ManageUser = () => {
  const [users, setUsers] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [newUser, setNewUser] = useState({ email: "", password: "" });

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  };

  const fetchAnalytics = async () => {
    try {
      const res = await getUserAnalytics();
      setAnalytics(res);
    } catch (error) {
      console.error("Error fetching analytics", error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const res = await deleteUsers(userId);
      toast.success("User deleted successfully!");
      fetchUsers();
    } catch (error) {
      toast.error("Error deleting user:", error.message);
      console.error("Error deleting user:", error.message);
    }
  };

  useEffect(() => {
    fetchAnalytics();
    fetchUsers();
  }, []);
  return (
    <div className="mt-24 p-4">
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold text-center mb-6">
          User Management and Analytics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-lg font-semibold text-blue-600">
              Active Users
            </h3>
            <p className="text-4xl font-bold text-blue-800">
              {analytics.activeUsers}
            </p>
          </div>
          <div className="bg-yellow-100 p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-lg font-semibold text-yellow-600">
              Suspended Users
            </h3>
            <p className="text-4xl font-bold text-yellow-800">
              {analytics.suspendedUsers}
            </p>
          </div>
          <div className="bg-green-100 p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-lg font-semibold text-green-600">
              Total Users
            </h3>
            <p className="text-4xl font-bold text-green-800">
              {analytics.totalUsers}
            </p>
          </div>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="border-b">
            <th className="py-2 px-4 text-start">Email</th>
            <th className="py-2 px-4 text-start">Role</th>
            {/* <th className="py-2 px-4 text-start">Article Count</th> */}
            <th className="py-2 px-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id} className="border-b">
              <td className="py-2 px-4">
                <Link
                  to={`/admin/news-management/NewsList/user/${user._id}`}
                  className="text-blue-500"
                >
                  {user.email}
                </Link>
              </td>
              <td className="py-2 px-4">{user.role}</td>

              <td className="py-2 px-4 text-center">
                <button
                  onClick={() => deleteUser(user._id)}
                  className="text-red-500 "
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageUser;
