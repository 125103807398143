import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSiteSettings } from "../../Hooks/Settings";
import { useAuth } from "../../Hooks";
import { getIsAuth } from "../../Api/auth";
import { Facebook, Instagram, X } from "react-feather";
import { XIcon } from "react-share";

const Footer = ({ SearchText }) => {
  const [searchText, setSearchText] = useState("");
  const { siteLogo } = useSiteSettings();
  const [userRole, setUserRole] = useState(null);
  const currentYear = new Date().getFullYear();

  const { authState } = useAuth();
  const navigate = useNavigate();

  const { isLoggedIn } = authState;

  const fetchUserRole = async () => {
    try {
      const response = await getIsAuth();
      if (response?.user?.role) {
        setUserRole(response.user.role);
      } else {
        setUserRole(null);
      }
    } catch (error) {
      console.log(error);
      setUserRole(null);
    }
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      fetchUserRole();
    }
  }, [isLoggedIn]);

  // Handle redirect based on user role when clicking on Profile
  const handleProfileClick = () => {
    if (userRole === "Admin") {
      navigate("/Admin");
    } else if (userRole === "Editor") {
      navigate("/Editor");
    } else if (userRole === "Writer") {
      navigate("/Writer");
    } else {
      navigate("/"); // Default redirection for other roles or if role is undefined
    }
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    navigate(`/search/${searchText}`);
  };

  const categoriesRow1 = [
    {
      title: "World",
      items: [
        "Africa",
        "Americas",
        "Asia",
        "Australia",
        "China",
        "Europe",
        "India",
        "Middle East",
        "United Kingdom",
      ],
    },
    {
      title: "Politics",
      items: [
        "The Biden Presidency",
        "The Tinubu Presidency",
        "Facts First",
        "2024 Elections",
      ],
    },
    {
      title: "Business",
      items: ["Markets", "Tech", "Media", "Calculators", "Videos"],
    },
    {
      title: "Health",
      items: [
        "Life, But Better",
        "Fitness",
        "Food",
        "Sleep",
        "Mindfulness",
        "Relationships",
      ],
    },
    {
      title: "Sexual Health & Wellness",
      items: [
        "Safe Sex Practices",
        "Birth Control & Contraceptives",
        "Sexually Transmitted Infections (STIs)",
        "Sexual Education",
        "Sexual Intimacy",
        "Libido & Desire",
        "Sexual Pleasure",
        "Men’s Sexual Health",
        "Women’s Sexual Health",
        "Gender Identity & Expression",
      ],
    },
    {
      title: "Reproductive Health & Relationships",
      items: [
        "Fertility & Family Planning",
        "Pregnancy & Childbirth",
        "Menstrual Health",
        "Conception & Infertility",
        "Relationship Advice",
        "Communication in Relationships",
        "Emotional Wellness in Relationships",
        "Reproductive Rights",
        "Sex & Mental Health",
      ],
    },
    {
      title: "Entertainment",
      items: ["Movies", "Television", "Celebrity", "BBN"],
    },
    {
      title: "Tech",
      items: [
        "Innovate",
        "Gadget",
        "Foreseeable Future",
        "Mission: Ahead",
        "Upstarts",
        "Work Transformed",
        "Innovative Cities",
      ],
    },
    {
      title: "Style",
      items: [
        "Arts",
        "Design",
        "Fashion",
        "Architecture",
        "Luxury",
        "Beauty",
        "Video",
      ],
    },

    // Add other categories similarly
  ];
  const categoriesRow2 = [
    {
      title: "Travel",
      items: ["Destinations", "Food & Drink", "Stay", "News", "Videos"],
    },
    {
      title: "Sports",
      items: [
        "Football",
        "Tennis",
        "Golf",
        "Motorsport",
        "US Sports",
        "Olympics",
        "Climbing",
        "Esports",
        "Hockey",
      ],
    },
    {
      title: "Watch",
      items: [
        "Live TV",
        "Digital Studios",
        "VB Films",
        "TV Schedule",
        "TV Shows A-Z",
      ],
    },
    {
      title: "Features",
      items: [
        "As Equals",
        "Call to Earth",
        "Freedom Project",
        "Impact Your World",
        "Inside Africa",
        "VB Heroes",
        "All Features",
      ],
    },
    {
      title: "Weather",
      items: ["Climate", "Wildfire Tracker", "Video"],
    },
    {
      title: "More",
      items: ["Photos", "VB Profiles", "VB Leadership", "Work for VB"],
    },
    // Add other categories similarly
  ];

  return (
    <div className="bg-black p-4">
      <hr className="pb-5" />
      <div className="flex items-center p-2 mt-4">
        <input
          type="text"
          value={SearchText}
          className="h-8 px-2 w-full bg-white border-none rounded-1 outline-none"
          onChange={handleInputChange}
        />
        <button
          onClick={handleSearch}
          className="h-8 bg-white text-black px-2 rounded-r flex items-center font-bold"
        >
          Search <span className="ml-1 font-bold text-2xl pb-1">&#8594;</span>
        </button>
      </div>
      <div className="bg-black text-white p-4">
        <div className="grid md:grid-cols-7 grid-cols-3 pb-4">
          {categoriesRow1.map((category, index) => (
            <div key={index} className="border-b-3 border-white">
              <span className="font-bold text-lg">{category.title}</span>
              <div className="flex flex-col flex-wrap mt-2">
                {category.items.map((item, i) => (
                  <NavLink
                    key={i}
                    to={`/${category.title.toLowerCase()}/${item.toLowerCase()}`}
                    className="py-1 text-sm leading-none hover:underline"
                    // exact
                  >
                    {item}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="grid md:grid-cols-7 grid-cols-3 pb-4">
          {categoriesRow2.map((category, index) => (
            <div key={index} className="border-b-3 border-white">
              <span className="font-bold text-lg">{category.title}</span>
              <div className="flex flex-col flex-wrap mt-2">
                {category.items.map((item, i) => (
                  <NavLink
                    key={i}
                    to={`/${category.title.toLowerCase()}/${item.toLowerCase()}`}
                    className="py-1 text-sm leading-none hover:underline"
                    // exact
                  >
                    {item}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-black w-full pt-8 pb-8 px-5 flex flex-col items-start text white border-t border-b border-gray-700">
        <div className=" w-full flex items-center justify-between">
          <div className="flex items-center">
            <NavLink to="/">
              <img
                src={siteLogo || "/default-logo.png"}
                alt=""
                className="w-auto h-8 mr-4"
              />
            </NavLink>
            <div className="font-bold text-2xl text-white">NG</div>
          </div>
          <div className="flex flex-wrap items-center w-full">
            <NavLink
              to="/Watch"
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              Watch
            </NavLink>
            <NavLink
              to="/Listen"
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              Listen
            </NavLink>
            <NavLink
              to="/live-tv"
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              Live TV
            </NavLink>
            <div className="border-r border-gray-500 w-1 h-6 mx-4"></div>\
            <div className="text-white text-[0.937rem] font-bold ">
              Follow VB
            </div>
            <NavLink
              to="https://web.facebook.com/profile.php?id=61563799709889"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <Facebook />
              {/* <img
                src="images/Footer/Facebook.png"
                className="h-full w-full"
                alt=""
              /> */}
            </NavLink>
            <NavLink
              to="https://x.com/verboxupdates"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <XIcon size={35} />
              {/* <img src="images/Footer/X.png" className="h-full w-full" alt="" /> */}
            </NavLink>
            <NavLink
              to="/Instagram"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <Instagram />
              {/* <img
                src="images/Footer/Instagram.png"
                className="h-full w-full"
                alt=""
              /> */}
            </NavLink>
            <NavLink
              to="https://www.tiktok.com/@verboxupdates"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <img
                src="images/Footer/Tiktok.png"
                className="h-full w-full"
                alt=""
              />
            </NavLink>
            {isLoggedIn === true ? (
              <button
                onClick={handleProfileClick}
                className="text-white p-1 rounded text-[0.937rem] border border-white font-bold"
              >
                Profile
              </button>
            ) : (
              <button className="border border-white rounded-xl px-4 py-2 ml-5 text-white hover:bg-gray-800">
                <NavLink to="/login">Log In</NavLink>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="text-white text-sm flex flex-wrap items-center justify-start w-full mt-4 ">
        <NavLink to="/terms-of-use">Terms of Use</NavLink>
        <NavLink to="/privacy-policy" className="ml-4">
          Privacy Policy
        </NavLink>
        <NavLink to="/contact-Us" className="ml-4">
          Contact Us
        </NavLink>
        <NavLink to="/article-publishers-network/V2-VB-africa" className="ml-4">
          Become a Writer / Editor
        </NavLink>
        <NavLink to="/About" className="ml-4">
          About
        </NavLink>
      </div>
      <div className="text-white text-sm mt-4">
        © {currentYear} Cable News Network. A Hidden Treasure Technologies.
        Discovery Company. All Rights
      </div>
      <div className="text-white text-sm pb-6">
        Reserved. VB Sans ™ & © 2024 Cable News Network.
      </div>
    </div>
  );
};

export default Footer;
