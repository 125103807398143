import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getNewsByTags } from "../Api/news";
import VideoDisplay from "../Helpers/VideoDisplay";
import { Eye, Video } from "react-feather";
import FileDisplay from "../Helpers/FileDisplay";
import moment from "moment";
import getDateString from "../Helpers/DateString";
import TextOnly from "../components/Cards/TextOnly";
import TextLoader from "../Helpers/TextLoader";

const truncateText = (text, wordLimit) => {
  if (!text) return "";
  const words = text.split(" ");
  return (
    words.slice(0, wordLimit).join(" ") +
    (words.length > wordLimit ? "..." : "")
  );
};

const Tags = () => {
  const { tagName } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [totalNews, setTotalNews] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const observerRef = useRef();

  const [videoDuration, setVideoDuration] = useState(null);

  const getDateDifference = (createdAt) => {
    return moment(createdAt)?.fromNow();
  };

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  const fetchArticlesByTag = async (pageNumber = 1) => {
    try {
      setLoading(true);
      const response = await getNewsByTags(tagName, 5, pageNumber);
      if (pageNumber === 1) {
        setArticles(response.news); // Initial load
      } else {
        setArticles((prevArticles) => [...prevArticles, ...response.news]); // Append new articles
      }
      setTotalNews(response.totalNews);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getMoreArticles = () => {
    return articles?.slice(1, 4);
  };

  useEffect(() => {
    if (tagName) {
      fetchArticlesByTag(1);
    }
  }, [tagName]);

  // Infinite Scroll Observer
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isFetchingMore) {
        if (articles.length < totalNews) {
          setIsFetchingMore(true);
          setPage((prevPage) => prevPage + 1);
        }
      }
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [isFetchingMore, articles, totalNews]);

  useEffect(() => {
    if (isFetchingMore && page > 1) {
      fetchArticlesByTag(page).then(() => setIsFetchingMore(false));
    }
  }, [isFetchingMore, page]);

  if (loading && page === 1)
    return (
      <div className="h-screen flex justify-center items-center bg-white">
        <p className="text-light-subtle animate-pulse !bg-transparent">
          Please wait
        </p>
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-gray-800 mt-6 mb-8 border-b-8 border-red-600 border-l-8 w-32 uppercase">
        {tagName}
      </h1>
      {/* Left Side article */}
      <div className=" flex flex-wrap">
        <div className="md:w-3/4 w-full space-y-8">
          {articles?.length > 0 ? (
            articles?.map((article) => (
              <div
                key={article?._id}
                className="flex flex-col lg:flex-row bg-white shadow-md rounded-lg overflow-hidden"
              >
                {/* Left Image/Video Side */}
                <div className="lg:w-1/3 w-full h-64 lg:h-auto">
                  {article?.video ? (
                    <div className="relative w-full h-full">
                      <VideoDisplay
                        //   image={imageSrc}
                        video={article?.video}
                        className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                        handleLoadedMetadata={handleLoadedMetadata}
                        showControls={false}
                      />
                      {videoDuration && (
                        <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                          <Video className="mr-1 text-white" size={11} />
                          <span className="text-white font-bold text-xs">
                            {videoDuration}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="w-full h-full object-cover">
                      <Link
                        to={`/${getDateString(article?.createdAt)}/${
                          article?.newsCategory
                        }/${article?._id}`}
                      >
                        <FileDisplay
                          file={article?.file}
                          smallImg={false}
                          className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                        />
                      </Link>
                    </div>
                  )}
                </div>

                {/* Right Content Side */}
                <div className="lg:w-2/3 w-full p-4">
                  <span className="text-sm font-semibold text-red-500 uppercase">
                    {article?.newsCategory}
                  </span>
                  <h2 className="text-xl font-bold md:mt-2 mt-6 text-gray-800 capitalize">
                    <Link
                      to={`/${getDateString(article?.createdAt)}/${
                        article?.newsCategory
                      }/${article?._id}`}
                    >
                      {article.title}
                    </Link>
                  </h2>
                  <p
                    className="text-gray-600 mt-3"
                    dangerouslySetInnerHTML={{
                      __html: truncateText(article?.editorText, 10),
                    }}
                  ></p>
                  <div className="flex items-center justify-between mt-4 text-sm text-gray-500">
                    <div className="flex items-center">
                      <span>
                        By{" "}
                        <Link
                          to={`/profiles/${article?.authorName}`}
                          state={{ user: article?.user }}
                          className="font-bold"
                        >
                          {article.user?.username}
                        </Link>
                      </span>
                      <span className="ml-4">
                        {getDateDifference(article?.createdAt)}
                      </span>
                    </div>
                    <div className="flex text-center items-center gap-2">
                      {article.views} <Eye size={15} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No articles found for this tag.</p>
          )}
          <div ref={observerRef} style={{ height: "20px" }}></div>
          {isFetchingMore && <p>Loading more articles...</p>}
        </div>
        {/* Right Side: More Articles */}
        <div
          className="md:w-1/4 w-full space-y-4 sticky top-20"
          style={{ maxHeight: "calc(100vh - 80px)", overflowY: "auto" }}
        >
          <h2 className="text-lg font-bold text-gray-800 mb-4 text-center border-b-4 border-red-600 mx-4 uppercase">
            Random From Tag
          </h2>
          <div className="space-y-4 ml-5 shadow-md rounded-lg p-4">
            {getMoreArticles()?.map((article, index) => (
              <div>
                <TextOnly
                  key={`cat2-${index}`}
                  link={`/${getDateString(article?.createdAt)}/${
                    article?.newsCategory
                  }/${article?._id}`}
                  text={
                    loading ? (
                      <TextLoader className="mx-auto text-center !bg-transparent" />
                    ) : (
                      article?.title
                    )
                  }
                />
                <span className="text-[10px] font-semibold text-red-500 uppercase">
                  {article?.subCategory}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tags;
