import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { createNews, videoUpload } from "../../Api/createNews";
import { toast } from "react-toastify";
import ModalContainer from "../../components/Modal/ModalContainer";
import NewsForm from "../NewsForm";
import { UploadCloud } from "react-feather";

const NewsUpload = ({ visible, onClose }) => {
  const [videoSelected, setVideoSelected] = useState(false);
  const [videoUploaded, setVideoUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newsUploaded, setNewsUploaded] = useState(false);
  const [newsUploadProgress, setNewsUploadProgress] = useState(0);
  const [videoInfo, setVideoInfo] = useState({});
  const [busy, setBusy] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);

  const handleTypeError = (error) => {
    toast.error(error);
  };

  const handleUploadVideo = async (data) => {
    const { error, url, public_id } = await videoUpload(
      data,
      setUploadProgress
    );
    if (error) return toast.error(error);

    setVideoUploaded(true);
    setVideoInfo({ url, public_id });
  };

  const handleChange = (file) => {
    const formData = new FormData();
    formData.append("video", file);

    setVideoSelected(true);
    handleUploadVideo(formData);
  };

  const getUploadProgressValue = () => {
    if (
      !videoUploaded &&
      !newsUploaded &&
      newsUploadProgress &&
      uploadProgress >= 100
    ) {
      return "Processing...";
    }

    return `Uploading... ${uploadProgress || newsUploadProgress}%`;
  };

  const handleSubmit = async (data) => {
    setBusy(true);
    data.append("video", JSON.stringify(videoInfo));
    const { error, news } = await createNews(data, setNewsUploadProgress);
    setBusy(false);
    setNewsUploaded(true);
    if (error) return toast.error(error);

    toast.success("News upload successfully.");
    onClose();
  };

  return (
    <ModalContainer
      visible={visible}
      onClose={onClose}
      className={"mt-20 md:mx-0 mx-16"}
    >
      <div className="mb-5">
        <label className="flex items-center space-x-3">
          <input
            type="checkbox"
            checked={uploadVideo}
            onChange={() => setUploadVideo(!uploadVideo)}
            className="form-checkbox h-5 w-5 text-gray-600 cursor-pointer"
          />
          <span className="text-gray-700">Upload Video</span>
        </label>
        {uploadVideo && (
          <UploadProgress
            visible={!videoUploaded && videoSelected}
            message={getUploadProgressValue()}
            width={uploadProgress}
          />
        )}
      </div>
      {uploadVideo && !videoSelected ? (
        <VideoSelector
          visible={!videoSelected}
          onTypeError={handleTypeError}
          handleChange={handleChange}
        />
      ) : (
        <NewsForm busy={busy} onSubmit={!busy ? handleSubmit : null} />
      )}
    </ModalContainer>
  );
};

const VideoSelector = ({ visible, handleChange, onTypeError }) => {
  if (!visible) return null;

  return (
    <div className="h-full flex items-center justify-center">
      <FileUploader
        handleChange={handleChange}
        onTypeError={onTypeError}
        types={["mp4", "avi"]}
      >
        <label className="w-48 h-48 border border-dashed border-light-subtle rounded-full flex flex-col items-center justify-center text-secondary cursor-pointer">
          <UploadCloud size={80} />
          <p>Drop your file here!</p>
        </label>
      </FileUploader>
    </div>
  );
};

const UploadProgress = ({ width, message, visible }) => {
  if (!visible) return null;

  return (
    <div className="items-center text-center bg-white drop-shadow-lg rounded p-3 ">
      <div className="relative h-3 bg-light-subtle overflow-hidden">
        <div
          style={{ width: width + "%" }}
          className="h-full max-w-full absolute left-0  bg-secondary"
        />
      </div>
      <p className="mx-20 items-center font-semibold text-light-subtle animate-pulse mt-1 text-center !bg-green-500">
        {message}
      </p>
    </div>
  );
};

export default NewsUpload;
