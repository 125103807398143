import React, { useState } from "react";
import Header from "../Common/Header/Header";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdAdv from "./ThirdAdv";
import FourthSection from "./FourthSection";
import FifthSection from "./FifthSection";
import SixthSection from "./SixthSection";
import SeventhSection from "./SeventhSection";
// import EightSection from "./EightSection";
import NinthSection from "./NinthSection";
// import TenthSection from "./TenthSection";
import EleventhSection from "./EleventhSection";
import Footer from "../Common/Footer/Footer";
import EntertainmentSection from "./EntertainmentSection";

function Home() {
  const [showFooterSearch, setShowFooterSearch] = useState(false);
  const onSearchButtonClick = () => {
    setShowFooterSearch(!showFooterSearch);
  };
  return (
    <>
      <Header onSearchButtonClick={onSearchButtonClick} />
      {!showFooterSearch && (
        <>
          <FirstSection />
          <SecondSection />
          <ThirdAdv />
          <FourthSection />
          <FifthSection />
          <SixthSection />
          <EntertainmentSection />
          <SeventhSection />
          <NinthSection />
          {/* <TenthSection /> */}
          <EleventhSection />
        </>
      )}
      <Footer />
    </>
  );
}

export default Home;
