import React, { useState } from "react";
import { applyForARole } from "../Api/apis";
import { toast } from "react-toastify";

const Application = () => {
  const [role, setRole] = useState("Writer");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [age, setAge] = useState("");
  const [country, setCountry] = useState("");
  const [reason, setReason] = useState("");
  const [hasGoodInternet, setHasGoodInternet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleApply = async () => {
    setLoading(true);
    try {
      const response = await applyForARole(
        role,
        additionalInfo,
        age,
        country,
        reason,
        hasGoodInternet
      );
      toast.success("Application submitted successfully!");
      setSuccess("Application submitted successfully!");
    } catch (err) {
      setError("Failed to submit application.");
    }
    setLoading(false);
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Apply to be a Writer or Editor
      </h2>
      <div className="mb-4">
        <label
          htmlFor="role"
          className="block text-gray-700 text-sm font-medium mb-2"
        >
          Role
        </label>
        <select
          id="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="Writer">Writer</option>
          <option value="Editor">Editor</option>
        </select>
      </div>
      {role === "editor" && (
        <div className="mb-4">
          <label
            htmlFor="additionalInfo"
            className="block text-gray-700 text-sm font-medium mb-2"
          >
            Additional Information (required for Editors)
          </label>
          <textarea
            id="additionalInfo"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            rows="4"
            className="block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
      )}
      <div className="mb-4">
        <label
          htmlFor="age"
          className="block text-gray-700 text-sm font-medium mb-2"
        >
          Age
        </label>
        <input
          id="age"
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="country"
          className="block text-gray-700 text-sm font-medium mb-2"
        >
          Country
        </label>
        <input
          id="country"
          type="text"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="reason"
          className="block text-gray-700 text-sm font-medium mb-2"
        >
          Reason for Applying
        </label>
        <textarea
          id="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows="4"
          className="block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="hasGoodInternet"
          className="flex items-center text-gray-700 text-sm font-medium"
        >
          <input
            id="hasGoodInternet"
            type="checkbox"
            checked={hasGoodInternet}
            onChange={(e) => setHasGoodInternet(e.target.checked)}
            className="mr-2"
          />
          I have a good internet connection in my residence
        </label>
      </div>
      <div className="flex items-center justify-between">
        <button
          onClick={handleApply}
          disabled={loading}
          className={`px-4 py-2 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            loading ? "bg-gray-400" : "bg-indigo-600 hover:bg-indigo-700"
          }`}
        >
          {loading ? "Submitting..." : "Apply"}
        </button>
      </div>
      {error && <p className="mt-4 text-red-500 text-sm">{error}</p>}
      {success && <p className="mt-4 text-green-500 text-sm">{success}</p>}
    </div>
  );
};

export default Application;
