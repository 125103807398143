import axios from "axios";
import React, { useEffect, useState } from "react";
// import Sidebar from "./Sidebar";
import Header from "./Header";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Sidebar from "./Sidebar";
import { getIsAuth } from "../Api/auth";
// import CreateNews from "./CreateNews";
// import NewsList from "./NewsList";
// import UpdateNews from "./UpdateNews";
// import ManageCategories from "./ManageCategories";
// import ManageTags from "./ManageTags";
// import Settings from "./Settings";
// import Support from "./Support";
// import ManageTypes from "./ManageTypes";

const Writer = () => {
  const [USERID, setuserid] = useState("");
  const [role, setRole] = useState(() => {
    // Retrieve the user role from localStorage when the component mounts
    return localStorage.getItem("userRole") || "";
  });

  const navigate = useNavigate();

  const writerRole = async () => {
    try {
      const response = await getIsAuth();
      if (response?.user?.role) {
        setuserid(response.user.userid);
        setRole(response?.user?.role);
        localStorage.setItem("userRole", response?.user?.role);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  useEffect(() => {
    writerRole();
  }, []);

  if (role !== "Writer") {
    return <Navigate to="/" />;
  }

  return (
    <div
      className="flex flex-col min-h-screen max-h-full dashboard w-full"
      style={{ backgroundColor: "#f1f5f9" }}
    >
      <div className="flex">
        <Sidebar />
        <div className="flex-grow flex flex-col">
          <Header />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/news-management/create" element={<CreateNews />} />
            <Route path="/news-management/NewsList" element={<NewsList />} />
            <Route
              path="/news-management/update/:id"
              element={<UpdateNews />}
            />
            <Route
              path="/news-management/manage-categories"
              element={<ManageCategories />}
            />
            <Route
              path="/news-management/manage-tag"
              element={<ManageTags />}
            />
            <Route
              path="/news-management/manage-type"
              element={<ManageTypes />}
            /> */}
            {/* <Route path="/settings" element={<Settings />} />
            <Route path="/help" element={<Support userRole={role} />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Writer;
