import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import UseNewsCategory from "../Common/FetchNews/useNewsCategory";
import MidCard from "../components/Cards/MidCard";
import getDateString from "../Helpers/DateString";
import CategorySmallCard from "./CategorySmallCard";
import VideoDisplay from "../Helpers/VideoDisplay";
import { Eye } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useSiteSettings } from "../Hooks/Settings";
import UseImageNews from "../Common/FetchNews/useImageNews";
import LiveScore from "../components/LiveScore";

const CategoryPage = () => {
  const { category, subcategory } = useParams();
  const { siteTitle } = useSiteSettings();
  const [activeImage, setActiveImage] = useState(null);

  const isFootballPage = category === "sports" && subcategory === "football";

  // .............SINGLE ARTICLE............. //
  const {
    data: generalArticles,
    loading: loadingGeneral,
    error: errorGeneral,
  } = UseNewsCategory(category, subcategory, "General", null, 1, "createdAt");
  const {
    data: generalArticles2,
    loading: loadingGeneral2,
    error: errorGeneral2,
  } = UseNewsCategory(category, subcategory, "Breaking", null, 1, "createdAt");
  const {
    data: generalArticles3,
    loading: loadingGeneral3,
    error: errorGeneral3,
  } = UseNewsCategory(category, subcategory, "Featured", null, 1, "createdAt");
  const {
    data: generalArticles4,
    loading: loadingGeneral4,
    error: errorGeneral4,
  } = UseNewsCategory(category, subcategory, "Top Story", null, 1, "createdAt");
  const {
    data: generalArticles5,
    loading: loadingGeneral5,
    error: errorGeneral5,
  } = UseNewsCategory(
    category,
    subcategory,
    "Video Tiles",
    null,
    1,
    "createdAt"
  );
  const {
    data: generalArticles6,
    loading: loadingGeneral6,
    error: errorGeneral6,
  } = UseNewsCategory(
    category,
    subcategory,
    "Photo Tiles",
    null,
    1,
    "createdAt"
  );
  const {
    data: generalArticles7,
    loading: loadingGeneral7,
    error: errorGeneral7,
  } = UseNewsCategory(category, subcategory, "Travel", null, 1, "createdAt");
  const {
    data: generalArticles8,
    loading: loadingGeneral8,
    error: errorGeneral8,
  } = UseNewsCategory("Sports", null, null, null, 1, "createdAt");
  const {
    data: generalArticles9,
    loading: loadingGeneral9,
    error: errorGeneral9,
  } = UseNewsCategory(
    "Sexual Health & Wellness",
    subcategory,
    null,
    null,
    1,
    "createdAt"
  );
  const {
    data: generalArticles10,
    loading: loadingGeneral10,
    error: errorGeneral10,
  } = UseNewsCategory(category, subcategory, "Exclusive", null, 1, "createdAt");
  const {
    data: generalArticles11,
    loading: loadingGeneral11,
    error: errorGeneral11,
  } = UseNewsCategory(category, subcategory, "Opinion", null, 1, "createdAt");
  const {
    data: generalArticles12,
    loading: loadingGeneral12,
    error: errorGeneral12,
  } = UseNewsCategory(
    category,
    subcategory,
    "Fact Check",
    null,
    1,
    "createdAt"
  );
  const {
    data: generalArticles13,
    loading: loadingGeneral13,
    error: errorGeneral13,
  } = UseNewsCategory(category, subcategory, "Analysis", null, 1, "createdAt");
  const {
    data: generalArticles14,
    loading: loadingGeneral14,
    error: errorGeneral14,
  } = UseNewsCategory(category, subcategory, "Flashback", null, 1, "createdAt");
  const {
    data: generalArticles15,
    loading: loadingGeneral15,
    error: errorGeneral15,
  } = UseNewsCategory(category, subcategory, "Style", null, 1, "createdAt");
  const {
    data: generalArticles16,
    loading: loadingGeneral16,
    error: errorGeneral16,
  } = UseNewsCategory(
    category,
    subcategory,
    "LiveUpdate",
    null,
    1,
    "createdAt"
  );
  const {
    data: imageArticles,
    loading: loadingImageArticles,
    error: errorImageArticles,
  } = UseImageNews("More", subcategory, null, null, 1, "createdAt");
  // .............SINGLE ARTICLE END............. //
  // .............7 ARTICLES............. //
  const {
    data: breakingArticles,
    loading: loadingBreaking,
    error: errorBreaking,
  } = UseNewsCategory(category, subcategory, "Breaking", null, 7, "desc");
  const {
    data: breakingArticles2,
    loading: loadingBreaking2,
    error: errorBreaking2,
  } = UseNewsCategory(category, subcategory, "General", null, 7, "desc");
  const {
    data: breakingArticles3,
    loading: loadingBreaking3,
    error: errorBreaking3,
  } = UseNewsCategory(category, subcategory, "Featured", null, 7, "desc");
  const {
    data: breakingArticles4,
    loading: loadingBreaking4,
    error: errorBreaking4,
  } = UseNewsCategory(category, subcategory, "Top Story", null, 7, "desc");
  const {
    data: breakingArticles5,
    loading: loadingBreaking5,
    error: errorBreaking5,
  } = UseNewsCategory(category, subcategory, "Video Tiles", null, 7, "desc");
  const {
    data: breakingArticles6,
    loading: loadingBreaking6,
    error: errorBreaking6,
  } = UseNewsCategory(category, subcategory, "Photo Tiles", null, 7, "desc");
  const {
    data: breakingArticles7,
    loading: loadingBreaking7,
    error: errorBreaking7,
  } = UseNewsCategory(category, subcategory, "Travel", null, 7, "desc");
  const {
    data: breakingArticles8,
    loading: loadingBreaking8,
    error: errorBreaking8,
  } = UseNewsCategory(null, "Football", null, null, 7, "desc");
  const {
    data: breakingArticles9,
    loading: loadingBreaking9,
    error: errorBreaking9,
  } = UseNewsCategory(
    category,
    "Reproductive Health & Relationships",
    null,
    null,
    7,
    "desc"
  );
  const {
    data: breakingArticles10,
    loading: loadingBreaking10,
    error: errorBreaking10,
  } = UseNewsCategory(category, subcategory, "Exclusive", null, 7, "desc");
  const {
    data: breakingArticles11,
    loading: loadingBreaking11,
    error: errorBreaking11,
  } = UseNewsCategory(category, subcategory, "Opinion", null, 7, "desc");
  const {
    data: breakingArticles12,
    loading: loadingBreaking12,
    error: errorBreaking12,
  } = UseNewsCategory(category, subcategory, "Fact Check", null, 7, "desc");
  const {
    data: breakingArticles13,
    loading: loadingBreaking13,
    error: errorBreaking13,
  } = UseNewsCategory(category, subcategory, "Analysis", null, 7, "desc");
  const {
    data: breakingArticles14,
    loading: loadingBreaking14,
    error: errorBreaking14,
  } = UseNewsCategory(category, subcategory, "Flashback", null, 7, "desc");
  const {
    data: breakingArticles15,
    loading: loadingBreaking15,
    error: errorBreaking15,
  } = UseNewsCategory(category, subcategory, "Style", null, 7, "desc");
  const {
    data: breakingArticles16,
    loading: loadingBreaking16,
    error: errorBreaking16,
  } = UseNewsCategory(category, subcategory, "LiveUpdate", null, 7, "desc");
  const {
    data: imageArticles1,
    loading: loadingImageArticles1,
    error: errorImageArticles1,
  } = UseImageNews("More", subcategory, null, null, 7, "createdAt");
  // .............7 ARTICLES END............. //
  // .............3 ARTICLES............. //
  const {
    data: featuredArticles,
    loading: loadingFeature,
    error: errorFeature,
  } = UseNewsCategory(category, subcategory, "Featured", null, 3, "desc");
  const {
    data: featuredArticles1,
    loading: loadingFeature1,
    error: errorFeature1,
  } = UseNewsCategory(category, subcategory, "General", null, 3, "desc");
  const {
    data: featuredArticles2,
    loading: loadingFeature2,
    error: errorFeature2,
  } = UseNewsCategory(category, subcategory, "Breaking", null, 3, "desc");
  const {
    data: featuredArticles3,
    loading: loadingFeature3,
    error: errorFeature3,
  } = UseNewsCategory(category, subcategory, "Travel", null, 3, "desc");
  const {
    data: featuredArticles4,
    loading: loadingFeature4,
    error: errorFeature4,
  } = UseNewsCategory(category, subcategory, "Top Story", null, 3, "desc");
  const {
    data: featuredArticles5,
    loading: loadingFeature5,
    error: errorFeature5,
  } = UseNewsCategory(null, "Transfer News", "Sports", null, 3, "desc");
  const {
    data: featuredArticles6,
    loading: loadingFeature6,
    error: errorFeature6,
  } = UseNewsCategory(category, subcategory, "Exclusive", null, 3, "desc");
  const {
    data: featuredArticles7,
    loading: loadingFeature7,
    error: errorFeature7,
  } = UseNewsCategory(category, subcategory, "Opinion", null, 3, "desc");
  const {
    data: featuredArticles8,
    loading: loadingFeature8,
    error: errorFeature8,
  } = UseNewsCategory(category, subcategory, "Fact Check", null, 3, "desc");
  const {
    data: featuredArticles9,
    loading: loadingFeature9,
    error: errorFeature9,
  } = UseNewsCategory(category, subcategory, "Analysis", null, 3, "desc");
  const {
    data: featuredArticles10,
    loading: loadingFeature10,
    error: errorFeature10,
  } = UseNewsCategory(category, subcategory, "Flashback", null, 3, "desc");
  const {
    data: featuredArticles11,
    loading: loadingFeature11,
    error: errorFeature11,
  } = UseNewsCategory(category, subcategory, "Style", null, 3, "desc");
  const {
    data: featuredArticles12,
    loading: loadingFeature12,
    error: errorFeature12,
  } = UseNewsCategory(category, subcategory, "LiveUpdate", null, 3, "desc");
  const {
    data: topStoriesArticles,
    loading: loadingTopStories,
    error: errorTopStories,
  } = UseNewsCategory(null, subcategory, "Top Story", null, 3, "desc");
  const {
    data: topStoriesArticles2,
    loading: loadingTopStories2,
    error: errorTopStories2,
  } = UseNewsCategory(null, subcategory, "General", null, 3, "desc");
  const {
    data: topStoriesArticles3,
    loading: loadingTopStories3,
    error: errorTopStories3,
  } = UseNewsCategory(null, subcategory, "Breaking", null, 3, "desc");
  const {
    data: topStoriesArticles4,
    loading: loadingTopStories4,
    error: errorTopStories4,
  } = UseNewsCategory(null, subcategory, "Featured", null, 3, "desc");
  const {
    data: topStoriesArticles5,
    loading: loadingTopStories5,
    error: errorTopStories5,
  } = UseNewsCategory(null, subcategory, "Travel", null, 3, "desc");
  const {
    data: topStoriesArticles6,
    loading: loadingTopStories6,
    error: errorTopStories6,
  } = UseNewsCategory("Sports", "Football Awards", null, null, 3, "desc");
  const {
    data: topStoriesArticles7,
    loading: loadingTopStories7,
    error: errorTopStories7,
  } = UseNewsCategory(null, subcategory, "Exclusive", null, 3, "desc");
  const {
    data: topStoriesArticles8,
    loading: loadingTopStories8,
    error: errorTopStories8,
  } = UseNewsCategory(null, subcategory, "Opinion", null, 3, "desc");
  const {
    data: topStoriesArticles9,
    loading: loadingTopStories9,
    error: errorTopStories9,
  } = UseNewsCategory(null, subcategory, "Fact Check", null, 3, "desc");
  const {
    data: topStoriesArticles10,
    loading: loadingTopStories10,
    error: errorTopStories10,
  } = UseNewsCategory(null, subcategory, "Analysis", null, 3, "desc");
  const {
    data: topStoriesArticles11,
    loading: loadingTopStories11,
    error: errorTopStories11,
  } = UseNewsCategory(null, subcategory, "Flashback", null, 3, "desc");
  const {
    data: topStoriesArticles12,
    loading: loadingTopStories12,
    error: errorTopStories12,
  } = UseNewsCategory(null, subcategory, "Style", null, 3, "desc");
  const {
    data: topStoriesArticles13,
    loading: loadingTopStories13,
    error: errorTopStories13,
  } = UseNewsCategory(null, null, "LiveUpdate", null, 3, "desc");
  // .............3 ARTICLES END............. //

  // Function to determine which articles to display
  const generalArticlesToDisplay = () => {
    if (generalArticles?.length > 0) return generalArticles;
    if (generalArticles2?.length > 0) return generalArticles2;
    if (generalArticles3?.length > 0) return generalArticles3;
    if (generalArticles4?.length > 0) return generalArticles4;
    if (generalArticles5?.length > 0) return generalArticles5;
    if (generalArticles6?.length > 0) return generalArticles6;
    if (generalArticles7?.length > 0) return generalArticles7;
    if (generalArticles8?.length > 0) return generalArticles8;
    if (generalArticles9?.length > 0) return generalArticles9;
    if (generalArticles10?.length > 0) return generalArticles10;
    if (generalArticles11?.length > 0) return generalArticles11;
    if (generalArticles12?.length > 0) return generalArticles12;
    if (generalArticles13?.length > 0) return generalArticles13;
    if (generalArticles14?.length > 0) return generalArticles14;
    if (generalArticles15?.length > 0) return generalArticles15;
    if (generalArticles16?.length > 0) return generalArticles16;
    return [];
  };

  const articleToDisplay = generalArticlesToDisplay();
  const breakingArticlesToDisplay = () => {
    if (breakingArticles?.length > 0) return breakingArticles;
    if (breakingArticles2?.length > 0) return breakingArticles2;
    if (breakingArticles3?.length > 0) return breakingArticles3;
    if (breakingArticles4?.length > 0) return breakingArticles4;
    if (breakingArticles5?.length > 0) return breakingArticles5;
    if (breakingArticles6?.length > 0) return breakingArticles6;
    if (breakingArticles7?.length > 0) return breakingArticles7;
    if (breakingArticles8?.length > 0) return breakingArticles8;
    if (breakingArticles9?.length > 0) return breakingArticles9;
    if (breakingArticles10?.length > 0) return breakingArticles10;
    if (breakingArticles11?.length > 0) return breakingArticles11;
    if (breakingArticles12?.length > 0) return breakingArticles12;
    if (breakingArticles13?.length > 0) return breakingArticles13;
    if (breakingArticles14?.length > 0) return breakingArticles14;
    return [];
  };

  const displayBreakingArticles = breakingArticlesToDisplay();
  const featuredArticlesToDisplay = () => {
    if (featuredArticles?.length > 0) return featuredArticles;
    if (featuredArticles1?.length > 0) return featuredArticles1;
    if (featuredArticles2?.length > 0) return featuredArticles2;
    if (featuredArticles3?.length > 0) return featuredArticles3;
    if (featuredArticles4?.length > 0) return featuredArticles4;
    if (featuredArticles5?.length > 0) return featuredArticles5;
    if (featuredArticles6?.length > 0) return featuredArticles6;
    if (featuredArticles7?.length > 0) return featuredArticles7;
    if (featuredArticles8?.length > 0) return featuredArticles8;
    if (featuredArticles9?.length > 0) return featuredArticles9;
    if (featuredArticles10?.length > 0) return featuredArticles10;
    return [];
  };

  const displayFeaturedArticles = featuredArticlesToDisplay();
  const topStoriesArticlesToDisplay = () => {
    if (topStoriesArticles?.length > 0) return topStoriesArticles;
    if (topStoriesArticles2?.length > 0) return topStoriesArticles2;
    if (topStoriesArticles3?.length > 0) return topStoriesArticles3;
    if (topStoriesArticles4?.length > 0) return topStoriesArticles4;
    if (topStoriesArticles5?.length > 0) return topStoriesArticles5;
    if (topStoriesArticles6?.length > 0) return topStoriesArticles6;
    if (topStoriesArticles7?.length > 0) return topStoriesArticles7;
    if (topStoriesArticles8?.length > 0) return topStoriesArticles8;
    if (topStoriesArticles9?.length > 0) return topStoriesArticles9;
    if (topStoriesArticles10?.length > 0) return topStoriesArticles10;
    if (topStoriesArticles11?.length > 0) return topStoriesArticles11;
    return [];
  };

  const displayTopStoriesArticles = topStoriesArticlesToDisplay();

  // Function to filter video articles from a list of articles
  const filterVideoArticles = (articles) => {
    return articles?.filter((article) => article?.video?.url);
  };

  // Filtering video articles from all categories
  const videoArticles = [
    ...filterVideoArticles(generalArticles),
    ...filterVideoArticles(generalArticles2),
    ...filterVideoArticles(generalArticles3),
    ...filterVideoArticles(generalArticles4),
    ...filterVideoArticles(generalArticles5),
    ...filterVideoArticles(generalArticles7),
    ...filterVideoArticles(generalArticles8),
    ...filterVideoArticles(generalArticles9),
    ...filterVideoArticles(generalArticles10),
    ...filterVideoArticles(generalArticles11),
    ...filterVideoArticles(generalArticles12),
    ...filterVideoArticles(generalArticles13),
    ...filterVideoArticles(generalArticles14),
    ...filterVideoArticles(generalArticles15),
    ...filterVideoArticles(generalArticles16),
    ...filterVideoArticles(breakingArticles),
    ...filterVideoArticles(breakingArticles2),
    ...filterVideoArticles(breakingArticles3),
    ...filterVideoArticles(breakingArticles4),
    ...filterVideoArticles(breakingArticles5),
    ...filterVideoArticles(breakingArticles7),
    ...filterVideoArticles(breakingArticles8),
    ...filterVideoArticles(breakingArticles9),
    ...filterVideoArticles(breakingArticles10),
    ...filterVideoArticles(breakingArticles11),
    ...filterVideoArticles(breakingArticles12),
    ...filterVideoArticles(breakingArticles13),
    ...filterVideoArticles(breakingArticles14),
    ...filterVideoArticles(breakingArticles15),
    ...filterVideoArticles(breakingArticles16),
    ...filterVideoArticles(featuredArticles),
    ...filterVideoArticles(featuredArticles1),
    ...filterVideoArticles(featuredArticles2),
    ...filterVideoArticles(featuredArticles3),
    ...filterVideoArticles(featuredArticles4),
    ...filterVideoArticles(featuredArticles5),
    ...filterVideoArticles(featuredArticles6),
    ...filterVideoArticles(featuredArticles7),
    ...filterVideoArticles(featuredArticles8),
    ...filterVideoArticles(featuredArticles9),
    ...filterVideoArticles(featuredArticles10),
    ...filterVideoArticles(featuredArticles11),
    ...filterVideoArticles(featuredArticles12),
    ...filterVideoArticles(topStoriesArticles),
    ...filterVideoArticles(topStoriesArticles2),
    ...filterVideoArticles(topStoriesArticles3),
    ...filterVideoArticles(topStoriesArticles4),
    ...filterVideoArticles(topStoriesArticles5),
    ...filterVideoArticles(topStoriesArticles6),
    ...filterVideoArticles(topStoriesArticles7),
    ...filterVideoArticles(topStoriesArticles8),
    ...filterVideoArticles(topStoriesArticles9),
    ...filterVideoArticles(topStoriesArticles10),
    ...filterVideoArticles(topStoriesArticles11),
    ...filterVideoArticles(topStoriesArticles12),
    ...filterVideoArticles(topStoriesArticles13),
  ];

  // Track IDs of displayed articles to ensure uniqueness
  const displayedIds = new Set();

  // Filter unique articles, excluding ones that have already been displayed
  const filterUniqueArticles = (articles, excludedArticles = []) => {
    const excludedIds = new Set(excludedArticles.map((article) => article._id));

    return articles?.filter((article) => {
      if (displayedIds?.has(article?._id) || excludedIds.has(article?._id)) {
        return false;
      } else {
        displayedIds?.add(article?._id);
        return true;
      }
    });
  };

  // const uniqueArticle1 = filterUniqueArticles(article1 || []);
  const uniqueArticle2 = filterUniqueArticles(displayBreakingArticles || []);
  const uniqueArticle3 = filterUniqueArticles(displayFeaturedArticles || []);
  const uniqueArticle4 = filterUniqueArticles(displayTopStoriesArticles || []);

  // Deduplicate video articles based on the video URL or a unique ID
  const uniqueVideoArticles = Array?.from(
    new Set(videoArticles?.map((video) => video?.video?.url))
  )?.map((url) => {
    return videoArticles?.find((video) => video?.video?.url === url);
  });

  if (
    loadingGeneral ||
    loadingGeneral2 ||
    loadingGeneral3 ||
    loadingGeneral4 ||
    loadingGeneral5 ||
    loadingGeneral6 ||
    loadingGeneral7 ||
    loadingGeneral8 ||
    loadingGeneral9 ||
    loadingGeneral10 ||
    loadingGeneral11 ||
    loadingGeneral12 ||
    loadingGeneral13 ||
    loadingGeneral14 ||
    loadingGeneral15 ||
    loadingGeneral16 ||
    loadingImageArticles ||
    loadingImageArticles1
  )
    return (
      <div className="m-10">
        <h1 className="font-bold text-4xl capitalize">{subcategory}</h1>
        <div className="flex justify-center text-center">
          <div
            className="animate-spin text-primary fill-green-500"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );

  // Handle the image click to change the large image
  const handleImageClick = (image) => {
    setActiveImage(image);
  };
  const articleImage = imageArticles && imageArticles[0];
  const articleImage2 = imageArticles1 && imageArticles1[1];

  if (loadingImageArticles) {
    return <div>Loading...</div>;
  }
  if (loadingImageArticles1) {
    return <div>Loading...</div>;
  }

  // console.log("articleImage::", articleImage);
  // console.log("articleImage2::", articleImage2);

  return (
    <div className="m-10">
      <Helmet>
        <title>{`${siteTitle} ${category}/${subcategory}`}</title>
        <meta property="og:title" content={category} />
      </Helmet>
      <h1 className="font-bold text-4xl uppercase">{subcategory}</h1>
      {isFootballPage && (
        <div>
          <LiveScore />
        </div>
      )}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/5 p-4">
          {articleToDisplay && articleToDisplay.length > 0 ? (
            articleToDisplay.map((card, index) => (
              <MidCard
                key={index}
                link={`/${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                loading={loadingGeneral}
                db={true}
              />
            ))
          ) : articleImage ? (
            <div className="mb-10">
              {/* Display the first large image */}
              <MidCard
                imageSrc={activeImage || articleImage?.files[0]} // Show clicked image or default to the first image
                text={articleImage?.title}
                loading={loadingImageArticles}
                db={true}
              />

              {/* Display smaller images below */}
              <div className="grid grid-cols-3 gap-6 w-full">
                {articleImage?.files?.slice(1)?.map((image, imgIndex) => (
                  <CategorySmallCard
                    key={imgIndex}
                    imageSrc={image}
                    // text={articleImage?.title}
                    loading={loadingImageArticles}
                    className="cursor-pointer w-64"
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <p>No articles available</p>
          )}

          {uniqueArticle2 &&
            uniqueArticle2?.map((card, index) => (
              <CategorySmallCard
                key={`cat3-${index}`}
                link={`/${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file || card?.files[0]}
                text={card?.title}
                loading={loadingBreaking}
                className=" flex w-full"
              />
            ))}
        </div>
        <div className="w-full md:w-2/6 p-4">
          {uniqueArticle3 ? (
            uniqueArticle3?.map((card, index) => (
              <MidCard
                key={index}
                link={`/${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file || card?.files[0]}
                text={card?.title}
                // tags={card?.tags}
                loading={loadingFeature}
                db={true}
              />
            ))
          ) : articleImage2 ? (
            <div className="mb-10">
              {/* Display the first large image */}
              <MidCard
                imageSrc={activeImage || articleImage2?.files[0]} // Show clicked image or default to the first image
                text={articleImage2?.title}
                loading={loadingImageArticles1}
                db={true}
              />

              {/* Display smaller images below */}
              <div className="flex flex-wrap mt-4 w-full">
                {articleImage2?.files?.slice(1)?.map((image, imgIndex) => (
                  <CategorySmallCard
                    key={imgIndex}
                    imageSrc={image}
                    // text={articleImage?.title}
                    loading={loadingImageArticles1}
                    className="cursor-pointer w-64"
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <p>No articles available</p>
          )}
        </div>
        <div className="w-full md:w-1/4 p-4">
          {uniqueArticle4?.map((card, index) => (
            <MidCard
              key={index}
              link={`/${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file || card?.files[0]}
              text={card?.title}
              // tags={card?.tags}
              loading={loadingFeature}
              db={true}
            />
          ))}
        </div>
      </div>
      {/* VIDEO FILES */}
      {uniqueVideoArticles?.length > 0 && (
        <div>
          <h2 className="font-bold text-2xl mb-6 capitalize">videos</h2>
          <div className="grid md:grid-cols-4 grid-cols-3 gap-6">
            {uniqueVideoArticles?.map((video, index) => (
              <>
                <div key={index} className="border rounded-lg p-4">
                  <VideoDisplay
                    image={video?.file}
                    video={video?.video}
                    className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                    handleLoadedMetadata={() => {}}
                    showControls={false}
                  />
                  <span className="flex items-center text-sm gap-1">
                    <Eye size={13} />
                    {video?.views}
                  </span>
                  <Link
                    to={`/${getDateString(video?.createdAt)}/${
                      video?.newsCategory
                    }/${video?._id}`}
                  >
                    <h2 className="text-xl font-semibold mt-4 hover:underline hover:text-gray-700">
                      {video?.title}
                    </h2>
                  </Link>
                </div>
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryPage;
