import React, { useEffect, useState } from "react";
import { createBiography, updateBiography } from "../Api/apis";
import { Loader } from "react-feather";
import { toast } from "react-toastify";

const BioForm = ({ personData, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    realName: "",
    stageName: "",
    aliasName: "",
    dateOfBirth: "",
    hometown: "",
    category: "Music Artist", // Default value
    label: "",
    position: "",
    niche: "",
    genre: "",
    club: "",
    platform: "",
    socialMedia: {},
    bio: "",
    image: null,
    imageUrl: "",
  });

  const isValidUrl = (url) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  };

  useEffect(() => {
    if (personData) {
      setFormData({
        ...personData,
        dateOfBirth: personData.dateOfBirth.split("T")[0],
        imageUrl: personData.image,
      });
    }
  }, [personData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      image: e.target.files[0],
      imageUrl: URL.createObjectURL(e.target.files[0]),
    }));
  };

  // Handle adding new social media links
  const handleSocialMediaChange = (e, platform) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      socialMedia: {
        ...prev.socialMedia,
        [platform]: value,
      },
    }));
  };

  // Handle updating platform name
  const handlePlatformChange = (e, oldPlatform) => {
    const { value } = e.target;
    setFormData((prev) => {
      const updatedSocialMedia = { ...prev.socialMedia };
      const url = updatedSocialMedia[oldPlatform]; // Preserve the existing URL value
      delete updatedSocialMedia[oldPlatform]; // Remove the old key
      updatedSocialMedia[value] = url; // Set the new platform name with the same URL

      return {
        ...prev,
        socialMedia: updatedSocialMedia,
      };
    });
  };

  // Adding new social media field dynamically
  const addNewSocialMediaField = () => {
    setFormData((prev) => ({
      ...prev,
      socialMedia: { ...prev.socialMedia, "": "" }, // Add an empty key-value pair
    }));
  };

  // Handle removing a social media field
  const removeSocialMediaField = (platform) => {
    setFormData((prev) => {
      const updatedSocialMedia = { ...prev.socialMedia };
      delete updatedSocialMedia[platform]; // Remove the platform
      return {
        ...prev,
        socialMedia: updatedSocialMedia,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      const socialMediaKeys = Object.keys(formData.socialMedia);
      const invalidEntries = socialMediaKeys.filter((platform) => {
        const url = formData.socialMedia[platform];
        return platform.trim() === "" || !isValidUrl(url);
      });

      if (invalidEntries.length > 0) {
        toast.error(
          "Please ensure all social media links are valid and platforms are not empty."
        );
        return;
      }

      if (key === "socialMedia") {
        formDataToSend.append(key, JSON.stringify(formData[key])); // Convert the social media object to JSON
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      if (personData) {
        setLoading(true);
        await updateBiography(personData?._id, formDataToSend);
        toast.success("Biography updated successfully!");
      } else {
        setLoading(true);
        await createBiography(formDataToSend);
        toast.success("Biography Created successfully!");
      }
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error saving person data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 p-6 h-5/6 overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">
            {personData ? "Update Biography" : "Add Biography"}
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="stageName"
              placeholder="Stage Name"
              value={formData.stageName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />

            <input
              type="text"
              name="realName"
              placeholder="Real Name"
              value={formData.realName}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="text"
              name="aliasName"
              placeholder="Alias Name"
              value={formData.aliasName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="text"
              name="hometown"
              placeholder="Hometown"
              value={formData.hometown}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            >
              <option value="Music Artist">Music Artist</option>
              <option value="Footballer">Footballer</option>
              <option value="Influencer">Influencer</option>
              <option value="Creator">Creator</option>
            </select>
            <input
              type="text"
              name="label"
              placeholder="Label"
              value={formData.label}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="text"
              name="position"
              placeholder="Position"
              value={formData.position}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="text"
              name="niche"
              placeholder="Niche"
              value={formData.niche}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="text"
              name="genre"
              placeholder="Genre"
              value={formData.genre}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <input
              type="text"
              name="club"
              placeholder="Club"
              value={formData.club}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            {/* Social Media Links Section */}
            <div className="mb-4">
              <h3 className="font-semibold">Social Media Links</h3>
              {Object?.keys(formData.socialMedia)?.map((platform, index) => (
                <div key={index} className="flex space-x-2 mb-2">
                  <input
                    type="text"
                    placeholder="Platform"
                    value={platform}
                    onChange={(e) => handlePlatformChange(e, platform)}
                    className="w-1/3 p-2 border border-gray-300 rounded"
                  />
                  <input
                    type="text"
                    placeholder="URL"
                    value={formData.socialMedia[platform]}
                    onChange={(e) => handleSocialMediaChange(e, platform)}
                    className="w-2/3 p-2 border border-gray-300 rounded"
                  />
                  <button
                    type="button"
                    onClick={() => removeSocialMediaField(platform)}
                    className="text-red-500 p-1"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="bg-gray-200 p-2 rounded mt-2"
                onClick={addNewSocialMediaField}
              >
                Add Social Media Link
              </button>
            </div>
            <textarea
              name="bio"
              placeholder="Biography"
              value={formData.bio}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            {formData.imageUrl && (
              <div className="mb-4">
                <img
                  src={formData.imageUrl}
                  alt="Preview"
                  className="w-full h-auto mb-4"
                />
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleFileChange}
              className="mb-4"
            />
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-2 text-gray-600 border border-gray-300 rounded px-4 py-2"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-600 text-white rounded px-4 py-2"
              >
                {loading ? (
                  <Loader className="animate-spin" />
                ) : (
                  <span>{personData ? "Update" : "Create"}</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default BioForm;
