import React from "react";
import NewsManagement from "./Components/NewsManagement";

const ApproveRejectNews = () => {
  return (
    <div className="mt-10">
      <NewsManagement />
    </div>
  );
};

export default ApproveRejectNews;
