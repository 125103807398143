import React, { useEffect, useState } from "react";
import { getUpNextArticles } from "../../Api/news";
import getDateString from "../../Helpers/DateString";
import VideoDisplay from "../../Helpers/VideoDisplay";
import { Video } from "react-feather";
import FileDisplay from "../../Helpers/FileDisplay";
import { Link } from "react-router-dom";

const UpNext = ({ articleId }) => {
  const [upNextArticles, setUpNextArticles] = useState([]);
  const [videoDuration, setVideoDuration] = useState(null);

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  useEffect(() => {
    const fetchUpNextArticles = async () => {
      try {
        const response = await getUpNextArticles(articleId);
        setUpNextArticles(response);
      } catch (error) {
        console.error("Error fetching Up Next articles:", error);
      }
    };

    fetchUpNextArticles();
  }, [articleId]);

  return (
    <>
      {upNextArticles && upNextArticles?.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4 border-b-4 border-red-600 w-24">
            Up next
          </h2>
          <ul>
            {upNextArticles?.map((article, index) => (
              <li key={index} className="flex items-start mb-6">
                <div className="flex-1">
                  <Link
                    to={`/${getDateString(article?.createdAt)}/${
                      article?.newsCategory
                    }/${article?._id}`}
                    className="font-semibold md:text-lg text-base hover:underline flex flex-wrap w-11/12"
                  >
                    {article.title}
                  </Link>
                  <p className="text-sm text-gray-500">
                    {Math.floor(Math.random() * 12) + 1} minute read
                  </p>{" "}
                  {/* Mock reading time */}
                </div>
                <div className="md:w-1/5 md:ml-0 ml-2">
                  {article?.file ? (
                    article?.video ? (
                      <div className="relative object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                        <VideoDisplay
                          image={article?.file}
                          video={article?.video} // Pass video object for VideoDisplay
                          className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                          handleLoadedMetadata={handleLoadedMetadata}
                          showControls={false}
                        />
                        {videoDuration && (
                          <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                            <Video className="mr-1 text-white" size={11} />
                            <span className="text-white font-bold text-xs">
                              {videoDuration}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                        <FileDisplay
                          file={{
                            ...article?.file,
                            isVideo: !!article?.video,
                            duration: article?.video?.duration,
                          }}
                          // file={imageSrc} // Pass file object for FileDisplay
                          className="w-40 h-20 object-cover transition-transform transform group-hover:scale-100"
                        />
                      </div>
                    )
                  ) : (
                    <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                      <img
                        src={article?.file?.url}
                        alt=""
                        className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                      />
                    </div>
                  )}
                </div>
                {/* {article.file && (
              <a
                href={`/${getDateString(article?.createdAt)}/${
                  article?.newsCategory
                }/${article?._id}`}
                className="ml-4"
              >
                <img
                  src={article?.file?.url}
                  alt={article?.title}
                  className="w-20 h-12 object-cover"
                />
              </a>
            )} */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default UpNext;
