import React, { useEffect, useState } from "react";
import NewsTable from "./Components/NewsListComponents/NewsTable";
import Pagination from "./Components/NewsListComponents/Pagination";
import { fetchAllNewsList } from "../Api/createNews";

const AllNewsList = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const itemsPerPage = 5;

  useEffect(() => {
    fetchNewsData();
  }, [currentPage]);

  const fetchNewsData = async () => {
    try {
      const { news, totalPages } = await fetchAllNewsList(
        currentPage,
        itemsPerPage
      );
      setNewsData(news);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleUIUpdate = () => {
    fetchNewsData();
  };

  return (
    <div className="mt-12 mx-4">
      <h1 className="text-3xl font-bold mb-4">All News</h1>
      <NewsTable
        data={newsData}
        onPageChange={handlePageChange}
        setData={setNewsData}
        afterDelete={handleUIUpdate}
        afterUpdate={handleUIUpdate}
      />
      <Pagination pageCount={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default AllNewsList;
