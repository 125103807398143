import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addToRecycleBin, deleteArticle } from "../../../Api/createNews";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import NewsUpdate from "../../../Admin/Components/NewsUpdate";
import { Trash2 } from "react-feather";

const EditorNewsTable = ({
  data,
  setData,
  onPageChange,
  afterDelete,
  afterUpdate,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [busy, setBusy] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const navigate = useNavigate();

  const filteredData = data?.filter(
    (item) =>
      item?.title &&
      item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const handlePageChange = ({ selected }) => {
    onPageChange({ selected }); // Pass the page change event to the parent
  };

  const paginatedData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const displayConfirmModal = (id) => {
    setDeletedItemId(id);
    setShowConfirmModal(true);
  };

  const handleDelete = (id) => {
    setDeletedItemId(id);
    setShowModal(true);
  };

  const handleOnDeleteConfirm = async () => {
    setBusy(true);
    const { error, message } = await addToRecycleBin(deletedItemId);
    setBusy(false);

    if (error) return toast.error("error", error);

    hideConfirmModal();
    toast.success("success", message);
    afterDelete(data);
  };
  // };
  const handleCreateNews = () => {
    // Implement the logic for creating a new news item
    navigate("/editor/news-management/create");
  };
  const handleRecycleBin = () => {
    navigate("/editor/news-management/RecycleBin");
  };

  function formatDate(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString)?.toLocaleDateString(
      undefined,
      options
    );

    return formattedDate;
  }

  const handleOnEditClick = (id) => {
    setShowUpdateModal(true);
    setSelectedNewsId(id);
  };

  const handleOnUpdate = (news) => {
    afterUpdate(news);
    setShowUpdateModal(false);
    setSelectedNewsId(null);
  };

  const hideConfirmModal = () => setShowConfirmModal(false);
  const hideUpdateModal = () => setShowUpdateModal(false);

  return (
    <>
      <div className="md:flex justify-between items-center">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search news..."
            className="border p-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex md:justify-end">
          <button
            onClick={handleCreateNews}
            className="bg-blue-500 text-white mb-4 py-2 px-4 mx-2 rounded hover:bg-blue-600"
          >
            Create News
          </button>
          <button
            onClick={handleRecycleBin}
            className="bg-red-500 text-white mb-4 py-1 px-2 rounded hover:bg-red-600"
            title="recycle Bin"
          >
            <Trash2 />
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-start">Title</th>
              <th className="py-2 px-4 border-b text-start">Category</th>
              <th className="py-2 px-4 border-b text-start">Author</th>
              <th className="py-2 px-4 border-b text-start">Published Date</th>
              <th className="py-2 px-4 border-b text-center pl-12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{item?.title}</td>
                <td className="py-2 px-4 border-b">{item?.newsCategory}</td>
                <td className="py-2 px-4 border-b">{item?.authorName}</td>
                <td className="py-2 px-4 border-b">
                  {formatDate(item?.createdAt)}
                </td>

                <td className="py-2 px-4 border-b flex self-end justify-end">
                  <button
                    onClick={() => handleOnEditClick(item?._id)}
                    className="text-blue-500 hover:underline mr-2 flex "
                    title="Edit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => displayConfirmModal(item?._id)}
                    className="text-red-500 hover:underline flex"
                    title="Trash"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination pageCount={pageCount} onPageChange={handlePageChange} /> */}
      <div className="p-0">
        <ConfirmModal
          visible={showConfirmModal}
          onConfirm={handleOnDeleteConfirm}
          onCancel={hideConfirmModal}
          title="Are you sure?"
          subtitle="This action will add this article to the recycle Bin!"
          busy={busy}
        />
        <NewsUpdate
          newsId={selectedNewsId}
          visible={showUpdateModal}
          onClose={hideUpdateModal}
          onSuccess={handleOnUpdate}
        />
      </div>
    </>
  );
};

export default EditorNewsTable;
