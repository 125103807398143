import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MidCard from "../components/Cards/MidCard";
import getDateString from "../Helpers/DateString";
import axios from "axios";
import { getImageFiles } from "../Api/apis";
import SquareAd from "../components/SquareAd";

const NinthSection = () => {
  const [images, setImages] = useState([]);

  const getImages = async () => {
    try {
      const res = await getImageFiles();
      setImages(res);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <div>
      <h2 className="pl-3.5 pt-6 pb-2 md:text-4xl text-2xl font-bold">
        Photos You Should See
      </h2>
      <div className="flex flex-col md:flex-row">
        {/* First Column (2 parts) */}
        <div className="md:w-2/3 w-full p-4 flex">
          <div className="grid grid-cols-3 gap-4 p-2">
            {images?.slice(0, 6)?.map((img, index) => (
              <div key={index} className="w-full p-2">
                <Link
                  to={`/image/${getDateString(img?.createdAt)}/${
                    img?.category
                  }/${img?._id}`}
                >
                  <div className="relative">
                    <div className="md:w-full w-[5rem]">
                      <img
                        src={img?.files[0]?.url}
                        alt={img?.altText}
                        className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50"
                      />
                    </div>
                    {img?.tags && img?.tags?.length > 0 && (
                      <div className="absolute bottom-0 left-0">
                        {img?.tags?.map((tag, index) => (
                          <span
                            key={index}
                            className="bg-black bg-opacity-25 text-white text-[10px] px-2 mr-1 py-1 font-bold"
                          >
                            {tag?.name}
                            {index !== img?.tags?.length - 1 && ", "}{" "}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <span className="md:text-sm text-xs font-semibold hover:underline">
                    {img?.title}
                  </span>
                </Link>
              </div>
            ))}
          </div>

          {/* <div className="w-1/3 p-2 ">
            {midCards2.map((card, index) => (
              <MidCard key={index} {...card} />
            ))}
          </div>
          <div className="w-1/3 p-2 ">
            {midCards3.map((card, index) => (
              <MidCard key={index} {...card} />
            ))}
          </div> */}
        </div>

        {/* Second Column (1 part) */}
        <div className="w-1/3 p-4 ">
          {/* Content for the second column */}

          <Link to="/#">
            <div className=" relative w-305 h-171 group mb-4 ">
              {/* Replace 'videos/sample.mp4' with the path to your video file */}
              <div className="relative w-250 h-300 group">
                {/* Image */}
                <SquareAd />
                {/* <img
                  src="images/NinthSection/adv.jpg"
                  alt="analysis"
                  className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                /> */}
                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-gray-400 opacity-0  ">
                  {/* Additional content can be added here, if needed */}
                </div>
                {/* <div className=" text-xs">Advertisement</div> */}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NinthSection;
