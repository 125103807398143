// import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import NewsList from "./NewsList";
import UpdateNews from "./UpdateNews";
import ManageCategories from "./ManageCategories";
import ManageRole from "./ManageRole";
import ManageUser from "./ManageUser";
import Settings from "./Settings";
import Support from "./Support";
import ManageTypes from "./ManageTypes";
import { getDetails, getIsAuth } from "../Api/auth";
import ApproveRejectNews from "./ApproveRejectNews";
import NewsUpload from "./Components/NewsUpload";
import CreateNewsForm from "../Form/CreateNewsForm";
import UploadImage from "../components/ImageUpload/UploadImage";
import Gallery from "../components/ImageUpload/Gallery";
import UserProfile from "../components/UserProfile";
import ActivityLog from "../components/ActivityLog";
import AccountStatusControl from "./Components/AccountStatusControl";
import SiteSettings from "./Components/SiteSettings";
import RecycleBin from "./RecycleBin";
import ManageApplications from "./Components/ManageApplications";
import AllNewsList from "./AllNewsList";
import BioManagement from "../components/BioManagement";

const Admin = () => {
  const [userId, setUserId] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [role, setRole] = useState(() => {
    // Retrieve the user role from localStorage when the component mounts
    return localStorage.getItem("userRole") || "";
  });

  const [showNewsUploadModal, setShowNewsUploadModal] = useState(false);

  const displayNewsUploadModal = () => {
    setShowNewsUploadModal(true);
  };

  const hideNewsUploadModal = () => {
    setShowNewsUploadModal(false);
  };

  const navigate = useNavigate();

  const adminRole = async () => {
    try {
      const response = await getIsAuth();
      if (response?.user?.role) {
        setUserId(response.user._id);
        setRole(response?.user?.role);
        localStorage.setItem("userRole", response?.user?.role);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  useEffect(() => {
    adminRole();
  }, []);

  useEffect(() => {
    if (userId) {
      getDetails(userId).then((data) => {
        setProfileImage(data?.user?.profilePhoto);
      });
    }
  }, [userId]);

  if (role !== "Admin") {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <div className="flex w-full">
          <Sidebar onAddNewsClick={displayNewsUploadModal} />
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <Header profileImage={profileImage} />
            <main>
              <div className="dark:!bg-[#182235] px-4 sm:px-6 lg:px-8 py-8 w-full h-screen max-w-9xl mx-auto overflow-auto scrollbar">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/news-management/create"
                    element={<CreateNewsForm />}
                  />
                  <Route
                    path="/profile-management/profile"
                    element={<BioManagement />}
                  />
                  <Route
                    path="/news-management/Image-upload"
                    element={<UploadImage />}
                  />
                  <Route
                    path="/news-management/Image-gallery"
                    element={<Gallery />}
                  />
                  <Route
                    path="/news-management/NewsList"
                    element={<NewsList />}
                  />
                  <Route
                    path="/news-management/allNews"
                    element={<AllNewsList />}
                  />
                  <Route
                    path="/news-management/RecycleBin"
                    element={<RecycleBin />}
                  />

                  <Route
                    path="/news-management/manage-categories"
                    element={<ManageCategories />}
                  />
                  <Route
                    path="/news-management/manage-type"
                    element={<ManageTypes />}
                  />
                  <Route
                    path="/news-management/approve-reject"
                    element={<ApproveRejectNews />}
                  />
                  <Route
                    path="/user-management/manage-role"
                    element={<ManageRole />}
                  />
                  <Route
                    path="/user-management/manage-user"
                    element={<ManageUser />}
                  />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/site-settings" element={<SiteSettings />} />
                  <Route path="/help" element={<Support userRole={role} />} />
                  <Route
                    path="/news-management/update/:id"
                    element={<UpdateNews />}
                  />
                  <Route
                    path="/user-profile"
                    element={<UserProfile userId={userId} />}
                  />
                  <Route
                    path="/user-management/logs"
                    element={<ActivityLog userId={userId} />}
                  />
                  <Route
                    path="/user-management/account-status-control"
                    element={<AccountStatusControl />}
                  />
                  <Route
                    path="/user-management/applications"
                    element={<ManageApplications />}
                  />
                </Routes>
              </div>
            </main>
          </div>
        </div>
      </div>
      <NewsUpload visible={showNewsUploadModal} onClose={hideNewsUploadModal} />
    </>
  );
};

export default Admin;
