import React, { useEffect, useState } from "react";
import { getArticlesByPerson } from "../../Api/apis";
import TruncateText from "../../Helpers/helper";
import { Link } from "react-router-dom";
import getDateString from "../../Helpers/DateString";

const Related = ({ realName, stageName }) => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const articlesPerPage = 5;

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      try {
        const response = await getArticlesByPerson(realName || stageName, {
          page: currentPage,
          limit: articlesPerPage,
        });
        setArticles(response?.articles);
        setTotalPages(response?.totalPages);
      } catch (error) {
        console.error("Error fetching related articles", error);
      }
    };

    if (realName || stageName) {
      fetchRelatedArticles();
    }
  }, [realName, stageName, currentPage]);

  if (articles?.length === 0)
    return (
      <div className="text-center font-semibold">
        No {stageName}'s articles found.
      </div>
    );

  return (
    <div className="w-full px-6 py-8 md:px-16">
      <h2 className="text-xl font-bold mb-4 uppercase bg-gradient-to-tr from-blue-500 via-red-500 to-green-400  w-fit px-2">
        "{stageName}" Articles
      </h2>
      <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
        {articles?.map((article, index) => (
          <Link
            key={index}
            to={`/${getDateString(article?.createdAt)}/${
              article?.newsCategory
            }/${article?._id}`}
          >
            <div className="flex items-start space-x-4 border-b pb-4">
              <img
                src={article?.file?.url}
                alt={article?.title}
                className="w-20 h-20 object-cover"
              />
              <div className="flex-1">
                <h3 className="font-bold md:text-lg text-sm hover:text-red-500">
                  {TruncateText(article?.title, 8)}
                </h3>
                <p className="text-gray-400 font-bold hover:text-red-500">
                  {stageName}
                </p>
                <p className="text-gray-400 text-sm mt-1">
                  {new Date(article?.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span className="mx-2">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Related;
