import React, { useEffect, useState } from "react";
import axios from "axios";
import client from "../../Api/client";

export default function UseNewsFetch(
  category,
  subcategory,
  type,
  tags,
  limit,
  order
) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = {};
        if (category) {
          params.category = category;
          if (subcategory) {
            params.subcategory = subcategory;
          }
        }
        if (type) {
          params.type = type;
        }
        if (tags) {
          params.tags = tags.join(","); // Convert tags array to comma-separated string
        }
        if (limit) {
          params.limit = limit;
        }
        if (order) {
          params.order = order;
        }
        const response = await client.get("/news", {
          params,
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log("Error is : ", error);
        setError("Error fetching data");
      }
      setLoading(false);
    };
    fetchData();
  }, [category, subcategory, type, tags, limit, order]);
  return { data, loading, error };
}
