import React, { useEffect, useState } from "react";
import { getRecentArticles } from "../Api/apis";
import moment from "moment";

const truncateText = (text, wordLimit) => {
  if (!text) return "";
  const words = text.split(" ");
  return (
    words.slice(0, wordLimit).join(" ") +
    (words.length > wordLimit ? "..." : "")
  );
};

const Dashboard = () => {
  const [articles, setArticles] = useState({
    recentArticles: [],
    sportsArticle: null,
    recentTechArticle: null,
    otherTechArticles: [],
    worldAndBusinessArticles: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await getRecentArticles();
        setArticles(response);
      } catch (error) {
        console.error("Error fetching articles", error);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, []);

  const {
    recentArticles,
    sportsArticle,
    recentTechArticle,
    otherTechArticles,
    worldAndBusinessArticles,
  } = articles;

  const getDateDifference = (createdAt) => {
    return moment(createdAt)?.fromNow();
  };
  const getTimeDifference = (createdAt) => {
    return moment(createdAt)?.format("MMMM Do YYYY, h:mm:ss a");
  };

  return (
    <main className="flex-1 p-6 bg-gray-100">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
        {/* Main Articles Section */}
        <section className="col-span-2">
          <h2 className="text-2xl font-bold rounded-tr rounded-tl bg-blue-500 px-2 py-1 text-white">
            Most Recent
          </h2>
          <div className="bg-white p-4 shadow rounded-bl rounded-br">
            {recentArticles && loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {recentArticles?.map((article, index) => (
                  <div key={index} className="mb-6 flex">
                    <img
                      src={article?.file?.responsive[1]}
                      alt="article img"
                      className=" w-24 h-20 mr-2"
                    />
                    <div className="w-auto truncate">
                      <div className="flex justify-between">
                        <span className="md:text-xs text-[8px]">
                          Created by: {article?.user?.username || "Unknown"}...{" "}
                          {getDateDifference(article?.createdAt)}
                        </span>
                        <time
                          dateTime={article?.createdAt}
                          className="md:text-xs text-[8px]"
                        >
                          {getTimeDifference(article?.createdAt)}
                        </time>
                      </div>
                      <h3 className="text-xl font-semibold truncate">
                        {article?.title}
                      </h3>

                      <p
                        className="text-gray-600 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{
                          __html: truncateText(article?.editorText, 15),
                        }}
                      ></p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <section className="mt-5">
            <h2 className="text-2xl font-bold bg-purple-700 px-2 py-1 text-white rounded-tr rounded-tl">
              World and Business
            </h2>
            <div className="bg-white p-4 shadow rounded-br rounded-bl">
              {worldAndBusinessArticles && loading ? (
                <p>Loading...</p>
              ) : (
                <>
                  {worldAndBusinessArticles?.map((article, index) => (
                    <div key={index} className="mb-6 flex">
                      <img
                        src={article?.file?.responsive[1]}
                        alt="article img"
                        className=" w-24 h-20 mr-2"
                      />
                      <div className="w-auto truncate">
                        <div className="flex justify-between">
                          <span className="md:text-xs text-[8px]">
                            Created by: {article?.user?.username || "Unknown"}
                            ... {getDateDifference(article?.createdAt)}
                          </span>
                          <time
                            dateTime={article?.createdAt}
                            className="md:text-xs text-[8px]"
                          >
                            {getTimeDifference(article?.createdAt)}
                          </time>
                        </div>
                        <h3 className="text-xl font-semibold truncate">
                          {article?.title}
                        </h3>

                        <p
                          className="text-gray-600 whitespace-pre-wrap"
                          dangerouslySetInnerHTML={{
                            __html: truncateText(article?.editorText, 15),
                          }}
                        ></p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </section>
        {/* Sidebar Widgets */}
        <aside className="col-span-1 space-y-6">
          <div className="bg-white p-4 shadow rounded flex px-1 gap-1">
            {/* <h2 className="text-xl font-bold mb-2">Weather</h2> */}
            <img
              src={recentTechArticle?.file?.responsive[1]}
              className="text-gray-600 w-32"
            />
            <img
              src={otherTechArticles?.file?.responsive[1]}
              className="text-gray-600 w-32"
            />
            <img
              src={sportsArticle?.file?.responsive[1]}
              className="text-gray-600 w-32"
            />
          </div>
          {sportsArticle && loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="bg-white p-4 shadow rounded">
                <h2 className="text-xl font-bold mb-2 truncate bg-blue-500 px-2 py-1 text-white">
                  {sportsArticle?.title || "Sports Article(Football)"}
                </h2>
                <div className="flex">
                  <img
                    src={sportsArticle?.file?.responsive[1]}
                    alt="image-file"
                    className="w-32"
                  />
                  <p
                    className="text-gray-600 ml-2"
                    dangerouslySetInnerHTML={{
                      __html: truncateText(
                        sportsArticle?.editorText,
                        15 || "No recent sports articles available"
                      ),
                    }}
                  ></p>
                </div>
              </div>
            </>
          )}
          {recentTechArticle && loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="bg-white p-4 shadow rounded">
                <h2 className="text-xl font-bold mb-2 truncate bg-blue-500 px-2 py-1 text-white">
                  {recentTechArticle?.title || "Tech Article"}
                </h2>
                <ul className="list-disc list-inside text-gray-600">
                  {otherTechArticles?.map((article, index) => (
                    <li key={index} className="truncate">
                      {article?.title}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </aside>
      </div>
    </main>
  );
};

export default Dashboard;
