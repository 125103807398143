import React, { useEffect, useState } from "react";
import { getBiographies } from "../../Api/apis";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-feather";

const AllBiography = () => {
  const [persons, setPersons] = useState([]);
  const [filteredPersons, setFilteredPersons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState("");

  const limit = 10;

  useEffect(() => {
    const fetchPersons = async () => {
      setLoading(true);
      try {
        const data = await getBiographies(currentPage, limit);
        setPersons(data?.data);
        setFilteredPersons(data?.data);
        setTotalPages(data?.totalPages);
      } catch (error) {
        console.error("Error fetching bios:", error);
      }
      setLoading(false);
    };

    fetchPersons();
  }, [currentPage]);

  // Function to handle filtering by selected letter
  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
    if (letter === "#") {
      setFilteredPersons(persons); // Show all if "#" is clicked
    } else {
      const filtered = persons.filter((person) =>
        person.stageName.toUpperCase().startsWith(letter)
      );
      setFilteredPersons(filtered);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div>
      {/* Header Section */}
      <section className="bg-black text-white py-8">
        <div className="container mx-auto">
          <h1 className="text-5xl font-bold capitalize">biographies</h1>
        </div>
      </section>

      {/* Alphabet Navigation */}
      <nav className="bg-black text-white py-4">
        <div className="container mx-auto">
          <ul className="flex flex-wrap justify-center md:space-x-4 space-x-3">
            {"ABCDEFGHIJKLMNOPQRSTUVWXYZ#"?.split("")?.map((letter, index) => (
              <li key={index}>
                <button
                  onClick={() => handleLetterClick(letter)}
                  className={`hover:underline ${
                    selectedLetter === letter ? "text-yellow-500" : ""
                  }`} // Highlight selected letter
                >
                  {letter}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </nav>

      {/* Persons Section */}
      <section className="container mx-auto py-8">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Map through filteredPersons */}
            {filteredPersons?.map((person, index) => (
              <div
                key={index}
                className="relative group text-center shadow-lg rounded-lg overflow-hidden"
              >
                <div>
                  <img
                    src={person?.image}
                    alt={person?.stageName}
                    className="w-full h-auto"
                  />
                </div>

                {/* Stage Name on top of the image */}
                <div className="absolute bottom-0 left-8 right-0 bg-black bg-opacity-50 text-white p-4 md:text-xl text-base font-bold transition-all duration-300 ease-in-out group-hover:translate-y-[-100%]">
                  {person?.stageName}
                </div>

                {/* Hover modal that slides in */}
                <div className="absolute top-8 left-8 right-0 bottom-2 bg-red-500 bg-opacity-80 text-white p-6 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transform translate-x-full transition-all duration-300 ease-in-out">
                  <>
                    <Link
                      to={`/biography/${person?._id}`}
                      className="md:text-xl text-base font-bold mb-4 hover:underline cursor-pointer"
                    >
                      {person?.stageName}
                    </Link>
                  </>
                  <div className="!border-b !border-dashed !border-white" />
                  <>
                    <Link
                      to={`/biography/${person?._id}`}
                      className="flex items-center px-4 py-2 text-white font-bold hover:underline"
                    >
                      View <ArrowRight size={15} />
                    </Link>
                  </>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Pagination Controls */}
        <div className="flex justify-center mt-8">
          <button
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="mx-4">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </section>
    </div>
  );
};

export default AllBiography;
