import React, { useState } from "react";
import { Link } from "react-router-dom";
import FileDisplay from "../Helpers/FileDisplay";
import getDateString from "../Helpers/DateString";
import TruncateText from "../Helpers/helper";
import TextLoader from "../Helpers/TextLoader";
import { Video } from "react-feather";
import VideoDisplay from "../Helpers/VideoDisplay";

const TagSmallCard = ({ link, imageSrc, tags, text, loading, video }) => {
  const [videoDuration, setVideoDuration] = useState(null);

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  return (
    <Link to={link}>
      <div className=" mt-2 pt-4">
        <div className="m-0 p-0">
          <div className="relative group border-b border-b-[#e6e6e6] mb-3 pb-3 flex max-w-full">
            <div className="w-2/5">
              {imageSrc ? (
                video ? (
                  <div className="relative object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                    <VideoDisplay
                      image={imageSrc}
                      video={video} // Pass video object for VideoDisplay
                      className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                      handleLoadedMetadata={handleLoadedMetadata}
                      showControls={false}
                    />
                    {videoDuration && (
                      <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                        <Video className="mr-1 text-white" size={11} />
                        <span className="text-white font-bold text-xs">
                          {videoDuration}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                    <FileDisplay
                      file={{
                        ...imageSrc,
                        isVideo: !!video,
                        duration: video?.duration,
                      }}
                      // file={imageSrc} // Pass file object for FileDisplay
                      className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                    />
                  </div>
                )
              ) : (
                <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                  <img
                    src={imageSrc}
                    alt=""
                    className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                  />
                </div>
              )}
            </div>
            <div className="w-3/5 ml-2">
              <h4 className="text-base self-start ml-2 hover:underline hover:text-gray-700">
                {loading ? (
                  <TextLoader className="mx-auto text-center !bg-transparent" />
                ) : (
                  TruncateText(text, 8)
                )}
              </h4>
              {/* <div className="flex flex-wrap">
                {tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="mx-2 bg-gray-200 rounded px-2 py-1 text-sm"
                  >
                    {tag?.name}
                  </span>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TagSmallCard;
