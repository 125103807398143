import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import NewsList from "./NewsList";
import Sidebar from "./Sidebar";
import DashBoard from "./DashBoard";
import { getDetails, getIsAuth } from "../Api/auth";
import CreateNews from "./CreateNews";
import NewsUpload from "../Admin/Components/NewsUpload";
import CreateNewsForm from "../Form/CreateNewsForm";
import ActivityLog from "../components/ActivityLog";
import UserProfile from "../components/UserProfile";
import Gallery from "../components/ImageUpload/Gallery";
import UploadImage from "../components/ImageUpload/UploadImage";
import RecycleBin from "../Admin/RecycleBin";
// import UpdateNews from "../Admin/UpdateNews";
// import Settings from "./Settings";
// import Support from "./Support";
// import ManageTypes from "./ManageTypes";

const Editor = () => {
  const [userId, setUserId] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [role, setRole] = useState(() => {
    // Retrieve the user role from localStorage when the component mounts
    return localStorage.getItem("userRole") || "";
  });

  const navigate = useNavigate();

  const [showNewsUploadModal, setShowNewsUploadModal] = useState(false);

  const displayNewsUploadModal = () => {
    setShowNewsUploadModal(true);
  };

  const hideNewsUploadModal = () => {
    setShowNewsUploadModal(false);
  };

  const editorRole = async () => {
    try {
      const response = await getIsAuth();
      if (response?.user?.role) {
        setUserId(response.user._id);
        setRole(response?.user?.role);
        localStorage.setItem("userRole", response?.user?.role);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  useEffect(() => {
    editorRole();
  }, []);

  useEffect(() => {
    if (userId) {
      getDetails(userId).then((data) => {
        setProfileImage(data?.user?.profilePhoto);
      });
    }
  }, [userId]);

  if (role !== "Editor") {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <div className="flex w-full">
          <Sidebar onAddNewsClick={displayNewsUploadModal} />
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <Header profileImage={profileImage} />
            <Routes>
              <Route path="/" element={<DashBoard />} />
              <Route path="/dashboard" element={<DashBoard />} />
              <Route
                path="/news-management/create"
                element={<CreateNewsForm />}
              />
              <Route
                path="/news-management/Image-upload"
                element={<UploadImage />}
              />
              <Route
                path="/news-management/Image-gallery"
                element={<Gallery />}
              />
              <Route path="/news-management/NewsList" element={<NewsList />} />
              <Route path="/logs" element={<ActivityLog userId={userId} />} />
              <Route
                path="/user-profile"
                element={<UserProfile userId={userId} />}
              />
              <Route
                path="/news-management/RecycleBin"
                element={<RecycleBin />}
              />
              {/* <Route path="/settings" element={<Settings />} />
            <Route path="/help" element={<Support userRole={role} />} /> */}
            </Routes>
          </div>
        </div>
      </div>
      <NewsUpload visible={showNewsUploadModal} onClose={hideNewsUploadModal} />
    </>
  );
};

export default Editor;
