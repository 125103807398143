import React, { useEffect, useState } from "react";
import TextOnly from "../components/Cards/TextOnly";
import MidCard from "../components/Cards/MidCard";
import axios from "axios";
import getDateString from "../Helpers/DateString";
import useMissedNewsFetch from "../Common/FetchNews/useMissedNewsFetch";
import HorizontalAd from "../components/HorizontalAd";

const EleventhSection = () => {
  // const [newsData, setNewsData] = useState([]);
  // const [midCards, setMidCards] = useState([]);
  // const [textOnly, setTextOnly] = useState([]);

  const {
    data: midCards,
    loading: loading1,
    error: error1,
  } = useMissedNewsFetch(null, null, "Top Story", null, 1, "desc");
  const {
    data: textOnly,
    loading: loading3,
    error: error3,
  } = useMissedNewsFetch(
    "Sexual Health & Wellness",
    null,
    null,
    null,
    8,
    "desc"
  );

  return (
    <div>
      <h2 className="pl-3.5 pt-6 pb-2 md:text-4xl text-2xl bg-black text-white font-bold">
        In Case You Missed It
      </h2>
      <div className="flex flex-wrap bg-black text-white">
        {/* First Column (2 parts) */}
        <div className="w-full md:w-3/5 p-4">
          {midCards?.map((card, index) => (
            <div key={index} className="mb-4">
              <MidCard
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                db={true}
                className={
                  "object-cover !bg-fixed opacity-100 transition duration-300 ease-in-out hover:!opacity-50"
                }
              />
            </div>
          ))}
        </div>

        {/* Second Column (1 part) */}
        <div className="w-full md:w-2/5 p-4">
          {/* Content for the second column */}
          {textOnly?.map((card, index) => (
            <TextOnly
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              text={card?.title}
              // loading={loading3}
            />
          ))}
        </div>

        {/* Third Column (1 part) */}
      </div>
      <div className="bg-black pb-6 flex items-center justify-center flex-col">
        {/* <img
          src="/images/EleventhSection/adv.jpg"
          alt="adv"
          className="w-full"
        /> */}
        <HorizontalAd />
        {/* <p className="text-zinc-600 text-xs self-start mt-1 adv-level">
          Advertisement
        </p> */}
      </div>
    </div>
  );
};

export default EleventhSection;
