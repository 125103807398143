import React, { useState } from "react";

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className="tabs text-center items-center mt-20 bg-gray-300 p-2">
        {children?.map((child, index) => (
          <button
            key={index}
            className={
              activeTab === index
                ? "active px-3 underline underline-offset-8 bg-red-500 text-white font-semibold rounded-tl-lg transition-all transform"
                : "px-3 text-gray-900 font-semibold"
            }
            onClick={() => handleTabClick(index)}
          >
            {child?.props?.label}
          </button>
        ))}
      </div>
      <div className="tab-content">{children[activeTab]}</div>
    </div>
  );
};

export const Tab = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default Tabs;
