import React, { createContext, useContext, useState, useEffect } from "react";
import { getIsAuth, login } from "../Api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

export const AuthContext = createContext();

const defaultAuthInfo = {
  profile: null,
  isLoggedIn: false,
  isPending: false,
  error: "",
};

export default function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({ ...defaultAuthInfo });
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    setAuthState({ ...authState, isPending: true });
    const { message, success, user } = await login({ email, password });

    // console.log("error:", message);
    if (success === false) {
      toast.error(message);
      return setAuthState({ ...authState, isPending: false, message });
    }

    setAuthState({
      profile: { ...user },
      isPending: false,
      isLoggedIn: true,
      error: "",
    });
    localStorage.setItem("authToken", user.token);
    localStorage.setItem("userRole", user.role);

    // console.log("User =>", user);

    // Redirect based on user role
    if (user.role === "Admin") {
      navigate("/Admin");
    } else if (user.role === "Editor") {
      navigate("/Editor");
    } else if (user.role === "Writer") {
      navigate("/Writer");
    } else {
      navigate("/"); // Default redirection for other roles
    }
  };

  const isAuth = async () => {
    try {
      setAuthState({ ...authState, isPending: true });

      const { error, user } = await getIsAuth();
      // console.log("auth-Res:", user);
      if (error) {
        console.error(error);
        return setAuthState({ ...authState, isPending: false, error });
      }
      setAuthState({
        profile: { ...user },
        isPending: false,
        isLoggedIn: true,
        error: "",
      });
    } catch (error) {
      setAuthState({ role: null });
    }
  };

  useEffect(() => {
    isAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        handleLogin,
        // isAuth,
        setAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
