import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import CreatableSelect from "react-select/creatable";
import {
  getCategories,
  getNewsById,
  getSubCat,
  getTypes,
  updateNews,
} from "../Api/createNews";
import { toast } from "react-toastify";
import client from "../Api/client";

const UpdateNews = () => {
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [updatedSelectedFile, setUpdatedSelectedFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [error, setError] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [base64File, setBase64File] = useState("");
  const [editorText, setEditorText] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [bioName, setBioName] = useState("");
  const [selectedNewsCategory, setSelectedNewsCategory] = useState("");
  const [selectedNewsSubCategory, setSelectedNewsSubCategory] = useState("");
  const [NewsCategorys, setNewsCategorys] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedTag, setSelectedTag] = useState([]);
  // Create a state for the file data
  const [file, setFile] = useState(null);
  const [isImage, setIsImage] = useState(null);
  const [isVideo, setIsVideo] = useState(null);
  const [filename, setfilename] = useState(null);

  const maxFileSizeinBytes = 50 * 1024 * 1024;
  const allowedFileTypes = [
    "image/jpeg",
    "image/JPG",
    "image/png",
    "image/gif",
    "image/webp",
    "video/mp4",
    "video/webm",
    "video/ogg",
  ];

  const IMAGE_TYPES = ["jpeg", "jpg", "gif", "png", "webp"];
  const VIDEO_TYPES = ["mp4", "webm", "ogg"];

  const handleEditorChange = (content) => {
    setEditorText(content);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxFileSizeinBytes) {
        setError("File size exceeds the maximum allowed size.");
        return;
      }
      const fileType = file.type.split("/")[0];
      setSelectedFile(file);
      setFileType(fileType);
      setError(null);
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileDataUrl = reader.result;
        setPreviewUrl(fileDataUrl);
        if (fileType === "image") {
          setBase64File(fileDataUrl.split(",")[1]);
        }
      };
      if (allowedFileTypes.includes(file.type)) {
        if (fileType === "image") {
          reader.readAsDataURL(file);
        } else if (fileType === "video") {
          setPreviewUrl(URL.createObjectURL(file));
        }
      } else {
        setError(
          "Invalid file type. Please select a valid image or video file."
        );
      }
    }
  };

  const handleNewsCategoryChange = (e) => {
    setSelectedNewsCategory(e.target.value);
  };
  const handleNewsSubCategoryChange = (e) => {
    setSelectedNewsSubCategory(e.target.value);
  };
  // ...........updated code for update from createNews...........

  function getFileType(fileString) {
    // Extract the file name from the URL
    const fileName = fileString?.split("/")?.pop();

    // Extract the file extension
    const fileExtension = fileName?.split(".")?.pop();

    return fileExtension?.toLowerCase();
  }

  const handleNewstypeChange = (e) => {
    setSelectedType(e.target.value);
  };
  const handleTagChange = (newValue) => {
    setSelectedTag(newValue);
  };
  // ...........end updated code for update from createNews...........
  const handleDataSubmit = async (e) => {
    e.preventDefault();
    const tags = selectedTag?.map((tag) => tag?.value || tag?.label);
    // Your form submission logic...
    const newsData = new FormData();
    newsData.append("id", id);
    newsData.append("title", title);
    if (selectedFile) {
      newsData.append("file", selectedFile);
    }
    newsData.append("newsCategory", selectedNewsCategory);
    newsData.append("subCategory", selectedNewsSubCategory);
    newsData.append("type", selectedType);
    newsData.append("editorText", editorText);
    newsData.append("tags", JSON?.stringify(tags));
    newsData.append("authorName", authorName);
    newsData.append("name", bioName);
    newsData.append("filename", filename);
    try {
      const response = await updateNews(newsData);
      // Perform actions on successful response
      toast.success(response);
    } catch (error) {
      console.error("Error sending data", error);
      // Handle the error
    }
  };

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const typeResponse = await getTypes(); // Replace with your API endpoint
        setTypes(typeResponse);
      } catch (error) {
        console.error("Error fetching types:", error.message);
      }
    };

    fetchTypes();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Fetch data from the API
        await getCategories()
          .then((response) => {
            // Assuming the API response data is an array of categories
            const getAllcategories = response;

            // Update the state with the fetched categories
            setNewsCategorys(getAllcategories);
          })
          .catch((error) => {
            console.error("Error fetching categories:", error);
          });
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);
  useEffect(() => {
    // Fetch subcategories based on the selected category
    if (selectedNewsCategory) {
      getSubCat(selectedNewsCategory)
        .then((response) => {
          setSubcategories(response);
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    }
  }, [selectedNewsCategory]);

  useEffect(() => {
    // Fetch existing news data if updating
    const fetchNewsData = async () => {
      if (id) {
        try {
          const res = await getNewsById(id);
          // console.log("updateData::", res);
          const fileTypes = getFileType(res?.file);
          setTitle(res?.title);
          setAuthorName(res?.authorName);
          setBioName(res?.bioName);
          setSelectedNewsCategory(res?.newsCategory);
          setSelectedNewsSubCategory(res?.subCategory);
          setEditorText(res?.editorText);
          setfilename(res?.file);
          setSelectedType(res?.type);
          setSelectedTag(
            res?.tags?.map((tag) => ({
              label: tag?.name,
              value: tag?._id,
            }))
          );
          // Determine file type and use the corresponding constant
          setIsImage(IMAGE_TYPES?.includes(fileTypes));
          setIsVideo(VIDEO_TYPES?.includes(fileTypes));

          // Fetch the file data from GridFS
          const response = await client.get(
            `/filesForNewsByFilename/${res?.file}`,
            { responseType: "arraybuffer", withCredentials: true }
          );
          // Check the Content-Type header to determine file type
          // Set the file data
          const contentType = response?.headers["content-type"];
          // Set the file data
          const blob = new Blob([response?.data], { type: contentType });
          const dataURL = URL.createObjectURL(blob);
          setUpdatedSelectedFile(dataURL);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchNewsData();
  }, [id]);
  return (
    <>
      <div className="mt-28">
        <div className="mx-auto bg-white drop-shadow-md w-10/12 rounded">
          <h3 className="p-6 font-bold mb-8 text-black border-b">
            {" "}
            Update News Article
          </h3>
          <div className="container p-5">
            <form onSubmit={handleDataSubmit}>
              <div className="mb-10">
                <label htmlFor="title" className="block text-sm  text-gray-600">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                />
              </div>
              <div className="mb-10">
                <label
                  htmlFor="newsType"
                  className="block text-sm text-gray-600"
                >
                  News Type
                </label>
                <select
                  id="NewsType"
                  name="NewsType"
                  value={selectedType}
                  onChange={handleNewstypeChange}
                  className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                >
                  <option value="" disabled>
                    Select News Type
                  </option>
                  {types?.map((types, index) => (
                    <option value={types?.name} key={index}>
                      {types?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-10">
                <label className="block text-sm text-gray-600">
                  Upload File
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                  className="sr-only"
                  accept="image/*,video/*"
                />
                <label
                  htmlFor="file"
                  className="mt-1 p-2 w-full cursor-pointer border border-gray-300 rounded-md flex items-center  justify-center bg-blue-600 text-white hover:bg-gray-900"
                >
                  Select Image or Video
                </label>
                {error && <p className="text-red-500">{error}</p>}
                {selectedFile && previewUrl && (
                  <div className="mt-2">
                    {fileType === "image" && (
                      <img
                        src={previewUrl}
                        alt="Preview image"
                        className="max-w-full h-96"
                      />
                    )}
                    {fileType === "video" && (
                      <video
                        controls
                        src={previewUrl}
                        alt="Preview image"
                        className="w-100  object-cover"
                      >
                        <source src={previewUrl} type={selectedFile.type} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                )}
                {updatedSelectedFile ? (
                  <div className="mb-10">
                    {isImage && (
                      <img
                        src={updatedSelectedFile}
                        alt="Preview"
                        className="w-full h-96 object-cover"
                      />
                    )}
                    {isVideo && (
                      <video controls className="w-full h-96 object-cover">
                        <source src={updatedSelectedFile} type="video/mp4" />
                      </video>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="title"
                  className="block text-sm font-bold text-gray-700 mb-1"
                >
                  News Editor
                </label>
                <ReactQuill
                  theme="snow"
                  value={editorText}
                  onChange={handleEditorChange}
                />

                {/* <Editor
              placeholder={"Write something..."}
              onChange={handleEditorChange}
              initialText={editorText}
            /> */}
              </div>
              <div className="mb-10"></div>
              <div className="mb-10">
                <label
                  htmlFor="newsType"
                  className="block text-sm text-gray-600"
                >
                  News Category
                </label>
                <select
                  id="NewsCategory"
                  name="NewsCategory"
                  value={selectedNewsCategory}
                  onChange={handleNewsCategoryChange}
                  className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                >
                  <option value="" disabled>
                    Select News Category
                  </option>
                  {NewsCategorys?.map((category, index) => (
                    <option value={category?.title} key={index}>
                      {category?.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-10">
                <label
                  htmlFor="newsType"
                  className="block text-sm text-gray-600"
                >
                  News Sub Category
                </label>
                <select
                  id="NewsSubCategory"
                  name="NewsSubCategory"
                  value={selectedNewsSubCategory}
                  onChange={handleNewsSubCategoryChange}
                  className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                >
                  <option value="" disabled>
                    Select News Sub Category
                  </option>
                  {subcategories?.map((subcategory, index) => (
                    <option value={subcategory?.name} key={index}>
                      {subcategory?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-10">
                <label
                  htmlFor="newsType"
                  className="block text-sm text-gray-600"
                >
                  News Tag
                </label>
                <CreatableSelect
                  id="tags"
                  isMulti
                  // options={tags?.map((tag) => ({
                  //   label: tag?.name,
                  //   value: tag?._id,
                  // }))}
                  value={selectedTag}
                  onChange={handleTagChange}
                />
              </div>
              <div className="mb-10">
                <label
                  htmlFor="author"
                  className="block text-sm  text-gray-600"
                >
                  Author
                </label>
                <input
                  type="text"
                  id="author"
                  name="author"
                  value={authorName}
                  onChange={(e) => setAuthorName(e.target.value)}
                  className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                />
              </div>
              <div className="mb-10 mt-4">
                <button
                  type="submit"
                  onClick={handleDataSubmit}
                  className="bg-gray-900 w-full text-white p-2  rounded-md hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateNews;
