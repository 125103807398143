import React, { useEffect, useState } from "react";
import BioForm from "../Form/BioForm";
import { deleteBiography, getBiography } from "../Api/apis";
import ConfirmModal from "./Modal/ConfirmModal";
import { toast } from "react-toastify";

const BioManagement = () => {
  const [persons, setPersons] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    fetchPersons();
  }, []);

  const fetchPersons = async () => {
    try {
      const response = await getBiography();
      setPersons(response);
    } catch (error) {
      console.error("Error fetching persons:", error);
    }
  };

  const handleEditClick = (person) => {
    setSelectedPerson(person);
    setModalOpen(true);
  };

  const displayConfirmModal = (id) => {
    setDeletedItemId(id);
    setShowConfirmModal(true);
  };

  const handleDelete = async () => {
    try {
      setBusy(true);
      await deleteBiography(deletedItemId);
      hideConfirmModal();
      toast.success("BioData deleted successfully!");
      fetchPersons();
    } catch (error) {
      console.error("Error deleting person:", error);
    } finally {
      setBusy(false);
    }
  };

  const handleSuccess = () => {
    setSelectedPerson(null);
    fetchPersons(); // Refresh the list after creation or update
  };

  const hideConfirmModal = () => setShowConfirmModal(false);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Person Management</h1>
      <button
        onClick={() => {
          setSelectedPerson(null);
          setModalOpen(true);
        }}
        className="mb-4 bg-green-600 text-white rounded px-4 py-2"
      >
        Add New Person
      </button>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Real Name</th>
              <th className="py-2 px-4 border-b">Alias Name</th>
              <th className="py-2 px-4 border-b">Category</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {persons?.map((person, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{person.realName}</td>
                <td className="py-2 px-4 border-b">{person.aliasName}</td>
                <td className="py-2 px-4 border-b">{person.category}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleEditClick(person)}
                    className="text-blue-600 hover:underline mr-2"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => displayConfirmModal(person._id)}
                    className="text-red-600 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        visible={showConfirmModal}
        onConfirm={handleDelete}
        onCancel={hideConfirmModal}
        title="Are you sure?"
        subtitle="This action will permanently delete this person!"
        busy={busy}
      />
      <BioForm
        personData={selectedPerson}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSuccess={handleSuccess}
      />
    </div>
  );
};

export default BioManagement;
