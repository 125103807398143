import React from "react";
import Adv from "./Adv";
import Nav from "./Nav";
import LiveScore from "../../components/LiveScore";

function Header({ onSearchButtonClick }) {
  return (
    <>
      <Adv />
      <Nav onSearchButtonClick={onSearchButtonClick} />
      {/* <LiveScore /> */}
    </>
  );
}

export default Header;
