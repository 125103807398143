import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Common/Footer/Footer";
import TextOnly from "../components/Cards/TextOnly";
import MidCard from "../components/Cards/MidCard";
import Header from "../Common/Header/Header";
import UseNewsFetch from "../Common/FetchNews/UseNewsFetch";
import VideoDisplay from "../Helpers/VideoDisplay";
import getDateString from "../Helpers/DateString";

const Category = () => {
  const { categoryName } = useParams();
  const {
    data: allCards,
    loading: loading1,
    error: error1,
  } = UseNewsFetch(categoryName, null, null, null, 6, "desc");
  const {
    data: breakingNews,
    loading: loadingBreakingNews,
    error: errorBreakingNews,
  } = UseNewsFetch(null, null, "Breaking", null, 1, "desc");

  const midCards = allCards?.slice(0, 3);
  const midCards2 = allCards?.slice(3);

  const {
    data: textOnly,
    loading: loading3,
    error: error3,
  } = UseNewsFetch(categoryName, null, null, null, 6, "desc");

  const [showFooterSearch, setShowFooterSearch] = useState(false);
  const onSearchButtonClick = () => {
    setShowFooterSearch(!showFooterSearch);
  };

  return (
    <>
      <Header onSearchButtonClick={onSearchButtonClick} />
      {!showFooterSearch && (
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/3 p-4 ">
            {midCards?.map((card, index) => (
              <MidCard
                key={`cat1-${index}`}
                link={`/${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                tag={card?.tag}
                db={true}
              />
            ))}
          </div>
          <div className="w-full md:w-1/3 p-4 ">
            {midCards2?.map((card, index) => (
              <MidCard
                key={`cat1-${index}`}
                link={`/${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                tag={card?.tag}
                db={true}
              />
            ))}
          </div>
          <div className="w-full md:w-1/3 p-4 ">
            {/* <Link to="/news-link" className="relative w-305 h-171 group mb-4">
              <video
                autoPlay={true}
                muted
                playsInline
                loop
                controls
                className="w-full  rounded-sm"
              >
                <source src="/videos/FirstSection/video.mp4" />
              </video>
            </Link> */}
            <Link
              to={`/${
                breakingNews && breakingNews.length > 0
                  ? `${getDateString(breakingNews[0]?.createdAt)}/${
                      breakingNews[0]?.newsCategory
                    }/${breakingNews[0]?._id}`
                  : ""
              }`}
              className="relative w-305 h-171 group mb-4"
            >
              <>
                {breakingNews?.length >= 0 && breakingNews[0]?.video && (
                  <VideoDisplay
                    image={breakingNews[0]?.file}
                    video={breakingNews[0]?.video}
                    playVideo={true}
                  />
                )}
              </>
            </Link>
            <div>
              <div className="mb-2 mt-4 font-bold text-xl hover:underline">
                Catch up on todays global news
              </div>
              <div>
                {textOnly?.map((card, index) => (
                  <TextOnly
                    key={`cat2-${index}`}
                    link={`/${getDateString(card?.createdAt)}/${
                      card?.newsCategory
                    }/${card?._id}`}
                    text={card?.title}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Category;
