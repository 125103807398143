import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Loader, Eye, EyeOff } from "react-feather";
import { toast } from "react-toastify";
import { resetMyPassword } from "../../Api/auth";

const ResetPassword = () => {
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  // Use useLocation to access the query parameters from the URL
  const location = useLocation();

  // Extract the token from the URL on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (token) {
      setToken(token);
    }
  }, [location]);

  const resetPassword = async (e) => {
    e.preventDefault();
    setIsPasswordFocused(false);
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    try {
      setLoading(true);
      const res = await resetMyPassword(token, newPassword);
      console.log("res::", res);
      if (res?.success) {
        toast.success(res.message || "Password reset successful");
      } else {
        toast.error(res?.message || "An error occurred during password reset.");
        setValidationErrors(res?.message);
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error resetting password", error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = () => {
    const hasLowercase = /[a-z]/.test(newPassword);
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasDigit = /\d/.test(newPassword);
    const hasSymbol = /[@$!%*?&]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;

    return {
      hasLowercase,
      hasUppercase,
      hasDigit,
      hasSymbol,
      isLengthValid,
    };
  };

  const passwordValidation = validatePassword();

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
      <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
      Flowbite    
    </a> */}
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Reset Password
          </h2>
          <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
            <div>
              <label
                htmlFor="resetToken"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Reset Token
              </label>
              <input
                type="text"
                name="resetToken"
                id="resetToken"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter reset token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
                disabled={token}
              />
            </div>
            <div className="mb-4 relative">
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onKeyDown={() => {
                    setIsPasswordFocused(true);
                    setValidationErrors(false);
                  }}
                />
                <div
                  className="absolute inset-y-0 right-0 top-7 flex items-center pr-3 cursor-pointer"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Eye /> : <EyeOff />}
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm-password"
                  autoComplete="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id="confirm-password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onKeyDown={() => {
                    setIsPasswordFocused(true);
                    setValidationErrors(false);
                  }}
                />
                <div
                  className="absolute inset-y-0 right-0 top-7 flex items-center pr-3 cursor-pointer"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  {showConfirmPassword ? <Eye /> : <EyeOff />}
                </div>
              </div>
              {isPasswordFocused && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    display: newPassword ? "block" : "none",
                    zIndex: "999", // Ensure the tooltip is above other elements
                  }}
                  className="bg-white text-xs p-2 border rounded drop-shadow-md border-gray-500 z-10"
                >
                  <div
                    style={{
                      color: passwordValidation.hasLowercase ? "green" : "red",
                    }}
                  >
                    {passwordValidation.hasLowercase ? "✓" : "✗"} At least one
                    lowercase letter
                  </div>
                  <div
                    style={{
                      color: passwordValidation.hasUppercase ? "green" : "red",
                    }}
                  >
                    {passwordValidation.hasUppercase ? "✓" : "✗"} At least one
                    uppercase letter
                  </div>
                  <div
                    style={{
                      color: passwordValidation.hasDigit ? "green" : "red",
                    }}
                  >
                    {passwordValidation.hasDigit ? "✓" : "✗"} At least one digit
                  </div>
                  <div
                    style={{
                      color: passwordValidation.hasSymbol ? "green" : "red",
                    }}
                  >
                    {passwordValidation.hasSymbol ? "✓" : "✗"} At least one
                    special character
                  </div>
                  <div
                    style={{
                      color: passwordValidation.isLengthValid ? "green" : "red",
                    }}
                  >
                    {passwordValidation.isLengthValid ? "✓" : "✗"} Minimum
                    length of 8 characters
                  </div>
                </div>
              )}
            </div>
            <div>
              {validationErrors && (
                <div className="text-red-600 flex flex-wrap">
                  <p className="mb-2 text-sm">{validationErrors}</p>
                  {/* {Object?.keys(validationErrors)?.map((key) => {
                    if (typeof validationErrors[key] === "object") {
                      return Object?.values(
                        validationErrors[key]?.map((error) => (
                          <p className="mb-2 text-sm" key={error}>
                            {error}
                          </p>
                        ))
                      );
                    }
                    return (
                      <p className="mb-2 text-sm" key={key}>
                        {validationErrors[key]}
                      </p>
                    );
                  })} */}
                </div>
              )}
            </div>
            <div>
              <button
                type="button"
                onClick={resetPassword}
                className="w-full flex justify-center py-3 px-4 border border-transparent text-md font-bold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                {loading ? (
                  <Loader className="animate-spin flex items-center text-center" />
                ) : (
                  "Reset password"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
