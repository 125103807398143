import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SiteSettingsProvider } from "./Hooks/Settings";
import ContextProviders from "./Context";
import { Helmet, HelmetProvider } from "react-helmet-async";

// Register service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    const swUrl = "/sw.js";
    navigator.serviceWorker.register(swUrl);
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>NewsVista</title>
        <link rel="canonical" href="https://www.newsvista.com" />
      </Helmet>

      <BrowserRouter>
        <ContextProviders>
          <SiteSettingsProvider>
            <App />
          </SiteSettingsProvider>
        </ContextProviders>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
