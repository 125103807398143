import client from "./client";
const fetchComments = async (articleId) => {
  try {
    const response = await client.get(`/comments?articleId=${articleId}`);
    return response.data.comments;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const getRecentArticles = async () => {
  try {
    const { data } = await client.get(`/most-recent`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return {
      recentArticles: data.recentArticles || [],
      sportsArticle: data.sportsArticle || null,
      recentTechArticle: data.recentTechArticle || null,
      otherTechArticles: data.otherTechArticles || [],
      worldAndBusinessArticles: data.worldAndBusinessArticles || [],
    };
  } catch (error) {
    console.error("Error fetching comments:", error);
    return {
      recentArticles: [],
      sportsArticle: null,
      recentTechArticle: null,
      otherTechArticles: [],
      worldAndBusinessArticles: [],
    };
  }
};

export const fetchSiteSettings = async () => {
  try {
    const { data } = await client.get(`/settings/get`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    if (!data) {
      console.log("No site settings found");
      return null; // or return a default value like {}
    }

    return data;
  } catch (error) {
    console.error("Error fetching site settings:", error);
    return null; // Return null or a fallback value to avoid breaking the flow
  }
};

export const getImageFiles = async () => {
  try {
    const { data } = await client.get(`/all-images`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const deleteImageFiles = async (id) => {
  try {
    const { data } = await client.delete(`/images/delete-by-user/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const fetchApplicationForARole = async () => {
  try {
    const { data } = await client.get("/applications", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const updateApplicationForARole = async (id, status) => {
  try {
    const { data } = await client.put(
      `/applications/${id}`,
      { status },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};

export const applyForARole = async (
  role,
  additionalInfo,
  age,
  country,
  reason,
  hasGoodInternet
) => {
  try {
    const { data } = await client.post(
      `/apply`,
      { role, additionalInfo, age, country, reason, hasGoodInternet },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const getBiography = async () => {
  try {
    const { data } = await client.get(`/bio/`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const deleteBiography = async (id) => {
  try {
    const { data } = await client.delete(`/bio/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const updateBiography = async (id, formDataToSend) => {
  try {
    const { data } = await client.put(`/bio/${id}`, formDataToSend, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const createBiography = async (formDataToSend) => {
  try {
    const { data } = await client.post(`/bio`, formDataToSend, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const getBiographyDetails = async (bioName) => {
  try {
    const { data } = await client.get(`/bio/${bioName}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const getArticlesByPerson = async (name, { page = 1, limit = 5 }) => {
  try {
    const { data } = await client.get(
      `/bio-articles?person=${name}&page=${page}&limit=${limit}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
export const getBiographies = async (page = 1, limit = 5) => {
  try {
    const { data } = await client.get(
      `/biographies?page=${page}&limit=${limit}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
