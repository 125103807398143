import React, { useEffect, useState } from "react";
import Editor from "./TextEditor/TextEditorWithQuill";
import socket from "../Helpers/Socket";
import CreatableSelect from "react-select/creatable";
import {
  getCategories,
  getLastFiveLiveUpdateNewsType,
  getLiveUpdateHeadLine,
  getSubCat,
  getTypes,
} from "../Api/createNews";
import { toast } from "react-toastify";
import ImageSelector from "../Hooks/ImageSelector";
import { commonInputClasses } from "../utils/theme";
import Submit from "../Form/Submit";
import Label from "../Form/Label";
import { cities } from "./Components/City";

const NewsForm = ({ busy, btnTitle, initialState, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");
  const [types, setTypes] = useState([]);
  const [file, setFile] = useState(null);
  const [NewsCategory, setNewsCategory] = useState([]);
  const [NewsSubCategory, setNewsSubCategory] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedNewsCategory, setSelectedNewsCategory] = useState("");
  const [selectedNewsSubCategory, setSelectedNewsSubCategory] = useState("");
  const [selectedNewsTags, setSelectedNewsTags] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [bioName, setBioName] = useState(false);
  const [liveUpdateTypes, setLiveUpdateTypes] = useState([]);
  const [selectedLiveUpdateType, setSelectedLiveUpdateType] = useState("");
  const [liveUpdateHeadline, setLiveUpdateHeadline] = useState([]);
  const [isLiveUpdate, setIsLiveUpdate] = useState(false);
  const [showHeadLine, setShowHeadLine] = useState("");
  const [selectedImageForUI, setSelectedImageForUI] = useState("");

  const handleNewsTypeChange = (e) => {
    setSelectedType(e.target.value);
    // console.log(e.target.value);
  };
  const handleNewsCategoryChange = (e) => {
    setSelectedNewsCategory(e.target.value);
    // console.log(e.target.value);
  };
  const handleNewsSubCategoryChange = (e) => {
    setSelectedNewsSubCategory(e.target.value);
    // console.log(e.target.value);
  };
  const handleTagChange = (newValue) => {
    setSelectedNewsTags(newValue);
  };

  const maxFileSizeinBytes = 100 * 1024 * 1024;

  const updateImageForUI = (file) => {
    const url = URL.createObjectURL(file);
    setSelectedImageForUI(url);
    setFile(file);
  };

  const handleFileChange = ({ target }) => {
    const { name, files } = target;
    if (name === "file") {
      const file = files[0];
      updateImageForUI(file);
      if (!file) {
        console.error("No file selected.");
        return;
      }
      if (file.size > maxFileSizeinBytes) {
        toast.error("File Size exceeds the maximum allowed size.");
        return;
      }
      return setFile(file);
    }
  };

  const handleLiveUpdateTypeChange = async (e) => {
    setSelectedLiveUpdateType(e.target.value);
    setShowHeadLine(e.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorText(content);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extract the tag values
    const tags = selectedNewsTags?.map((tag) => tag?.value || tag?.label);
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    if (!selectedNewsCategory) {
      toast.error("Please select a news category");
      return;
    }
    if (!selectedType) {
      toast.error("Please select a news Type");
      return;
    }
    if (!authorName) {
      toast.error("Please write the Author Name");
      return;
    }
    if (!selectedNewsTags) {
      toast.error("Please Please tag this article");
      return;
    }

    formData.append("title", title);
    formData.append("city", city);
    formData.append("type", selectedType);
    formData.append("isLiveUpdate", isLiveUpdate);
    formData.append("liveUpdateType", selectedLiveUpdateType);
    formData.append("liveUpdateHeadline", liveUpdateHeadline);
    formData.append("newsCategory", selectedNewsCategory);
    formData.append("subCategory", selectedNewsSubCategory);
    formData.append("tags", JSON?.stringify(tags));
    formData.append("editorText", editorText);
    formData.append("authorName", authorName);
    formData.append("name", bioName);
    onSubmit(formData);
    socket.emit("liveUpdate", true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const typeResponse = await getTypes();
        setTypes(typeResponse);

        const categoryResponse = await getCategories();
        setNewsCategory(categoryResponse);

        if (selectedType === "LiveUpdate") {
          setIsLiveUpdate(true);
          const liveUpdateResponse = await getLastFiveLiveUpdateNewsType();
          setLiveUpdateTypes(
            liveUpdateResponse?.map((item) => item?.liveUpdateType)
          );
          if (showHeadLine) {
            const liveUpdateHeadlineResponse = await getLiveUpdateHeadLine(
              showHeadLine
            );
            setLiveUpdateHeadline(liveUpdateHeadlineResponse.data);
          }
        } else {
          setIsLiveUpdate(false);
        }
        if (selectedLiveUpdateType) {
          const selectedLiveUpdateTypeResponse =
            await getLastFiveLiveUpdateNewsType(selectedLiveUpdateType);
          setLiveUpdateHeadline(selectedLiveUpdateTypeResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedType, selectedLiveUpdateType]);
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedNewsCategory) {
          const subcategoryResponse = await getSubCat(selectedNewsCategory);
          setNewsSubCategory(subcategoryResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchSubcategories();
  }, [selectedNewsCategory]);

  useEffect(() => {
    if (initialState) {
      setEditorText(initialState.editorText);
      setTitle(initialState.title);
      setCity(initialState.city);
      setSelectedType(initialState.type);
      setIsLiveUpdate(initialState.isLiveUpdate);
      setSelectedLiveUpdateType(initialState.liveUpdateType);
      setLiveUpdateHeadline(initialState.liveUpdateHeadline);
      setSelectedNewsCategory(initialState.newsCategory);
      setSelectedNewsSubCategory(initialState.subCategory);
      setSelectedNewsTags(
        Array.isArray(initialState?.tags)
          ? initialState?.tags?.map((tag) =>
              typeof tag === "string"
                ? { label: tag, value: tag }
                : { label: tag?.name, value: tag?._id }
            )
          : []
      );
      setAuthorName(initialState.authorName);
      setBioName(initialState?.name?.stageName || "");
      setSelectedImageForUI(initialState.file);
      setFile(null);
    }
  }, [initialState]);

  return (
    <>
      <div
        onSubmit={handleSubmit}
        className="md:flex space-x-3 md:mt-32 h-96 overflow-x-auto"
      >
        <div className="md:w-[70%] space-y-5">
          <div>
            <Label htmlFor="title">Title</Label>
            <input
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              name="title"
              type="text"
              className={
                commonInputClasses + " border-b-2 font-semibold text-xl"
              }
              placeholder="Titanic"
            />
          </div>
          <div>
            <Label htmlFor="type">News Type</Label>
            <select
              id="NewsType"
              name="NewsType"
              value={selectedType}
              onChange={handleNewsTypeChange}
              className="w-full border-2 bg-white border-light-subtle focus:border-primary p-1 pr-10 outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
            >
              <option value="" disabled>
                Select News Type
              </option>
              {types?.map((types, index) => (
                <option value={types?.name} key={index}>
                  {types?.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            {selectedType === "LiveUpdate" && (
              <>
                {liveUpdateTypes?.length !== 0 ? (
                  <div className=" bg-yellow-100 px-4 mb-10 rounded">
                    <div className="flex justify-between py-5">
                      <div className=" w-full">
                        <Label htmlFor="LiveUpdateType">
                          Live Update News Type
                        </Label>
                        <input
                          type="text"
                          id="LiveUpdateType"
                          name="LiveUpdateType"
                          placeholder="eg: ukraine-russia-war"
                          onChange={handleLiveUpdateTypeChange}
                          className="mt-2 p-3 mr-2 bg-gray-200 focus:outline-none w-full border rounded-md"
                          required
                        />
                      </div>
                      <p className=" font-medium text-xs flex items-center mb-2 ml-2 text-gray-600">
                        OR
                      </p>
                      <div className=" w-full">
                        <Label htmlFor="LiveUpdateType">
                          Live Update News Type
                        </Label>
                        <select
                          id="LiveUpdateType"
                          name="LiveUpdateType"
                          value={selectedLiveUpdateType}
                          onChange={handleLiveUpdateTypeChange}
                          className="mt-2 p-[0.95rem] ml-2 bg-gray-200 focus:outline-none w-full border rounded-md"
                        >
                          <option value="" disabled>
                            Select Live News Type
                          </option>
                          {liveUpdateTypes?.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="pb-5">
                      <Label htmlFor="LiveUpdateType">
                        Live Update Main Headline
                      </Label>
                      <input
                        type="text"
                        id="LiveUpdateType"
                        name="LiveUpdateType"
                        value={liveUpdateHeadline}
                        onChange={(e) => {
                          setLiveUpdateHeadline(e.target.value);
                        }}
                        placeholder="Live Update Main Headline"
                        className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                        required
                      />
                    </div>
                  </div>
                ) : (
                  <div className=" bg-yellow-200 px-4 mb-10 rounded">
                    <div className="py-5">
                      <Label htmlFor="LiveUpdateType">
                        Live Update News Type
                      </Label>
                      <input
                        type="text"
                        id="LiveUpdateType"
                        name="LiveUpdateType"
                        placeholder="eg: ukraine-russia-war"
                        onChange={handleLiveUpdateTypeChange}
                        className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                        required
                      />
                    </div>
                    <div className="pb-5">
                      <Label htmlFor="LiveUpdateType">
                        Live Update Main Headline
                      </Label>
                      <input
                        type="text"
                        id="LiveUpdateType"
                        name="LiveUpdateType"
                        value={liveUpdateHeadline}
                        onChange={(e) => {
                          setLiveUpdateHeadline(e.target.value);
                        }}
                        placeholder="Live Update Main Headline"
                        className="mt-2 p-3 bg-gray-200 focus:outline-none w-full border rounded-md"
                        required
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div>
            <Label htmlFor="">Content Text</Label>
            <Editor
              placeholder="Content Text..."
              onChange={handleEditorChange}
              initialText={"" || editorText}
            />
          </div>

          <div className="w-full mr-2">
            <Label htmlFor="tags">Tags</Label>
            <CreatableSelect
              id="tags"
              isMulti
              // options={NewsTag?.map((tag) => ({
              //   label: tag?.name,
              //   value: tag?._id,
              // }))}
              value={Array.isArray(selectedNewsTags) ? selectedNewsTags : []}
              onChange={handleTagChange}
              placeholder="Enter or select tags"
              className="border-2 bg-white border-light-subtle focus:border-primary p-1  outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
              // formatCreateLabel={(inputValue) => `Create tag: "${inputValue}"`}
            />
          </div>
          <div className="w-full ml-2">
            <Label htmlFor="author">Author</Label>
            <input
              type="text"
              id="author"
              name="author"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              className={
                commonInputClasses + " border-b-2 font-semibold text-xl"
              }
              required
            />
          </div>

          <Submit
            busy={busy}
            value={"Publish"}
            onClick={handleSubmit}
            type="button"
          />
        </div>
        <div className="md:w-[30%] space-y-5">
          <div>
            <div>
              <Label htmlFor="">Upload Image</Label>
              <ImageSelector
                name="file"
                onChange={handleFileChange}
                selectedImage={selectedImageForUI}
                label="Select image"
                accept="image/jpg, image/jpeg, image/png, image/gif, image/webp"
              />
            </div>
          </div>
          <div className="w-full">
            <div>
              <Label htmlFor="NewsCategory">Category</Label>
              <select
                id="NewsCategory"
                name="NewsCategory"
                value={selectedNewsCategory}
                onChange={handleNewsCategoryChange}
                className="w-full border-2 bg-white border-light-subtle focus:border-primary p-1 pr-10 outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
                required
              >
                <option value="" disabled>
                  Select News Category
                </option>
                {NewsCategory?.map((category, index) => (
                  <option value={category?.title} key={index}>
                    {category?.title}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Label htmlFor="NewsSubCategory">Sub Category</Label>
              <select
                id="NewsSubCategory"
                name="NewsSubCategory"
                value={selectedNewsSubCategory}
                onChange={handleNewsSubCategoryChange}
                className="w-full border-2 bg-white border-light-subtle focus:border-primary p-1 !pr-10 outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
              >
                <option value="" disabled>
                  Select News Sub Category
                </option>
                {NewsSubCategory?.map((subcategory, index) => (
                  <option value={subcategory?.name} key={index}>
                    {subcategory?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div>
                <Label htmlFor="city">
                  City{" "}
                  <span className="text-xs">
                    (Which city does the incident happen?)
                  </span>
                </Label>
                <CreatableSelect
                  value={cities?.find((c) => c?.value === city)}
                  onChange={(option) => setCity(option?.value)}
                  options={cities}
                  className="border-2 bg-white border-light-subtle focus:border-primary p-1  outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
                  placeholder="Enter or select City or Country"
                />
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-wrap">
                <Label htmlFor="bioName">Bio Name</Label>
                <span className="text-[10px] font-bold italic">
                  (artist name, player name, celebrity name, e.t.c)
                </span>
              </div>
              <input
                type="text"
                id="bioName"
                name="bioName"
                value={bioName}
                onChange={(e) => setBioName(e.target.value)}
                className={
                  commonInputClasses + " border-b-2 font-semibold text-xl"
                }
                required
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsForm;
