import React, { useEffect, useState } from "react";
import socket from "../Helpers/Socket";
import client from "../Api/client";

const LiveScore = () => {
  const [groupedScores, setGroupedScores] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLiveScores = async () => {
      try {
        setLoading(true);
        const response = await client.get("/live-scores");
        console.log("Grouped Matches Data:", response?.data?.data);
        setGroupedScores(response?.data?.data || {});
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchLiveScores();
  }, []);

  const formatMatchDate = (dateString) => {
    const matchDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (matchDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (matchDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return matchDate.toLocaleDateString();
    }
  };

  const formatMatchTime = (dateString) => {
    const matchDate = new Date(dateString);
    return matchDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Function to check if a match is within the last 3 days
  const isMatchVisible = (dateString) => {
    const matchDate = new Date(dateString);
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    return matchDate >= threeDaysAgo;
  };

  if (loading) {
    return <div className="text-center text-xl py-10">Loading matches...</div>;
  }

  if (Object.keys(groupedScores).length === 0) {
    return (
      <div className="text-center text-xl py-10">No matches currently.</div>
    );
  }

  //   useEffect(() => {
  //     // Listen for live score updates
  //     socket.on("liveScoreUpdate", (liveMatches) => {
  //       setScores(liveMatches);
  //     });

  //     // Cleanup the socket connection on component unmount
  //     return () => {
  //       socket.off("liveScoreUpdate");
  //     };
  //   }, []);
  return (
    <div className="bg-gray-100 max-h-60 overflow-y-auto py-10">
      <h2 className="md:text-4xl text-sm text-center font-bold mb-8">
        Matches
      </h2>
      <div className="mx-auto flex flex-wrap justify-center gap-8">
        {Object?.entries(groupedScores)?.map(([competitionName, stages]) => (
          <div key={competitionName} className="w-full mb-8 mx-4">
            <h3 className="md:text-2xl text-sm font-bold">{competitionName}</h3>
            {Object?.entries(stages)?.map(([stageName, matches]) => (
              <div key={stageName} className="mt-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-center">
                    {matches?.length > 0 && (
                      <img
                        src={matches[0].competition?.emblem}
                        alt={matches[0].competition?.type}
                        className="w-12 h-12"
                      />
                    )}
                    <h4 className="md:text-xl text-sm font-semibold">
                      {stageName}
                    </h4>
                  </div>
                  {/* Display area name */}
                  {matches?.length > 0 && (
                    <p className="text-gray-500 flex items-center text-center">
                      <img
                        src={matches[0].area?.flag}
                        alt={matches[0].area?.name}
                        className="w-12 h-12"
                      />{" "}
                      <span className="text-base font-bold">
                        {matches[0].area?.name}
                      </span>
                    </p>
                  )}
                </div>
                <div className="flex flex-wrap justify-between gap-2">
                  {matches
                    ?.filter((match) =>
                      match?.status === "FINISHED"
                        ? isMatchVisible(match?.utcDate)
                        : true
                    )
                    ?.map((match, index) => (
                      <div
                        key={index}
                        className="bg-white shadow-lg rounded-lg p-3 flex flex-col justify-between w-full sm:w-full md:w-1/3 lg:w-1/5"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex flex-col items-center">
                            <img
                              src={match?.homeTeam?.crest}
                              alt={match?.homeTeam?.name}
                              className="md:w-14 md:h-14 w-12 h-12 object-cover rounded-full"
                            />
                            <span className="md:text-lg text-sm font-semibold mt-5">
                              {match?.homeTeam?.name}
                            </span>
                            <small className=" bg-green-800 text-gray-300 font-semibold rounded-full px-2 text-xs">
                              home
                            </small>
                          </div>
                          <div className="!flex md:text-2xl text-sm font-bold text-center items-center text-gray-800 px-2">
                            <p>{match?.score?.fullTime?.home}</p> -{" "}
                            <p>{match?.score?.fullTime?.away}</p>
                          </div>
                          <div className="flex flex-col items-center">
                            <img
                              src={match?.awayTeam?.crest}
                              alt={match?.awayTeam?.name}
                              className="md:w-14 md:h-14 w-12 h-12 object-cover rounded-full"
                            />
                            <span className="md:text-lg text-sm font-semibold mt-5">
                              {match?.awayTeam?.name}
                            </span>
                            <small className=" bg-red-800 text-gray-300 font-semibold rounded-full px-2 text-xs">
                              away
                            </small>
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          <span
                            className={`px-2 py-1 md:text-[10px] text-sm font-semibold rounded-full ${
                              match.status === "LIVE"
                                ? "bg-green-500 text-white"
                                : "bg-gray-500 text-white"
                            }`}
                          >
                            {match.status}
                          </span>
                        </div>
                        <div className="text-center mt-4">
                          <span className="text-gray-600 md:text-[14px] text-sm">
                            {formatMatchDate(match?.utcDate)} at{" "}
                            <small className="font-bold">
                              {formatMatchTime(match?.utcDate)}
                            </small>
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveScore;
