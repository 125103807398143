import client from "./client";

export const getIsAuth = async () => {
  try {
    const { data } = await client.get("/isAuth", {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;
    return { error: error.message || error };
  }
};
export const login = async (email, password) => {
  try {
    const { data } = await client.post("/login", email, password, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const logout = async () => {
  try {
    const { data } = await client.post("/logout");
    return data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getUsers = async () => {
  try {
    const { data } = await client.get("/users", {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const deleteUsers = async (userId) => {
  try {
    const { data } = await client.delete(`/deleteUsersManually/${userId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getDetails = async (userId) => {
  try {
    const { data } = await client.get(`/me/${userId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const updateUser = async (userId, formData) => {
  try {
    const { data } = await client.put(`/me/${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getRoles = async () => {
  try {
    const { data } = await client.get("/getRoles", {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const updateUserStatus = async (userId, status) => {
  try {
    const { data } = await client.put(
      "/update-status",
      { userId, status },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getUserAnalytics = async () => {
  try {
    const { data } = await client.get("/user-analytics", {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const initiatePasswordReset = async (email) => {
  try {
    const { data } = await client.post(
      "/initiate-reset",
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const resetMyPassword = async (token, newPassword) => {
  try {
    const { data } = await client.post(
      "/reset-password",
      {
        token,
        newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
