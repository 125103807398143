import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import "quill/dist/quill.snow.css";

const Editor = ({ onChange, initialText }) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      blotFormatter: {},
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { list: "check" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ size: ["small", false, "large", "huge"] }],
        ["link", "image", "video"],
        ["clean"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ],
    },
    formats: [
      "header",
      "font",
      "size",
      "color",
      "background",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "code-block",
      "align",
    ],
  });

  const [hasSetInitialText, setHasSetInitialText] = useState(false);

  if (Quill && !quill) {
    Quill.register("modules/blotFormatter", BlotFormatter);
  }

  useEffect(() => {
    if (quill && initialText && !hasSetInitialText) {
      quill.clipboard.dangerouslyPasteHTML(initialText);
      setHasSetInitialText(true);
    }

    if (quill) {
      quill.getModule("toolbar").addHandler("image", () => {
        selectLocalImages();
      });

      quill.getModule("toolbar").addHandler("video", () => {
        const videoUrl = prompt("Enter video URL:");
        if (videoUrl) {
          const range = quill.getSelection();
          if (
            videoUrl.includes("youtube.com") ||
            videoUrl.includes("youtu.be")
          ) {
            let embedUrl = videoUrl;
            if (videoUrl.includes("watch?v=")) {
              embedUrl = videoUrl.replace("watch?v=", "embed/");
            } else if (videoUrl.includes("youtu.be")) {
              const videoId = videoUrl.split("/").pop();
              embedUrl = `https://www.youtube.com/embed/${videoId}`;
            }
            quill.insertEmbed(range.index, "video", embedUrl);
          } else {
            quill.insertEmbed(range.index, "video", videoUrl);
          }
        }
      });

      quill.getModule("toolbar").addHandler("link", () => {
        const range = quill.getSelection();
        const selectedText = quill.getText(range.index, range.length);
        const postUrl = prompt(
          "Enter the URL for the selected text:",
          selectedText
        );

        if (postUrl) {
          quill.formatText(range.index, range.length, { link: postUrl });
        }
      });

      quill.on("text-change", () => {
        onChange && onChange(quill.root.innerHTML);
      });

      quill.keyboard.addBinding({ key: "Backspace" }, handleBackspace);
    }
  }, [quill, initialText, hasSetInitialText, onChange]);

  // Embed Twitter Post
  const embedTwitterPost = () => {
    const twitterUrl = prompt("Enter Twitter post URL:");
    if (twitterUrl) {
      const range = quill.getSelection();
      const embedCode = `<blockquote class="twitter-tweet"><a href="${twitterUrl}"></a></blockquote>`;
      quill.clipboard.dangerouslyPasteHTML(range.index, embedCode);
      loadTwitterEmbedScript();
    }
  };

  // Embed Instagram Post
  const embedInstagramPost = () => {
    const instagramUrl = prompt("Enter Instagram post URL:");
    if (instagramUrl) {
      const range = quill.getSelection();
      const embedCode = `<blockquote class="instagram-media" data-instgrm-permalink="${instagramUrl}" data-instgrm-version="12"></blockquote>`;
      quill.clipboard.dangerouslyPasteHTML(range.index, embedCode);
      loadInstagramEmbedScript();
    }
  };

  // Load Instagram Embed Script
  const loadInstagramEmbedScript = () => {
    if (
      !document.querySelector(
        'script[src="https://www.instagram.com/embed.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://www.instagram.com/embed.js";
      document.body.appendChild(script);
    } else {
      window.instgrm?.Embeds.process();
    }
  };

  // Load Twitter Embed Script
  const loadTwitterEmbedScript = () => {
    if (
      !document.querySelector(
        'script[src="https://platform.twitter.com/widgets.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      document.body.appendChild(script);
    } else {
      window.twttr?.widgets.load();
    }
  };

  const selectLocalImages = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", true);
    input.click();

    input.onchange = () => {
      const files = input.files;
      const range = quill.getSelection();

      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result;
          quill.setSelection(range.index);
          quill.insertEmbed(range.index, "image", imageUrl, "user");
        };
        reader.readAsDataURL(file);
      });
    };
  };

  const handleBackspace = () => {
    const range = quill.getSelection();
    if (range) {
      const [leaf] = quill.getLeaf(range.index);
      if (leaf && leaf.domNode && leaf.domNode.tagName === "IMG") {
        leaf.remove();
      }
    }
  };

  return (
    <>
      <div ref={quillRef} />
      {/* External buttons for embedding posts */}
      <button
        onClick={embedTwitterPost}
        className="bg-blue-400 font-bold text-sm rounded-lg px-2 text-white mr-2"
      >
        Twitter Link
      </button>
      <button
        onClick={embedInstagramPost}
        className="bg-red-400 font-bold text-sm rounded-lg px-2 text-white mr-2"
      >
        Instagram Link
      </button>
    </>
  );
};

export default Editor;
