import React from "react";
import { Link } from "react-router-dom";
import MidCard from "../components/Cards/MidCard";
import TextOnly from "../components/Cards/TextOnly";
import ColumnHead from "../Common/ColumnHead/ColumnHead";
import UseNewsFetch from "../Common/FetchNews/UseNewsFetch";
import getDateString from "../Helpers/DateString";
import VerticalAd from "../components/VerticleAd";

const FifthSection = () => {
  const {
    data: midCards,
    loading: loading1,
    error: error1,
  } = UseNewsFetch("Business", null, null, null, 1, "desc");
  const {
    data: textOnly,
    loading: loading2,
    error: error2,
  } = UseNewsFetch(null, "Markets", null, null, 5, "desc");
  const {
    data: midCards2,
    loading: loading3,
    error: error3,
  } = UseNewsFetch("Travel", null, null, null, 1, "desc");
  const {
    data: textOnly2,
    loading: loading4,
    error: error4,
  } = UseNewsFetch("Travel", null, "Travel", null, 5, "desc");

  return (
    <div>
      <h2 className="pl-3.5 pt-6 pb-2 text-4xl font-bold">Featured Sections</h2>
      <div className="flex flex-wrap">
        {/* First Column (2 parts) */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-4">
          <ColumnHead columnHeadTag="NV BUSINESS" />
          {midCards &&
            midCards?.map((card, index) => (
              <MidCard
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                loading={loading3}
                db={true}
              />
            ))}
          {textOnly &&
            textOnly?.length > 0 &&
            textOnly?.map((card, index) => (
              <TextOnly
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                text={card?.title}
                loading={loading3}
              />
            ))}
        </div>

        {/* Second Column (1 part) */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-4">
          {/* Content for the second column */}
          <ColumnHead columnHeadTag="NV TRAVEL" />
          {midCards2?.map((card, index) => (
            <MidCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
              db={true}
            />
          ))}
          {textOnly2 &&
            textOnly2?.length > 0 &&
            textOnly2?.map((card, index) => (
              <TextOnly
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                text={card?.title}
                loading={loading3}
              />
            ))}
        </div>

        {/* Third Column (1 part) */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-4">
          {/* Content for the third column */}
          <Link to="/#">
            {/* Replace 'videos/sample.mp4' with the path to your video file */}
            <div className="relative w-full h-full group">
              {/* Image */}
              <VerticalAd />
              {/* <img
                src="images/FifthSection/fifthadv.jpg"
                alt="analysis"
                className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
              /> */}
              {/* Hover Overlay */}
              <div className="absolute inset-0 bg-gray-400 opacity-0">
                {/* Additional content can be added here, if needed */}
              </div>
              {/* <div className="text-xs">Advertisement</div> */}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FifthSection;
