import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSiteSettings } from "../Hooks/Settings";
import { useAuth } from "../Hooks";
import { getIsAuth } from "../Api/auth";

const AuthFooter = () => {
  const currentYear = new Date().getFullYear();
  const { siteLogo } = useSiteSettings();
  const [userRole, setUserRole] = useState(null);

  const { authState } = useAuth();
  const navigate = useNavigate();

  const { isLoggedIn } = authState;

  const fetchUserRole = async () => {
    try {
      const response = await getIsAuth();
      if (response?.user?.role) {
        setUserRole(response.user.role);
      } else {
        setUserRole(null);
      }
    } catch (error) {
      console.log(error);
      setUserRole(null);
    }
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      fetchUserRole();
    }
  }, [isLoggedIn]);

  // Handle redirect based on user role when clicking on Profile
  const handleProfileClick = () => {
    if (userRole === "Admin") {
      navigate("/Admin");
    } else if (userRole === "Editor") {
      navigate("/Editor");
    } else if (userRole === "Writer") {
      navigate("/Writer");
    } else {
      navigate("/"); // Default redirection for other roles or if role is undefined
    }
  };

  return (
    <div className="bg-black w-full px-4">
      <div className="bg-black w-full pt-8 pb-8 px-5 flex flex-col items-start text white border-t border-b border-gray-700">
        <div className=" w-full flex items-center justify-between">
          <div className="flex items-center">
            <NavLink to="/">
              <img
                src={siteLogo || "/default-logo.png"}
                alt=""
                className="w-auto h-8 mr-4"
              />
            </NavLink>
            <div className="font-bold text-2xl text-white">NG</div>
          </div>
          <div className="flex flex-wrap items-center">
            <NavLink
              to="/Watch"
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              Watch
            </NavLink>
            <NavLink
              to="/Listen"
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              Listen
            </NavLink>
            <NavLink
              to="/live-tv"
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              Live TV
            </NavLink>
            <div className="border-r border-gray-500 w-1 h-6 mx-4"></div>\
            <div className="text-white text-[0.937rem] font-bold ">
              Follow VB
            </div>
            <NavLink
              to="https://web.facebook.com/profile.php?id=61563799709889"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <img
                src="images/Footer/Facebook.png"
                className="h-full w-full"
                alt=""
              />
            </NavLink>
            <NavLink
              to="https://x.com/verboxupdates"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <img src="images/Footer/X.png" className="h-full w-full" alt="" />
            </NavLink>
            <NavLink
              to="/#"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <img
                src="images/Footer/Instagram.png"
                className="h-full w-full"
                alt=""
              />
            </NavLink>
            <NavLink
              to="https://www.tiktok.com/@verboxupdates"
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <img
                src="images/Footer/Tiktok.png"
                className="h-full w-full"
                alt=""
              />
            </NavLink>
            {isLoggedIn === true ? (
              <button
                onClick={handleProfileClick}
                className="text-white p-1 rounded text-[0.937rem] border border-white font-bold"
              >
                Profile
              </button>
            ) : (
              <button className="border border-white rounded-xl px-4 py-2 ml-5 text-white hover:bg-gray-800">
                <NavLink to="/login">Log In</NavLink>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="text-white text-sm flex flex-wrap items-center justify-start w-full mt-4 ">
        <NavLink to="/terms-of-use">Terms of Use</NavLink>
        <NavLink to="/privacy-policy" className="ml-4">
          Privacy Policy
        </NavLink>
        <NavLink to="/About" className="ml-4">
          Accessibility & CC
        </NavLink>
        <NavLink to="/privacy-policy" className="ml-4">
          About
        </NavLink>
      </div>
      <div className="text-white text-sm mt-4">
        © {currentYear} Cable News Network. A Hidden Treasure Technologies.
        Discovery Company. All Rights
      </div>
      <div className="text-white text-sm pb-6">
        Reserved. VB Sans ™ & © 2024 Cable News Network.
      </div>
    </div>
  );
};

export default AuthFooter;
