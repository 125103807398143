import React, { useEffect, useState } from "react";
import { getNewsForUpdate, updateNews } from "../../Api/createNews";
import ModalContainer from "../../components/Modal/ModalContainer";
import NewsForm from "../NewsForm";
import { toast } from "react-toastify";

export default function NewsUpdate({ newsId, visible, onSuccess, onClose }) {
  const [busy, setBusy] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectedNews, setSelectedNews] = useState(false);

  const handleSubmit = async (data) => {
    setBusy(true);
    const { error, news, message } = await updateNews(newsId, data);
    setBusy(false);
    if (error) return toast.error(error);

    toast.success(message);
    onSuccess(news);
  };

  const fetchNewsToUpdate = async () => {
    const { news, error } = await getNewsForUpdate(newsId);
    if (error) return toast.error(error);
    setReady(true);
    setSelectedNews(news);
  };
  useEffect(() => {
    if (newsId) fetchNewsToUpdate();
  }, [newsId]);

  return (
    <ModalContainer visible={visible} onClose={onClose}>
      {ready ? (
        <NewsForm
          initialState={selectedNews}
          btnTitle="Update"
          onSubmit={!busy ? handleSubmit : null}
          busy={busy}
        />
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <p className="text-light-subtle !bg-transparent animate-pulse text-xl">
            Please wait...
          </p>
        </div>
      )}
    </ModalContainer>
  );
}
