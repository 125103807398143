import React from "react";
import ColumnHead from "../Common/ColumnHead/ColumnHead";
import MidCard from "../components/Cards/MidCard";
import TextOnly from "../components/Cards/TextOnly";
import BigCard from "../components/Cards/BigCard";
import SmallHorizontalCard from "../components/Cards/SmallHorizontalCard";
import { Link } from "react-router-dom";
import UseNewsFetch from "../Common/FetchNews/UseNewsFetch";
import TagSmallCard from "../components/TagSmallCard";
import getDateString from "../Helpers/DateString";
import SquareAd from "../components/SquareAd";
// import getDateString from "../Helpers/DateString";

const SecondSection = () => {
  const {
    data: midCard,
    loading: loading1,
    error: error1,
  } = UseNewsFetch(null, null, "Top Story", null, 3, "desc");
  const {
    data: midCard2,
    loading: loading,
    error: error,
  } = UseNewsFetch("News", null, null, null, 3, "desc");
  const {
    data: bigCard,
    loading: loading2,
    error: error2,
  } = UseNewsFetch(null, null, "Featured", null, 1, "desc");
  const {
    data: textOnly,
    loading: loading3,
    error: error3,
  } = UseNewsFetch(null, null, "Top Story", null, 6, "desc");
  const {
    data: smallHorizontalCard,
    loading: loading4,
    error: error4,
  } = UseNewsFetch(null, null, "Travel", null, 2, "desc");
  const {
    data: smallHorizontalCard3,
    loading: loading5,
    error: error5,
  } = UseNewsFetch(null, "subCategory", null, null, 4, "desc");
  const {
    data: smallHorizontalCard4,
    loading: loading6,
    error: error6,
  } = UseNewsFetch(null, null, "Education", null, 4, "desc");

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/4 p-4">
        <ColumnHead columnHeadTag="MORE TOP STORIES" />
        {midCard?.map((card, index) => (
          <MidCard
            key={index}
            link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
              card?._id
            }`}
            imageSrc={card?.file}
            text={card?.title}
            tags={card?.tags}
            loading={loading3}
            db={true}
          />
        ))}
        {textOnly && textOnly?.length > 0 && (
          <>
            {textOnly?.map((card, index) => (
              <TextOnly
                key={index}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                text={card?.title}
                loading={loading3}
              />
            ))}
          </>
        )}
      </div>
      <div className="w-full md:w-1/2 p-4">
        <ColumnHead columnHeadTag="FEATURED" />
        {bigCard &&
          bigCard?.map((card, index) => (
            <BigCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
              video={card?.video}
            />
          ))}
        {smallHorizontalCard &&
          smallHorizontalCard?.map((card, index) => (
            <SmallHorizontalCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
              video={card?.video}
            />
          ))}
        <div className="mt-8">
          <ColumnHead columnHeadTag="NEWS" />
          {midCard2?.map((card, index) => (
            <MidCard
              key={index}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              tags={card?.tags}
              loading={loading}
              db={true}
            />
          ))}
        </div>
      </div>
      <div className="w-full md:w-1/4 p-4">
        <Link to="/adv-link">
          <div className="w-full h-64 group mb-4 ">
            <div className="w-full h-full group">
              <SquareAd />
              {/* <img
                src="images/SecondSection/adv.jpg"
                alt=""
                className="w-full h-full object-cover"
              /> */}
            </div>
            {/* <div className="text-sm">Advertisement</div> */}
          </div>
        </Link>
        {smallHorizontalCard3 &&
          smallHorizontalCard3?.map((card, index) => (
            <TagSmallCard
              key={`cat1-${index}`}
              link={`${getDateString(card?.createdAt)}/${card?.newsCategory}/${
                card?._id
              }`}
              imageSrc={card?.file}
              text={card?.title}
              loading={loading3}
            />
          ))}
        <div>
          <ColumnHead columnHeadTag="EDUCATION" />
          {smallHorizontalCard4 &&
            smallHorizontalCard4?.map((card, index) => (
              <TagSmallCard
                key={`cat2-${index}`}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                text={card?.title}
                loading={loading6}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
