// SiteSettingsContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchSiteSettings } from "../Api/apis";

const SiteSettingsContext = createContext();

export const SiteSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetchSiteSettings();
        setSettings(response);
      } catch (error) {
        console.error("Error fetching site settings", error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <SiteSettingsContext.Provider value={settings}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export const useSiteSettings = () => useContext(SiteSettingsContext);
