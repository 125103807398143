import React from "react";

const FileDisplay = ({ file, smallImg, className }) => {
  if (!file || !file?.url) {
    return null; // Handle case where file or file URL is missing
  }

  return (
    <div className="file-display">
      <img
        src={file?.url}
        alt={file?.public_id}
        className={"file-image " + className}
      />
      {/* Additional logic for responsive images */}
      {smallImg && (
        <>
          {file?.responsive && (
            <picture>
              {file?.responsive?.map((url, index) => (
                <source
                  key={index}
                  srcSet={url}
                  media={`(max-width: ${index * 100}px)`}
                />
              ))}
              <img
                src={file?.url}
                alt={file?.public_id}
                className="file-image"
              />
            </picture>
          )}
        </>
      )}
    </div>
  );
};

export default FileDisplay;
