import axios from "axios";
import React, { useEffect, useState } from "react";
import Comment from "./Comment";
import client from "../Api/client";

const CommentList = ({ articleId }) => {
  const [comments, setComments] = useState([]);
  const [newCommentText, setNewCommentText] = useState("");
  const [visibleComments, setVisibleComments] = useState(3);

  useEffect(() => {
    fetchComments();
  }, [articleId]);

  const fetchComments = async () => {
    try {
      const response = await client.get(`/comments/${articleId}`);
      setComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleNewCommentSubmit = async () => {
    if (!newCommentText.trim()) {
      alert("Comment cannot be empty");
      return;
    }

    try {
      await client.post("/comment", {
        articleId,
        commentText: newCommentText,
      });
      setNewCommentText("");
      fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleReplySubmit = async (parentCommentId, replyText) => {
    if (!replyText.trim()) {
      alert("Reply cannot be empty");
      return;
    }

    try {
      await client.post("/comment", {
        articleId,
        commentText: replyText,
        parentCommentId,
      });
      fetchComments();
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  const handleShowMoreComments = () => {
    setVisibleComments((prev) => prev + 10);
  };

  const handleShowLessComments = () => {
    setVisibleComments(3); // Reset to initial 3 comments
  };

  return (
    <div className="max-w-xl mx-auto p-4">
      <p className="uppercase font-bold text-xl text-black mb-2 border-b-4 border-blue-300 w-28">
        Comment
      </p>

      <div className="mb-4">
        <input
          type="text"
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
          placeholder="Add a comment..."
          className="border border-gray-300 rounded-lg px-3 py-5 w-full text-sm"
        />
        <button
          onClick={handleNewCommentSubmit}
          className="bg-blue-500 text-white text-sm rounded-lg px-3 py-1 mt-2"
        >
          Submit
        </button>
      </div>
      {comments.slice(0, visibleComments).map((comment) => (
        <Comment
          key={comment._id}
          comment={comment}
          onReply={handleReplySubmit}
        />
      ))}
      {comments.length > visibleComments ? (
        <button
          onClick={handleShowMoreComments}
          className="text-blue-500 text-sm mt-4 hover:underline"
        >
          Show more comments
        </button>
      ) : (
        visibleComments > 3 && (
          <button
            onClick={handleShowLessComments}
            className="text-blue-500 text-sm mt-4 hover:underline"
          >
            Show less comments
          </button>
        )
      )}
    </div>
  );
};

export default CommentList;
