import React, { useEffect, useState } from "react";
import { fetchPendingNewsList } from "../../../Api/createNews";
import Pagination from "./Pagination";
import ManageNewsTable from "./ManageNewsTable";

const PendingNews = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const itemsPerPage = 5;

  useEffect(() => {
    fetchNewsData();
  }, [currentPage]);

  const fetchNewsData = async () => {
    try {
      const { news, totalPages } = await fetchPendingNewsList(
        currentPage,
        itemsPerPage
      );
      setNewsData(news);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <div className="mt-12 mx-4">
      <h1 className="text-3xl font-bold mb-4">Pending News</h1>
      <ManageNewsTable data={newsData} setData={setNewsData} />
      <Pagination pageCount={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default PendingNews;
