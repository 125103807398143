import React, { useState } from "react";
import { Link } from "react-router-dom";
import FileDisplay from "../../Helpers/FileDisplay";
import TextLoader from "../../Helpers/TextLoader";
import TextLoaderError from "../../Helpers/TextLoaderError";
import VideoDisplay from "../../Helpers/VideoDisplay";
import { Video } from "react-feather";

const MidCard = ({
  link,
  imageSrc,
  tags,
  bioName,
  text,
  horizontal,
  color,
  loading,
  db,
  video,
  className,
  bioId,
}) => {
  const [videoDuration, setVideoDuration] = useState(null);

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  const content = (
    <div
      className={`${horizontal ? "p-2" : ""} ${
        color ? "bg-black" : "" + className
      }`}
    >
      <div className="relative w-305 h-171 group">
        {db ? (
          video ? (
            <div className={"relative " + className}>
              <VideoDisplay
                image={imageSrc}
                video={video} // Pass video object for VideoDisplay
                className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                handleLoadedMetadata={handleLoadedMetadata}
                showControls={false}
              />
              {videoDuration && (
                <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                  <Video className="mr-1 text-white" size={11} />
                  <span className="text-white font-bold text-xs">
                    {videoDuration}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className={className}>
              <FileDisplay
                file={{
                  ...imageSrc,
                  isVideo: !!video,
                  duration: video?.duration,
                }}
                className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
              />
            </div>
          )
        ) : (
          <div className={className}>
            <img
              src={imageSrc}
              alt=""
              className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
            />
          </div>
        )}

        {tags && tags?.length > 0 && (
          <div className="absolute bottom-0 left-0">
            {tags?.slice(0, 2)?.map((tag, index) => (
              <span
                key={index}
                className="bg-white text-red-600 text-xs px-2 mr-1 py-1 font-bold"
              >
                {tag}
                {index !== tags?.slice(0, 2).length - 1 && ", "}
              </span>
            ))}
          </div>
        )}
        {bioName && bioId && (
          <div className="absolute bottom-0 left-0">
            <span className="bg-white text-red-600 text-xs px-2 mr-1 py-1 font-bold cursor-pointer hover:underline">
              {bioName}
            </span>
          </div>
        )}
      </div>
      <div>
        <h4
          className={`md:text-xl font-bold mb-2 mt-2 ${
            color ? "text-white" : ""
          } hover:underline hover:text-gray-700`}
        >
          {loading ? (
            <TextLoader className="mx-auto text-center !bg-transparent" />
          ) : (
            text
          )}
        </h4>
      </div>
    </div>
  );

  return link ? <Link to={link}>{content}</Link> : content;
};

export default MidCard;
