import React, { useState, useEffect, useCallback } from "react";
import CreatableSelect from "react-select/creatable";
import { useDropzone } from "react-dropzone";
import { commonInputClasses, commonModalClasses } from "../../utils/theme";
import Label from "../../Form/Label";
import { getCategories, getSubCat } from "../../Api/createNews";
import { toast } from "react-toastify";
import Submit from "../../Form/Submit";
import client from "../../Api/client";

const UploadImage = () => {
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [altText, setAltText] = useState("");
  const [files, setFiles] = useState([]);
  const [busy, setBusy] = useState(false);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    // console.log(e.target.value);
  };
  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
    // console.log(e.target.value);
  };

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryResponse = await getCategories();
        if (Array.isArray(categoryResponse)) {
          setCategory(categoryResponse);
        } else {
          toast.error("Unexpected data format for categories");
          setCategory([]);
        }
      } catch (error) {
        toast.error("Failed to fetch categories");
        setCategory([]);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedCategory) {
          const subcategoryResponse = await getSubCat(selectedCategory);
          setSubCategory(subcategoryResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchSubcategories();
  }, [selectedCategory]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    ]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemoveImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const tags = selectedTags?.map((tag) => tag?.value);
    const formData = new FormData();

    files.forEach(({ file }) => {
      formData.append("images", file);
    });

    formData.append("title", title);
    formData.append("caption", caption);
    formData.append("category", selectedCategory);
    formData.append("subCategory", selectedSubCategory);
    formData.append("tags", tags);
    formData.append("altText", altText);

    try {
      setBusy(true);
      const response = await client.post("/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      setTitle("");
      setCaption("");
      setSelectedCategory("");
      setSelectedSubCategory("");
      setSelectedTags([]);
      setAltText("");
      setFiles([]);
    } catch (error) {
      toast.error("Failed to upload image");
    } finally {
      setBusy(false);
    }
  };

  return (
    <div
      className={
        "w-full mt-24 flex items-center flex-col md:ml-10 " + commonModalClasses
      }
    >
      <h2 className="text-xl font-semibold mb-4">Upload New Image</h2>
      <form
        onSubmit={handleSubmit}
        className={
          "space-y-4 h-96 md:w-6/12 overflow-x-auto " + commonModalClasses
        }
      >
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={"w-full border-b-2 border-double " + commonInputClasses}
          required
        />
        <textarea
          placeholder="Caption"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          className={"w-full p-2 border rounded " + commonInputClasses}
        />
        <div>
          <Label htmlFor="Category">Category</Label>
          <select
            id="Category"
            name="Category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full border-2 bg-white border-light-subtle focus:border-primary p-1 pr-10 outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
          >
            <option value="" disabled>
              Select Category
            </option>
            {category &&
              category?.map((category, index) => (
                <option value={category?.title} key={index}>
                  {category?.title}
                </option>
              ))}
          </select>
        </div>
        <div>
          <Label htmlFor="SubCategory">Sub Category</Label>
          <select
            id="SubCategory"
            name="SubCategory"
            value={selectedSubCategory}
            onChange={handleSubCategoryChange}
            className="w-full border-2 bg-white border-light-subtle focus:border-primary p-1 !pr-10 outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
          >
            <option value="" disabled>
              Select Sub Category
            </option>
            {subCategory &&
              subCategory?.map((subcategory, index) => (
                <option value={subcategory?.name} key={index}>
                  {subcategory?.name}
                </option>
              ))}
          </select>
        </div>
        <div className="w-full mr-2">
          <Label htmlFor="tags">Tags</Label>
          <CreatableSelect
            id="tags"
            isMulti
            // options={allTags?.map((tag) => ({
            //   label: tag?.name,
            //   value: tag?._id,
            // }))}
            value={selectedTags}
            onChange={handleTagChange}
            placeholder="Enter or select tags"
            className="border-2 bg-white focus:border-primary p-1  outline-none transition rounded bg-transparent text-light-subtle focus:!text-primary"
          />
        </div>
        <input
          type="text"
          placeholder="Alt Text"
          value={altText}
          onChange={(e) => setAltText(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
        <div
          {...getRootProps()}
          className="flex items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-sm text-gray-500 dark:!text-gray-400">
              Drop the files here ...
            </p>
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          {files.map((fileWrapper, index) => (
            <div key={index} className="relative">
              <img
                src={fileWrapper.preview}
                alt={`preview ${index}`}
                className="w-full h-32 object-cover"
              />
              <button
                onClick={() => handleRemoveImage(index)}
                type="button"
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 py-1"
              >
                X
              </button>
            </div>
          ))}
        </div>
        <Submit busy={busy} value={"Upload Image"} type="submit" />
      </form>
    </div>
  );
};

export default UploadImage;
