import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import client from "../Api/client";

const ImageDetails = () => {
  const { imageId } = useParams();
  const [imageData, setImageData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchImageDetails = async () => {
      try {
        const response = await client.get(`/image/${imageId}`);
        setImageData(response?.data?.image);
      } catch (error) {
        console.error("Error fetching image details:", error);
      }
    };

    fetchImageDetails();
  }, [imageId]);

  const handleNext = () => {
    if (imageData && currentIndex < imageData.files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (imageData && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const formatPublishedDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "h:mm a 'EST,' EEEE MMMM d, yyyy");
  };

  if (!imageData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
        <div className="md:flex">
          <div className="md:w-1/12 flex items-center justify-center text-lg font-semibold text-gray-800">
            {currentIndex + 1}/{imageData?.files?.length}
          </div>
          <div className="md:w-7/12">
            <img
              src={imageData?.files[currentIndex]?.url}
              alt={imageData?.altText}
              className="w-full h-64 object-cover md:h-auto transition-all ease-in-out"
              style={{ transition: "transform 2s ease-in-out" }} // Adding transition for slide effect
            />
          </div>
          <div className="md:w-4/12 p-4">
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">
              {imageData?.title}
            </h1>
            <p className="text-gray-600 mb-4">{imageData?.caption}</p>
            {/* Published Date */}
            <p className="text-gray-500 text-sm mb-4">
              Published {formatPublishedDate(imageData?.createdAt)}
            </p>
            <div className="flex space-x-2">
              {imageData?.tags?.map((tag) => (
                <span
                  key={tag?._id?.$oid}
                  className="inline-block bg-gray-200 text-gray-800 text-xs font-semibold rounded-full px-2 py-1"
                >
                  #{tag?.name}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Navigation Arrows */}
        <div className="absolute bottom-4 right-4 flex space-x-4">
          <button
            onClick={handlePrev}
            className={`${
              currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
            } p-2 bg-gray-300 rounded-full shadow-lg`}
            disabled={currentIndex === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-gray-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            className={`${
              currentIndex === imageData?.files?.length - 1
                ? "opacity-50 cursor-not-allowed"
                : ""
            } p-2 bg-gray-300 rounded-full shadow-lg`}
            disabled={currentIndex === imageData?.files?.length - 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-gray-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageDetails;
