import client from "./client";

export const createNews = async (formData, onUploadProgress) => {
  try {
    const { data } = await client.post("/createnews", formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: ({ loaded, total }) => {
        if (onUploadProgress)
          onUploadProgress(Math.floor((loaded / total) * 100));
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const videoUpload = async (videoData, onUploadProgress) => {
  try {
    const { data } = await client.post("/upload-video", videoData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: ({ loaded, total }) => {
        if (onUploadProgress)
          onUploadProgress(Math.floor((loaded / total) * 100));
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSubCat = async (selectedNewsCategory) => {
  try {
    const { data } = await client.get(
      `/getsubcategories/${selectedNewsCategory}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const addType = async (name) => {
  try {
    const { data } = await client.post(
      "/addType",
      { name },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getTypes = async () => {
  try {
    const { data } = await client.get("/types", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const deleteType = async (TypeId) => {
  try {
    const { data } = await client.delete(`/deleteType/${TypeId}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const addCategories = async (name, subcategories, parentCategory) => {
  try {
    const { data } = await client.post(
      "/addCategories",
      { name, subcategories, parentCategory },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const updateCategory = async (categoryId, categoryName) => {
  try {
    const { data } = await client.put(
      `/updateCategory/${categoryId}`,
      {
        categoryName: categoryName,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const updateSubCat = async (
  categoryId,
  subcategoryId,
  subcategoryName
) => {
  try {
    const { data } = await client.put(
      `/updateSubCategory/${categoryId}/subcategory/${subcategoryId}`,
      {
        subcategoryName: subcategoryName,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getCategories = async () => {
  try {
    const { data } = await client.get("/getAllNewsCategories", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getCategoriesWithSub = async () => {
  try {
    const { data } = await client.get("/AllCategoriesWithSubCategory", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const deleteCategory = async (categoryId) => {
  try {
    const { data } = await client.delete(`/deleteCategories/${categoryId}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const deleteSubCat = async (categoryId, subcategoryId) => {
  try {
    const { data } = await client.delete(
      `/categories/${categoryId}/subcategories/${subcategoryId}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getLastFiveLiveUpdateNewsType = async () => {
  try {
    const { data } = await client.get("/getLastFiveLiveUpdateNewsType", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getLiveUpdateHeadLine = async (showHeadLine) => {
  try {
    const { data } = await client.get(`/getHeadline/${showHeadLine}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
// export const getLastFiveLiveUpdateNewsType = async (selectedLiveUpdateType) => {
//   try {
//     const { data } = await axios.get(`/api/getLastFiveLiveUpdateNewsType`, {
//       withCredentials: true,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       params: { selectedLiveUpdateType }, // Pass parameters if necessary
//     });
//     return data;
//   } catch (error) {
//     const { response } = error;
//     if (response?.data) return response.data;

//     return { error: error.message || error };
//   }
// };

export const fetchNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/newsList", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const fetchAllNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/allNewsList", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const fetchPendingNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/pending", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const fetchRejectedNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/rejected", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const fetchApprovedNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/approved", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const approveArticle = async (approvedItemId) => {
  try {
    const { data } = await client.put(`/approve/${approvedItemId}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const rejectArticle = async (rejectedItemId) => {
  try {
    const { data } = await client.put(`/reject/${rejectedItemId}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getArticleById = async (id) => {
  try {
    const { data } = await client.get(`/getNewsByArticleId/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getNewsById = async (id) => {
  try {
    const { data } = await client.get(`/getNewsByID/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const updateNews = async (id, newsData) => {
  try {
    const { data } = await client.patch("/update/" + id, newsData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const getNewsForUpdate = async (id) => {
  try {
    const { data } = await client.get("/for-update/" + id, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const addToRecycleBin = async (id) => {
  try {
    const { data } = await client.delete(`/recycle-bin/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const fetchDeletedArticles = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/recycle-bin", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const deleteArticle = async (id) => {
  try {
    const { data } = await client.delete(`/news/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const restoreArticle = async (id) => {
  try {
    const { data } = await client.patch(`/news/restore/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
