import React, { useEffect, useState } from "react";
import { fetchSiteSettings } from "../Api/apis";

const SEOComponent = () => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetchSiteSettings();
        setSettings(response);
      } catch (error) {
        console.error("Error fetching settings", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      document.title = settings?.siteTitle || "Newsvist";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          settings?.siteDescription || "Newsvist Description"
        );
      document
        .querySelector('link[rel="icon"]')
        .setAttribute("href", settings?.siteLogo || "/default-logo.png");
    }
  }, [settings]);

  return null;
};

export default SEOComponent;
