import React, { useState } from "react";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import {
  addToRecycleBin,
  deleteArticle,
  restoreArticle,
} from "../../../Api/createNews";

const RecycleTable = ({
  data,
  setData,
  onPageChange,
  afterDelete,
  afterUpdate,
}) => {
  const [deletedItemId, setDeletedItemId] = useState(null);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [busy, setBusy] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const filteredData = data?.filter(
    (item) =>
      item?.title &&
      item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const paginatedData = filteredData?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const displayConfirmModal = (id) => {
    setDeletedItemId(id);
    setShowConfirmModal(true);
  };

  const handleOnDeleteConfirm = async () => {
    setBusy(true);
    const { error, message } = await deleteArticle(deletedItemId);
    setBusy(false);

    if (error) return toast.error("error", error);

    hideConfirmModal();
    toast.success("success", message);
    afterDelete(data);
  };

  const restoreNews = async (id) => {
    try {
      const data = await restoreArticle(id);
      toast.success("News restored successfully.");
      afterUpdate(); // Fetch updated news data
    } catch (error) {
      toast.error("Error restoring news.");
    }
  };

  function formatDate(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = new Date(dateTimeString)?.toLocaleDateString(
      undefined,
      options
    );

    return formattedDate;
  }

  const hideConfirmModal = () => setShowConfirmModal(false);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search news..."
            className="border p-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-start">Title</th>
              <th className="py-2 px-4 border-b text-start">Category</th>
              <th className="py-2 px-4 border-b text-start">Author</th>
              <th className="py-2 px-4 border-b text-start">Delete Date</th>
              <th className="py-2 px-4 border-b text-center pl-12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{item?.title}</td>
                <td className="py-2 px-4 border-b">{item?.newsCategory}</td>
                <td className="py-2 px-4 border-b">{item?.authorName}</td>
                <td className="py-2 px-4 border-b">
                  {formatDate(item?.deletedAt)}
                </td>

                <td className="py-2 px-4 border-b flex self-end justify-end">
                  <button
                    onClick={() => restoreNews(item?._id)}
                    className="text-green-500 hover:underline flex"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 12h-15"
                      />
                    </svg>
                    <div>Restore</div>
                  </button>
                  <button
                    onClick={() => displayConfirmModal(item?._id)}
                    className="text-red-500 hover:underline flex"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                    <div>Trash</div>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination pageCount={pageCount} onPageChange={handlePageChange} /> */}
      <div className="p-0">
        <ConfirmModal
          visible={showConfirmModal}
          onConfirm={handleOnDeleteConfirm}
          onCancel={hideConfirmModal}
          title="Are you sure?"
          subtitle="This action will remove this news permanently!"
          busy={busy}
        />
      </div>
    </>
  );
};

export default RecycleTable;
