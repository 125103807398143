import React, { useState } from "react";
import { initiatePasswordReset } from "../../Api/auth";
import { toast } from "react-toastify";
import { Loader } from "react-feather";

const InitiateReset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const initiateReset = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await initiatePasswordReset(email);
      toast.success("Password reset link sent to your email");
      if (response.error) {
        toast.error(response.error);
        throw new Error(response.error);
      }
    } catch (error) {
      toast.error("Error initiating password reset", error);
      console.error("Error initiating password reset", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <main id="content" role="main" className="w-full  max-w-md mx-auto p-6">
      <div className="mt-7 bg-white  rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700 border-2 border-indigo-300">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
              Forgot password?
            </h1>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Remember your password?
              <a
                className="text-blue-600 decoration-2 hover:underline font-medium"
                href="/login"
              >
                Login here
              </a>
            </p>
          </div>
          <div className="mt-5">
            <form>
              <div className="grid gap-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold ml-1 mb-2 dark:text-white"
                  >
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                      required
                      aria-describedby="email-error"
                    />
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="email-error"
                  >
                    Please include a valid email address so we can get back to
                    you
                  </p>
                </div>
                <button
                  type="button"
                  onClick={initiateReset}
                  className="py-3 px-4 inline-flex justify-center items-center text-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                >
                  {loading ? (
                    <Loader className="animate-spin" />
                  ) : (
                    "Reset password"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default InitiateReset;
