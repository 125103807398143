import axios from "axios";
import client from "./client";

export const fetchEditorNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/editor-newsList", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const fetchNewsList = async (currentPage, itemsPerPage) => {
  try {
    const { data } = await client.get("/editor-newsList", {
      withCredentials: true,
      params: { page: currentPage + 1, pageSize: itemsPerPage },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
