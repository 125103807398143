// PushNotification.js
import { useEffect, useState } from "react";
import client from "../Api/client";
import { toast } from "react-toastify";
import { useSiteSettings } from "../Hooks/Settings";

function PushNotification() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { siteLogo } = useSiteSettings();

  // Show popup after 5 seconds (5000ms) of page visit
  useEffect(() => {
    const timer = setTimeout(() => {
      if (Notification.permission !== "granted" && !isSubscribed) {
        setIsPopupVisible(true);
      }
    }, 10000); // Show popup after 5 seconds

    return () => clearTimeout(timer); // Clear the timer when component unmounts
  }, [isSubscribed]);

  // Handle "OK" button to subscribe the user
  const handleOk = async () => {
    setIsPopupVisible(false);

    if ("serviceWorker" in navigator && "PushManager" in window) {
      try {
        const registration = await navigator.serviceWorker.register("/sw.js");

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BGDMj_ekx6U1UrOvhgyu-AfKk7LE2ZSQY9jfZvoThy8rCQC_5lXKOCMzj5ZYvtN3RH0dvH8B9OrJBaix2p031Xc",
        });

        // Send the subscription to your server
        await client.post("/subscribe", subscription);
        setIsSubscribed(true);
        toast.success(
          "Subscription successful! You'll receive the latest updates."
        );
      } catch (error) {
        console.error("Failed to subscribe the user: ", error);
      }
    } else {
      console.error("Push messaging is not supported");
    }
  };

  // Handle "Decline" button
  const handleDecline = () => {
    setIsPopupVisible(false);
  };

  if (!isPopupVisible) return null;

  return (
    <div className="fixed inset-x-0 top-0 bg-transparent bg-opacity-75 flex justify-center items-center z-50 p-4">
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg flex items-center max-w-xs md:max-w-md w-full">
        {/* Logo Section */}
        <img
          src={siteLogo ? siteLogo : "/default-logo.png"}
          alt="nv Logo"
          className="w-12 h-12 md:w-16 md:h-16 rounded-full object-cover mr-4"
        />

        {/* Text and Buttons Section */}
        <div className="flex flex-col w-full">
          <h3 className="text-sm md:text-lg font-semibold mb-2 md:mb-4">
            Stay informed with the latest updates from NEWS VIST
          </h3>
          <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4">
            <button
              onClick={handleDecline}
              className="bg-gray-300 text-black px-3 py-1.5 md:px-4 md:py-2 rounded hover:bg-gray-400 transition"
            >
              Decline
            </button>
            <button
              onClick={handleOk}
              className="bg-blue-500 text-white px-3 py-1.5 md:px-4 md:py-2 rounded hover:bg-blue-700 transition"
            >
              Sure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PushNotification;
