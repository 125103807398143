import React from "react";
import { Helmet } from "react-helmet-async";
import { useSiteSettings } from "../Hooks/Settings";

const TermsOfService = () => {
  const { siteTitle } = useSiteSettings();
  return (
    <div className="terms-of-service">
      <Helmet>
        <title>
          {siteTitle} {`- Terms of Service`}
        </title>
        <meta property="og:site_title" content={siteTitle} />
      </Helmet>
      <h1>Terms of Service</h1>
      <p>Last updated: [Sep/11/2024]</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        Welcome to www.newsvist.com. By accessing or using our website, you
        agree to be bound by these Terms of Service and our Privacy Policy. If
        you do not agree with these terms, please do not use our website.
      </p>

      <h2>2. Use of Our Website</h2>
      <p>
        You agree to use our website in compliance with all applicable laws and
        regulations. You will not use our website for any unlawful purpose or in
        a way that could damage, disable, or impair our services or interfere
        with other users' enjoyment.
      </p>

      <h2>3. Content and Intellectual Property</h2>
      <p>
        All content, including text, graphics, logos, and images, is the
        property of www.newsvist.com or its licensors and is protected by
        copyright and intellectual property laws. You may not reproduce,
        distribute, or create derivative works from any content without our
        prior written consent.
      </p>

      <h2>4. User Contributions</h2>
      <p>
        Users may contribute content to our website, such as comments or
        articles. By submitting content, you grant us a perpetual, royalty-free,
        worldwide license to use, modify, and distribute such content. You are
        solely responsible for any content you submit and agree not to submit
        any content that is unlawful, defamatory, or infringes on third-party
        rights.
      </p>

      <h2>5. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services. We
        are not responsible for the content or practices of any third parties
        and do not endorse or guarantee their services.
      </p>

      <h2>6. Disclaimers and Limitation of Liability</h2>
      <p>
        Our website is provided "as is" and "as available" without any
        warranties, express or implied. We disclaim all warranties, including
        but not limited to, implied warranties of merchantability and fitness
        for a particular purpose. In no event shall www.newsvist.com be liable
        for any indirect, incidental, or consequential damages arising out of or
        in connection with your use of our website.
      </p>

      <h2>7. Changes to the Terms</h2>
      <p>
        We may update these Terms of Service from time to time. Any changes will
        be posted on this page with an updated effective date. Your continued
        use of our website after any changes constitutes your acceptance of the
        new terms.
      </p>

      <h2>8. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to our website
        at our sole discretion, without notice, for any reason, including if you
        violate these Terms of Service.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of Nigeria, without regard to its conflict of law principles.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, please contact
        us at:
      </p>
      <p>Email: verboxupdates@gmail.com</p>
    </div>
  );
};

export default TermsOfService;
