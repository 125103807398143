import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import socket from "../Helpers/Socket";
import Footer from "../Common/Footer/Footer";
import NewUpdateBanner from "../Helpers/NewUpdateBanner";
import FileDisplay from "../Helpers/FileDisplay";
import TimeComponent from "../Helpers/TimeComponent";
import PulsatingDot from "../Helpers/PulsatingDot";
import Header from "../Common/Header/Header";
import client from "../Api/client";
import TextOnly from "../components/Cards/TextOnly";
import { getAllLiveUpdate } from "../Api/news";

const LiveNews = () => {
  const [news, setNews] = useState([]);
  const [prev, setPrev] = useState([]);
  const [firstNews, setFirstNews] = useState([]);

  const [newUpdate, setNewUpdate] = useState(false);
  // const liveUpdateType = "Israel-hamas-war";
  const { liveUpdateType } = useParams();

  const getAllNews = async () => {
    try {
      const response = await client.get(
        `/getNewsByLiveUpdateType/${liveUpdateType}`
      );
      setNews(response.data);
      // ...............End For displaying file................

      // return allNews;
    } catch (error) {
      console.error("Error fetching all news:", error.message);
      throw error;
    }
  };

  const getAllLiveNews = async () => {
    try {
      const res = await getAllLiveUpdate();
      setPrev(res);
    } catch (error) {
      console.error("Error fetching prev news:", error.message);
      throw error;
    }
  };
  const getOldestNewsArticleByType = async (liveUpdateType) => {
    try {
      const response = await client.get(
        `/getOldestNewsArticleByType/${liveUpdateType}`
      );
      setFirstNews(response.data);
    } catch (error) {
      console.error("Error fetching oldest news:", error.message);
      throw error;
    }
  };
  useEffect(() => {
    // When the component mounts, subscribe to live updates for a specific type

    getOldestNewsArticleByType(liveUpdateType);
    getAllNews();
    getAllLiveNews();
  }, [liveUpdateType]);

  socket.on("liveNewsUpdate", () => {
    getAllNews();
    getAllLiveNews();
    setNewUpdate(true);
  });

  const handleLinkClick = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  // Parse the createdAt value as a UTC date and time
  const createdAt = news[0]?.createdAt;

  // Use Intl.DateTimeFormat to format the date and time according to the ET timezone
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  // Check if createdAt is a valid date string before formatting
  const formatted = createdAt ? formatter.format(new Date(createdAt)) : null;

  const [showFooterSearch, setShowFooterSearch] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleSearchButtonClick = () => {
    setShowFooterSearch(!showFooterSearch);
  };

  const handleFooterSearch = () => {
    // Do something with the search text, e.g., navigate to search results page
    // ...

    // For example, update the state with the search text
    setSearchText(searchText);
  };

  // console.log("LiveUpdate:", firstNews);
  return (
    <>
      <Header onSearchButtonClick={handleSearchButtonClick} />
      {!showFooterSearch && (
        <>
          <div className="mt-16 mx-12 flex">
            <div className="w-7/12 h-full ">
              <div className="flex items-center mb-3">
                <PulsatingDot />{" "}
                <span className="tracking-wide font-bold text-xl ml-2">
                  {" "}
                  Live Update
                </span>
              </div>
              <h1 className="font-bold md:text-5xl text-base leading-tight ">
                <strong>{firstNews?.liveUpdateHeadline}</strong>
              </h1>
              <div className="flex">
                <div className="md:w-10 md:h-10 w-8 h-8 rounded-full overflow-hidden">
                  <img
                    src={firstNews?.user?.profilePhoto}
                    className="object cover w-full h-full"
                    alt="user img"
                  />
                </div>
                <div className=" text-gray-400 mt-2 md:text-base text-sm">
                  By{" "}
                  <span className="underline">
                    <Link
                      to={`/profiles/${firstNews?.authorName}`}
                      state={{ user: firstNews?.user }}
                    >
                      {firstNews?.authorName}
                    </Link>
                  </span>
                  , NEWSVIST
                </div>
              </div>
              <div className=" text-gray-400 mt-2 md:text-base text-sm">
                {" "}
                {formatted ? `Updated ${formatted}` : "Invalid date format"}
              </div>
            </div>
            <div className="w-5/12 h-full ">
              {" "}
              <div className=" relative w-305 h-171 group mb-4 ">
                <FileDisplay file={firstNews?.file} />

                <div className="mb-2 mt-2 font-bold text-xs text-gray-600 hover:underline">
                  {" "}
                  Catch up on previous global news
                </div>
                {prev?.slice(1, 7).map((item, index) => (
                  <TextOnly
                    key={`catLive-${index}`}
                    link={`/live/${item?.liveUpdateType}`}
                    text={item?.title}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="mt-16 mx-12 flex md:flex-nowrap flex-wrap">
            <div className="md:w-4/12 w-full p-6 m-5 border-b border-t-4 border-t-red-600 border-l border-r rounded">
              <h1 className="text-2xl">What we covered here</h1>
              <div className="sticky top-16 h-screen">
                <ul className="mt-8 overflow-y-auto">
                  {news?.map((item, index) => (
                    <a
                      key={index}
                      href={`#${item?._id}`}
                      className="cursor-pointer"
                      onClick={(e) => handleLinkClick(e, item?._id)}
                    >
                      <li className="list-disc mb-8 ">{item?.title}</li>
                    </a>
                  ))}
                </ul>
              </div>
            </div>

            <div className="md:w-8/12 w-full">
              <div className="border-b mr-5 ml-5 mb-5 mt-4">
                <div className="border-b-4 w-6 pl-1 border-b-red-600">All</div>
              </div>
              {news?.map((item, index) => {
                return (
                  <div
                    key={index}
                    id={item?._id}
                    className=" p-6 m-5 border-b border-t border-l border-r rounded"
                  >
                    {" "}
                    <TimeComponent timestamp={item?.createdAt} />
                    <div className="text-3xl font-bold mb-4">{item?.title}</div>
                    <FileDisplay file={item?.file} />
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.editorText }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      <Footer
        onSearch={handleFooterSearch}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <NewUpdateBanner newUpdate={newUpdate} />
    </>
  );
};

export default LiveNews;
