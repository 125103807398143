import React, { useState, useEffect } from "react";
import { Edit3, Trash2 } from "react-feather";
import CategoryForm from "./Components/CategoryForm";
import {
  addCategories,
  deleteCategory,
  deleteSubCat,
  getCategoriesWithSub,
  updateCategory,
  updateSubCat,
} from "../Api/createNews";
import { toast } from "react-toastify";

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesWithSub();
      setCategories(response);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleDeleteCategory = async (categoryId) => {
    try {
      const res = await deleteCategory(categoryId);
      toast.success(res);
      // Refresh the categories after deletion
      fetchCategories();
    } catch (error) {
      toast.error("Error deleting category:", error.message);
      console.error("Error deleting category:", error.message);
    }
  };

  const handleDeleteSubcategory = async (categoryId, subcategoryId) => {
    try {
      const res = await deleteSubCat(categoryId, subcategoryId);
      toast.success(res);
      // Refresh the categories after deletion
      fetchCategories();
    } catch (error) {
      toast.error("Error deleting subcategory:", error.message);
      console.error("Error deleting subcategory:", error.message);
    }
  };
  const handleAddCategory = async ({
    categoryName,
    subcategoryName,
    selectedParentCategory,
  }) => {
    try {
      if (subcategoryName && !selectedParentCategory) {
        toast.error("Please select a parent category");
        return;
      }
      if (!subcategoryName && selectedParentCategory) {
        toast.error("Please enter a subcategory name");
        return;
      }
      setLoading(true);
      setBusy(true);
      const res = await addCategories(
        categoryName,
        subcategoryName,
        selectedParentCategory
      );
      setLoading(false);
      setBusy(false);
      if (res?.status === false) {
        toast.error(res?.message);
        return;
      } else {
        toast.success("Category Added Successfully!");
      }
      // Refresh the categories after addition
      fetchCategories();
    } catch (error) {
      setLoading(false);
      setBusy(false);
      toast.error("Error adding category:", error.message);
      console.error("Error adding category:", error.message);
    }
  };

  const handleUpdateCategory = async ({ categoryId, categoryName }) => {
    try {
      const res = await updateCategory(categoryId, categoryName);
      toast.success("Category updated successfully!");
      fetchCategories(); // Refresh categories after update
      setEditMode(false);
      setCurrentCategory(null);
    } catch (error) {
      toast.error("Error updating category:", error.message);
    }
  };

  const handleUpdateSubCategory = async ({
    categoryId,
    subcategoryId,
    subcategoryName,
  }) => {
    try {
      const res = await updateSubCat(
        categoryId,
        subcategoryId,
        subcategoryName
      );
      toast.success("Subcategory updated successfully!");
      fetchCategories(); // Refresh categories after update
      setEditMode(false);
      setCurrentSubCategory(null);
    } catch (error) {
      toast.error("Error updating subcategory:", error.message);
    }
  };

  const handleEditCategory = (category) => {
    setEditMode(true);
    setCurrentCategory(category);
  };

  const handleEditSubCategory = (category, subcategory) => {
    setEditMode(true);
    setCurrentCategory(category);
    setCurrentSubCategory(subcategory);
  };

  return (
    <div className="md:mt-24 md:mx-20 bg-white md:px-4 rounded-md drop-shadow-md h-screen overflow-x-auto">
      <h1 className="text-2xl font-semibold md:my-4">Manage Categories</h1>
      <CategoryForm
        categories={categories}
        onSubmit={handleAddCategory}
        loading={loading}
        busy={busy}
        editMode={editMode}
        currentCategory={currentCategory}
        currentSubCategory={currentSubCategory}
        onUpdateCategory={handleUpdateCategory}
        onUpdateSubCategory={handleUpdateSubCategory}
      />
      <ul className="mt-4">
        <h1 className="text-2xl font-semibold my-4">Category List</h1>
        {categories?.length === 0 ? (
          <p>No categories found.</p>
        ) : (
          categories?.map((category, index) => (
            <li key={index} className="mb-4">
              <div className="flex justify-between items-center bg-gray-100 p-2 rounded-md">
                <span className="text-lg font-semibold">{category?.title}</span>
                <div>
                  <button
                    className="text-blue-500 mr-2"
                    onClick={() => handleEditCategory(category)}
                  >
                    <Edit3 size={16} />
                  </button>
                  <button
                    className="text-red-500"
                    onClick={() => handleDeleteCategory(category?._id)}
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
              <ul className="ml-4">
                {category?.items?.map((subcategory, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <span>{subcategory.name}</span>
                    <div>
                      <button
                        className="text-blue-500 ml-2"
                        onClick={() =>
                          handleEditSubCategory(category, subcategory)
                        }
                      >
                        <Edit3 size={14} />
                      </button>
                      <button
                        className="text-red-500 ml-2 bg-none"
                        onClick={() =>
                          handleDeleteSubcategory(
                            category?._id,
                            subcategory?._id
                          )
                        }
                      >
                        <Trash2 size={14} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default ManageCategories;
