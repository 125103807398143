import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoDisplay from "../Helpers/VideoDisplay";
import { Video } from "react-feather";
import FileDisplay from "../Helpers/FileDisplay";
import TextLoader from "../Helpers/TextLoader";
import TruncateText from "../Helpers/helper";

const CategorySmallCard = ({
  link,
  imageSrc,
  tags,
  text,
  loading,
  video,
  className,
  onClick,
}) => {
  const [videoDuration, setVideoDuration] = useState(null);

  const handleLoadedMetadata = (e) => {
    const duration = e.target.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    setVideoDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  const content = (
    <div className={"mt-2 pt-4 " + className}>
      <div className="m-0 p-0">
        <div className="relative group border-b border-b-[#e6e6e6] mb-3 pb-3 flex max-w-full">
          <div className="md:w-2/5 w-2/3">
            {imageSrc ? (
              video ? (
                <div className="relative object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                  <VideoDisplay
                    image={imageSrc}
                    video={video} // Pass video object for VideoDisplay
                    className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                    handleLoadedMetadata={handleLoadedMetadata}
                    showControls={false}
                  />
                  {videoDuration && (
                    <div className="absolute bottom-0 right-0 flex items-center bg-black bg-opacity-50 py-1 px-2">
                      <Video className="mr-1 text-white" size={11} />
                      <span className="text-white font-bold text-xs">
                        {videoDuration}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50 ">
                  <FileDisplay
                    file={{
                      ...imageSrc,
                      isVideo: !!video,
                      duration: video?.duration,
                    }}
                    // file={imageSrc} // Pass file object for FileDisplay
                    className="md:w-32 h-full object-cover transition-transform transform group-hover:scale-100"
                  />
                </div>
              )
            ) : (
              <div className="object-cover bg-fixed opacity-100 transition duration-300 ease-in-out hover:opacity-50">
                <img
                  src={imageSrc}
                  alt=""
                  className="w-full h-full object-cover transition-transform transform group-hover:scale-100"
                />
              </div>
            )}
          </div>
          <div className="md:w-3/5 w-full md:-ml-10">
            {text ? (
              <h4 className="md:text-base text-sm self-start ml-2 hover:underline hover:text-gray-700">
                {loading ? (
                  <TextLoader className="mx-auto text-center !bg-transparent" />
                ) : (
                  TruncateText(text, 8)
                )}
              </h4>
            ) : null}
            {/* <div className="flex flex-wrap">
                {tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="mx-2 bg-gray-200 rounded px-2 py-1 text-sm"
                  >
                    {tag?.name}
                  </span>
                ))}
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
  return link ? (
    <Link to={link}>{content}</Link>
  ) : (
    <div onClick={onClick}>{content}</div>
  );
};

export default CategorySmallCard;
