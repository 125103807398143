import React from "react";
import { Facebook, Linkedin, Mail, MessageCircle, X } from "react-feather";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";

const Share = ({ url, title, image, hashtag }) => {
  return (
    <div className="flex space-x-4 mt-4">
      <FacebookShareButton url={url} quote={title} hashtag={hashtag}>
        <Facebook className="text-blue-600 text-xl hover:scale-110 transition-transform" />
      </FacebookShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        via="newsvistupdates"
        hashtags={[hashtag]}
        related={["newsvistupdates"]}
      >
        <X className="text-blue-400 text-xl hover:scale-110 transition-transform" />
      </TwitterShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        summary={title}
        source={image}
      >
        <Linkedin className="text-blue-700 text-xl hover:scale-110 transition-transform" />
      </LinkedinShareButton>

      <EmailShareButton
        url={url}
        subject={title}
        body={`Check out this article: ${url}\n\n${title}\n`}
      >
        <Mail className="text-red-500 text-xl hover:scale-110 transition-transform" />
      </EmailShareButton>

      <WhatsappShareButton url={url} title={title} separator=":: ">
        <MessageCircle className="text-green-500 text-xl hover:scale-110 transition-transform" />
      </WhatsappShareButton>
    </div>
  );
};

export default Share;
