import React, { useState } from "react";

const Comment = ({ comment, onReply }) => {
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [visibleReplies, setVisibleReplies] = useState(3);

  const handleReplyClick = () => {
    setShowReplyBox(!showReplyBox);
  };

  const handleReplySubmit = () => {
    onReply(comment._id, replyText);
    setReplyText("");
    setShowReplyBox(false);
  };

  const handleShowMoreReplies = () => {
    setVisibleReplies((prev) => prev + 10);
  };

  const handleShowLessReplies = () => {
    setVisibleReplies(3); // Reset to initial 3 replies
  };

  return (
    <div className="p-4 bg-white shadow rounded-lg mb-4">
      <p className="text-sm">
        <strong className="text-gray-800">
          {comment.isAnonymous
            ? "Anonymous"
            : comment.user?.username || comment.name}
        </strong>{" "}
        : {comment.commentText}
      </p>
      <button
        onClick={handleReplyClick}
        className="text-blue-500 text-sm mt-2 hover:underline"
      >
        Reply
      </button>
      {showReplyBox && (
        <div className="mt-2">
          <input
            type="text"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            placeholder="Write a reply..."
            className="border border-gray-300 rounded-lg px-3 py-2 w-full text-sm"
          />
          <button
            onClick={handleReplySubmit}
            className="bg-blue-500 text-white text-sm rounded-lg px-3 py-1 mt-2"
          >
            Submit
          </button>
        </div>
      )}
      <div className="ml-6 mt-4">
        {comment.replies.slice(0, visibleReplies).map((reply) => (
          <Comment key={reply._id} comment={reply} onReply={onReply} />
        ))}
        {comment.replies.length > visibleReplies ? (
          <button
            onClick={handleShowMoreReplies}
            className="text-blue-500 text-sm mt-2 hover:underline"
          >
            Show more replies
          </button>
        ) : (
          visibleReplies > 3 && (
            <button
              onClick={handleShowLessReplies}
              className="text-blue-500 text-sm mt-2 hover:underline"
            >
              Show less replies
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default Comment;
