import axios from "axios";
import React, { useEffect, useState } from "react";
import { getDetails, updateUser } from "../Api/auth";
import { toast } from "react-toastify";
import { Loader } from "react-feather";

const UserProfile = ({ userId }) => {
  const [user, setUser] = useState({});
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getDetails(userId);
        const userData = response.user;
        setUser(userData);
        setUsername(userData.username || "");
        setPhone(userData.phone || "");
        setEmail(userData.email || "");
        setBio(userData.bio || "");
        setProfilePhoto(userData.profilePhoto || null);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [userId]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("bio", bio);
    if (profilePhoto) {
      formData.append("profilePhoto", profilePhoto);
    }
    try {
      setLoading(true);
      await updateUser(userId, formData);

      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Update Profile</h1>
      <form onSubmit={handleUpdateProfile} className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-medium">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-medium">Phone</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-medium">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-medium">Bio</label>
          <textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="font-bold">Profile Photo</label>
          <div className="relative flex flex-col mb-4 items-center justify-center">
            {profilePhoto && (
              <div className="flex justify-center mb-4">
                <img
                  src={profilePhoto}
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover"
                />
              </div>
            )}
            <input
              type="file"
              onChange={(e) => setProfilePhoto(e.target.files[0])}
              className="absolute p-2 h-fit w-28 top-20 border border-gray-300 rounded-md"
            />
          </div>
          <button
            type="submit"
            className="w-full p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
          >
            {loading ? <Loader className="animate-spin" /> : "Update Profile"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
