import axios from "axios";
import React, { useEffect, useState } from "react";
import UserTable from "./Components/UserTable";
import { getRoles, getUsers } from "../Api/auth";
import client from "../Api/client";

const ManageRole = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const res = await getUsers();
      setUsers(res);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    // Fetch available roles
    getRoles()
      .then((response) => setRoles(response))
      .catch((error) => console.error("Error fetching roles:", error));
  }, []);

  const handleAssignRole = (userId, selectedRole) => {
    client
      .post(
        `/assignRole/${userId}`,
        {
          role: selectedRole,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data); // Log the server response
        // Handle success, e.g., update the state or show a success message
      })
      .catch((error) => {
        console.error("Error assigning role:", error.message);
        // Handle errors, e.g., show an error message to the user
      });
  };
  return (
    <div className="mt-24 p-6">
      <h1 className="text-xl font-bold mb-4">User Role Management </h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <UserTable
          users={users}
          roles={roles}
          onAssignRole={handleAssignRole}
        />
      )}
    </div>
  );
};

export default ManageRole;
