import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Helmet } from "react-helmet-async";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import { Link, useParams } from "react-router-dom";
import ThirdAdv from "../Home/ThirdAdv";
import TenthSection from "../Home/TenthSection";
import ColumnHead from "../Common/ColumnHead/ColumnHead";
import FileDisplay from "../Helpers/FileDisplay";
import TagSmallCard from "../components/TagSmallCard";
import { getArticle } from "../Api/news";
import VideoDisplay from "../Helpers/VideoDisplay";
import getDateString from "../Helpers/DateString";
import RelatedArticle from "./RelatedArticle";
import NewsAndBuzz from "./NewsAndBuzz";
import UpNext from "../components/Cards/UpNext";
import MostRead from "../components/Cards/MostRead";
import CommentList from "../Comment/CommentList";
import Share from "../Share/Share";
import SquareAd from "../components/SquareAd";

const Article = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [relatedNews, setRelatedNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const articleTags = article?.tags?.map((tag) => tag?._id);
  const articleCategory = article?.newsCategory;

  const wordsPerMinute = 200;
  const words = article?.editorText?.split(" ")?.length || 0;
  const readingTime = Math.ceil(words / wordsPerMinute);

  // Check if createdAt is a valid date
  const date = new Date(article?.createdAt);
  const isValidDate = !isNaN(date);

  // Format the date only if it's valid
  const formattedDate = isValidDate
    ? format(date, "h:mm a 'EST,' EEEE MMMM d, yyyy")
    : "Invalid date";

  const fetchNews = async () => {
    try {
      setLoading(true);
      const response = await getArticle(articleId);
      setArticle(response?.article);
      setRelatedNews(response?.relatedNews);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (articleId) fetchNews();
  }, [articleId]);

  const cityText =
    article?.city && article.city !== "undefined"
      ? `${article.city} (NV)`
      : "(NV)";

  if (loading)
    return (
      <div className="h-screen flex justify-center items-center bg-white">
        <p className="text-light-subtle animate-pulse !bg-transparent">
          Please wait
        </p>
      </div>
    );
  if (error) return <div>Error:{error}</div>;

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const articleTitle = article?.title;
  const articleDescription = `${stripHtmlTags(article?.editorText).substring(
    0,
    160
  )}... read more`;

  const articleUrl = window.location.href;
  const articleImage = article?.file?.url;

  const quote = articleTitle;
  const hashTags = "#newsvistupdates";

  // console.log("profile::", articleImage);

  return (
    <>
      <Header />
      <Helmet>
        <title>{articleTitle}</title>
        <meta name="description" content={articleDescription} />
        {/* Open Graph Tags */}
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_US" />
        <meta property="quote" content={quote} />
        <meta property="og:title" content={articleTitle} />
        <meta property="og:description" content={articleDescription} />
        <meta property="og:url" content={articleUrl} />
        <meta property="og:hashtag" content={hashTags} />
        <meta property="og:quote" content={quote} />
        <meta property="og:image" content={articleImage} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:image:alt" content={articleTitle} />
        <meta property="og:site_name" content="NewsVist" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />

        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={articleTitle} />
        <meta name="twitter:description" content={articleDescription} />
        <meta name="twitter:image" content={articleImage} />
      </Helmet>
      <div className="flex flex-wrap">
        <div className="w-full md:w-3/4 p-4">
          <div className="title">
            <h1 className="md:text-4xl text-2xl font-bold m-4 pb-4 capitalize">
              {article?.title}
            </h1>
          </div>

          <div className="author flex">
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <img
                src={article?.user?.profilePhoto}
                className="object cover w-full h-full"
                alt="user img"
              />
            </div>
            <div className="author-name-date">
              <div className="author-name text-base text-gray-600 pl-2">
                By{" "}
                <span className="underline">
                  <Link
                    to={`/profiles/${article?.authorName}`}
                    state={{ user: article?.user }}
                  >
                    {article?.authorName}
                  </Link>
                </span>{" "}
                <Link
                  to={`/profiles/${article?.authorName}`}
                  state={{ user: article?.user }}
                >
                  {article?.editor ? article?.editor?.username : ""}
                </Link>
                , NEWSVIST
              </div>
              <div className="publish-date text-base text-gray-600 pl-2 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span className="pl-2">
                  {readingTime} minute read Published {formattedDate}
                </span>
              </div>
            </div>
          </div>
          <Share
            url={articleUrl}
            title={articleTitle}
            image={articleImage}
            hashtag={hashTags}
          />
          <div className="content pt-6">
            <div className="image-box bg-gray-100">
              {article?.video ? (
                <VideoDisplay image={article?.file} video={article?.video} />
              ) : (
                <FileDisplay file={article?.file} />
              )}
            </div>
            <div className="ml-2 md:ml-16 mr-2 md:mr-16 mt-4">
              <div>
                <p className="font-bold inline">{cityText} — </p>
                <p
                  dangerouslySetInnerHTML={{ __html: article?.editorText }}
                  className="inline"
                ></p>
              </div>
              {/* TAGS */}
              <div className="flex flex-wrap mt-4">
                <h2 className="text-base font-bold mr-2">Tags: </h2>
                {article?.tags?.map((tag, index) => (
                  <Link to={`/tag/${tag}`} key={index}>
                    <span
                      key={index}
                      className="bg-blue-500 text-white text-xs px-3 py-1 mr-2 mb-2 rounded-full font-semibold"
                    >
                      {tag}
                    </span>
                  </Link>
                ))}
              </div>
              {/* TAGS END */}
              {/* Share Buttons Component */}
              <Share
                url={articleUrl}
                title={articleTitle}
                image={articleImage}
                hashtag={hashTags}
              />
              <RelatedArticle
                articleId={articleId}
                tags={articleTags}
                category={articleCategory}
              />
              <UpNext articleId={articleId} />
              <MostRead />
            </div>
            {/* Share Buttons Component */}
            <Share url={articleUrl} title={articleTitle} />
            {/* Add CommentList component */}
            <CommentList articleId={articleId} />
            <ThirdAdv />
          </div>
        </div>
        <div className="w-full md:w-1/4 p-4 pr-2">
          <div className="mt-12 md:mt-[12.5rem]">
            <ColumnHead columnHeadTag="MORE FROM NEWSVIST" />
          </div>

          <div>
            {relatedNews?.length === 0 ? (
              <div>Not found</div>
            ) : (
              relatedNews?.map((newsItem, index) => {
                return (
                  <TagSmallCard
                    key={index}
                    link={`/${getDateString(newsItem?.createdAt)}/${
                      newsItem?.newsCategory
                    }/${newsItem?._id}`}
                    imageSrc={newsItem?.file}
                    text={newsItem?.title}
                    video={newsItem?.video}
                  />
                );
              })
            )}
          </div>
          <div className="mt-12 md:mt-[12.5rem]">
            <ColumnHead columnHeadTag="NEWS & BUZZ" />
          </div>
          <div>
            <NewsAndBuzz />
          </div>
          <div className="mt-5">
            <Link to="/adv-link">
              <div className="w-full h-64 group mb-4">
                <div className="w-full h-full group">
                  {/* <img
                    src="/images/Article/adv.png"
                    alt=""
                    className="w-full h-full object-cover "
                  /> */}
                  <SquareAd />
                </div>
                {/* <div className="text-xs">Advertisement</div> */}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Article;
