import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./Home/Home";
import Article from "./Article/Article";
import Register from "./AuthPages/Register";
import Login from "./AuthPages/Login";
import Admin from "./Admin/Admin";
import Search from "./Search/Search";
import LiveNews from "./LiveNews/LiveNews";
import Category from "./CategoryPage/Category";
import Writer from "./Writer/Writer";
import Editor from "./Editor/Editor";
import "react-toastify/dist/ReactToastify.css";
import ImageDetails from "./Article/ImageDetails";
import CategoryPage from "./CategoryPage/CategoryPage";
import InitiateReset from "./components/PasswordReset/InitiateReset";
import ResetPassword from "./components/PasswordReset/ResetPassword";
import SEOComponent from "./components/SEOComponent";
import Application from "./components/Application";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import ContactUs from "./components/ContactUs";
import PushNotification from "./components/PushNotification";
import UserBioPage from "./Article/UserBioPage";
import Tags from "./TagPage/Tags";
import Biography from "./components/BioPage/Biography";
import AllBiography from "./components/BioPage/AllBiography";

function App() {
  return (
    <>
      <SEOComponent />
      <PushNotification />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/:year/:month/:day/:category/:articleId"
          element={<Article />}
        />
        <Route path="/tag/:tagName" element={<Tags />} />
        <Route path="/profiles/:authorName" element={<UserBioPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/search/:searchText" element={<Search />} />
        <Route path="/Admin/*" element={<Admin />} />
        <Route path="/Editor/*" element={<Editor />} />
        <Route path="/Writer/*" element={<Writer />} />
        <Route path="/live/:liveUpdateType" element={<LiveNews />} />
        <Route path="/category/:categoryName" element={<Category />} />
        <Route path="/:category/:subcategory" element={<CategoryPage />} />
        <Route
          path="/image/:year/:month/:day/:category/:imageId"
          element={<ImageDetails />}
        />
        <Route path="/biography/:bioId" element={<Biography />} />
        <Route path="/biographies" element={<AllBiography />} />
        <Route path="/forgot-password" element={<InitiateReset />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/article-publishers-network/V2-VB-africa"
          element={<Application />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfService />} />
        <Route path="/contact-Us" element={<ContactUs />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
