import React, { useEffect, useState } from "react";
import {
  fetchApplicationForARole,
  updateApplicationForARole,
} from "../../Api/apis";

const ManageApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await fetchApplicationForARole();
        setApplications(response.applications);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch applications");
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const handleStatusChange = async (id, status) => {
    setUpdating(true);
    try {
      await updateApplicationForARole(id, status);
      setApplications((prevApps) =>
        prevApps?.map((app) => (app?._id === id ? { ...app, status } : app))
      );
      setUpdating(false);
    } catch (err) {
      setError("Failed to update status");
      setUpdating(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Manage Applications
      </h2>
      <table className="min-w-full bg-white border border-gray-300 rounded-lg">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Name
            </th>
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Role
            </th>
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Age
            </th>
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Country
            </th>
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Reason
            </th>
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Status
            </th>
            <th className="px-6 py-3 text-left text-gray-600 font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {applications?.map((app) => (
            <tr key={app._id} className="border-b">
              <td className="px-6 py-4">
                {app.userId.username ? app.userId.username : "User"}
              </td>
              <td className="px-6 py-4 capitalize">{app.role}</td>
              <td className="px-6 py-4">{app.age}</td>
              <td className="px-6 py-4">{app.country}</td>
              <td className="px-6 py-4">{app.reason}</td>
              <td className="px-6 py-4">
                <select
                  value={app.status}
                  onChange={(e) => handleStatusChange(app._id, e.target.value)}
                  className="p-2 border border-gray-300 rounded-md"
                >
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </td>
              <td className="px-6 py-4">
                <button
                  onClick={() => handleStatusChange(app._id, app.status)}
                  disabled={updating}
                  className={`px-4 py-2 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                    updating
                      ? "bg-gray-400"
                      : "bg-indigo-600 hover:bg-indigo-700"
                  }`}
                >
                  {updating ? "Updating..." : "Update"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageApplications;
