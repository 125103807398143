import React from "react";
import { Link } from "react-router-dom";
import MidCard from "../components/Cards/MidCard";
import TextOnly from "../components/Cards/TextOnly";
import UseNewsFetch from "../Common/FetchNews/UseNewsFetch";
import FileDisplay from "../Helpers/FileDisplay";
import getDateString from "../Helpers/DateString";
import SkeletonLoader from "../Helpers/ImageLoader";
import CrashedPost from "../Helpers/CrashedPost";
import CrackedSkeletonLoader from "../Helpers/CrackedSkeletonLoader";
import TextLoader from "../Helpers/TextLoader";
import TextLoaderError from "../Helpers/TextLoaderError";
import VideoDisplay from "../Helpers/VideoDisplay";

const FirstSection = () => {
  const {
    data: midCards,
    loading: loading1,
    error: error1,
  } = UseNewsFetch(null, null, "General", null, 3, "desc");
  const {
    data: breaking,
    loading: loading2,
    error: error2,
  } = UseNewsFetch(null, null, "Breaking", null, 2, "desc");
  const {
    data: breakingNews,
    loading: loadingBreakingNews,
    error: errorBreakingNews,
  } = UseNewsFetch(null, null, "Breaking", null, 1, "desc");
  const {
    data: textOnly,
    loading: loading3,
    error: error3,
  } = UseNewsFetch(null, null, "General", null, 6, "desc");
  const {
    data: liveUpdate,
    loading: loading4,
    error: error4,
  } = UseNewsFetch(null, null, "LiveUpdate", null, 1, "desc");

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 p-4 ">
          <Link
            to={`/${
              breaking && breaking.length > 0
                ? `${getDateString(breaking[1]?.createdAt)}/${
                    breaking[1]?.newsCategory
                  }/${breaking[1]?._id}`
                : ""
            }`}
          >
            <div className="max-w-screen-md mx-auto p-8">
              <h1 className="md:text-5xl font-bold mb-4 text-black text-center hover:underline">
                {loading2 ? (
                  <TextLoader className="mx-auto text-center !bg-transparent" />
                ) : breaking && breaking?.length > 0 ? (
                  breaking[1]?.title
                ) : error2 ? (
                  <TextLoaderError className="mx-auto text-center" />
                ) : (
                  ""
                )}
              </h1>
              <div className="max-w-screen-md mx-auto mb-3">
                <div className="aspect-w-16 aspect-h-9">
                  {/* <img src={breaking[1]?.file} alt="" /> */}
                  {breaking?.video &&
                  breaking &&
                  breaking?.length > 0 &&
                  breaking[1]?.video ? (
                    <VideoDisplay
                      image={breaking[1]?.file}
                      video={breaking[1]?.video}
                    />
                  ) : (
                    <FileDisplay file={breaking[1]?.file} />
                  )}
                  {/* {breaking && breaking?.length > 0 && breaking[1]?.file && (
                    <FileDisplay file={breaking[1]?.file} />
                  )} */}
                </div>
              </div>
            </div>
          </Link>

          <ul className="px-5">
            {textOnly && textOnly?.length > 0 && (
              <li className="list-disc text-[1.02rem] my-3 hover:underline">
                <Link
                  to={`${getDateString(textOnly[0]?.createdAt)}/${
                    textOnly[0]?.newsCategory
                  }/${textOnly[0]?._id}`}
                >
                  {loading3 ? (
                    <TextLoader className="mx-auto text-center !bg-transparent" />
                  ) : (
                    textOnly[0]?.title
                  )}
                </Link>
              </li>
            )}

            {liveUpdate && liveUpdate?.length > 0 && (
              <li className="list-disc text-[1.02rem] my-3 hover:underline">
                <Link
                  to={`/live/${
                    liveUpdate && liveUpdate?.length > 0
                      ? liveUpdate[0]?.liveUpdateType
                      : ""
                  }`}
                >
                  {liveUpdate && liveUpdate?.length > 0 && (
                    <div>
                      {" "}
                      <span className=" text-red-600 font-bold text-[1rem]">
                        Live Update:{" "}
                      </span>
                      {loading4 ? (
                        <TextLoader className="mx-auto text-center !bg-transparent" />
                      ) : (
                        liveUpdate[0]?.title
                      )}
                    </div>
                  )}
                </Link>
              </li>
            )}

            {midCards?.map((newsItem, index) => (
              <Link
                to={`${getDateString(newsItem?.createdAt)}/${
                  newsItem?.newsCategory
                }/${newsItem?._id}`}
                key={index}
              >
                <li className="list-disc my-3 hover:underline">
                  {loading1 ? (
                    <TextLoader className="mx-auto text-center !bg-transparent" />
                  ) : (
                    newsItem?.title
                  )}
                </li>
              </Link>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-1/3 p-4 ">
          {midCards?.map((card, index) => {
            // console.log("lookin::", card);
            return (
              <MidCard
                key={`cat1-${index}`}
                link={`${getDateString(card?.createdAt)}/${
                  card?.newsCategory
                }/${card?._id}`}
                imageSrc={card?.file}
                video={card?.video}
                text={card?.title}
                loading={loading3}
                db={true}
              />
            );
          })}
        </div>
        <div className="w-full md:w-1/3 p-4 ">
          {/* <Link to="/news-link" className="relative w-305 h-171 group mb-4">
            <video
              autoPlay={true}
              muted
              playsInline
              loop
              controls
              className="w-full  rounded-sm"
            >
              <source src="/videos/FirstSection/video.mp4" />
            </video>
          </Link> */}
          <Link
            to={`/${
              breakingNews && breakingNews.length > 0
                ? `${getDateString(breakingNews[0]?.createdAt)}/${
                    breakingNews[0]?.newsCategory
                  }/${breakingNews[0]?._id}`
                : ""
            }`}
            className="relative w-305 h-171 group mb-4"
          >
            <>
              {breakingNews?.length >= 0 && breakingNews[0]?.video && (
                <VideoDisplay
                  image={breakingNews[0]?.file}
                  video={breakingNews[0]?.video}
                  playVideo={true}
                />
              )}
            </>
          </Link>
          <div>
            <div className="mb-2 mt-4 font-bold text-xl hover:underline">
              Catch up on todays global news
            </div>
            <div>
              {textOnly?.map((card, index) => (
                <TextOnly
                  key={`cat2-${index}`}
                  link={`${getDateString(card?.createdAt)}/${
                    card?.newsCategory
                  }/${card?._id}`}
                  text={
                    loading3 ? (
                      <TextLoader className="mx-auto text-center !bg-transparent" />
                    ) : (
                      card?.title
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstSection;
