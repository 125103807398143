import React from "react";
import Tabs, { Tab } from "../../Helpers/Tabs";
import PendingNews from "./NewsListComponents/PendingNews";
import ApprovedNews from "./NewsListComponents/ApprovedNews";
import RejectedNews from "./NewsListComponents/RejectedNews";

const NewsManagement = () => {
  return (
    <div className="news-management w-full m-0">
      <Tabs>
        <Tab label="Pending News" className="bg-yellow-500">
          <PendingNews />
        </Tab>
        <Tab label="Approved News" className="bg-green-500">
          <ApprovedNews />
        </Tab>
        <Tab label="Rejected News" className="bg-red-500">
          <RejectedNews />
        </Tab>
      </Tabs>
    </div>
  );
};

export default NewsManagement;
