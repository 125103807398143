import React, { useState, useEffect } from "react";
import axios from "axios";
import TypeForm from "./Components/TypeForm";
import { Trash2 } from "react-feather";
import { addType, deleteType, getTypes } from "../Api/createNews";
import { toast } from "react-toastify";

const ManageTypes = () => {
  const [Type, setType] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchType = async () => {
    try {
      const response = await getTypes();
      setType(response);
    } catch (error) {
      console.error("Error fetching Tag:", error.message);
    }
  };
  useEffect(() => {
    fetchType();
  }, []);

  const handleDeleteType = async (TypeId) => {
    try {
      await deleteType(TypeId);
      toast.success("Type deleted successfully!");
      // Refresh the Type after deletion
      fetchType();
    } catch (error) {
      console.error("Error deleting Tag:", error.message);
    }
  };

  const handleAddType = async ({ TypeName }) => {
    try {
      setLoading(true);
      const res = await addType(TypeName);
      setLoading(false);
      if (res?.message === "Internal server error") {
        setLoading(false);
        toast.error(res?.message);
      } else {
        toast.success(`Type Added successfully!`);
      }
      // Refresh the Type after addition
      fetchType();
    } catch (error) {
      setLoading(false);
      toast.error("Error adding Type:", error.message);
      console.error("Error adding Type:", error.message);
    }
  };
  return (
    <div className="md:mt-24 md:mx-20 bg-white md:px-4 rounded-md drop-shadow-md h-screen overflow-x-auto">
      <h1 className="text-2xl font-semibold my-4">Manage Type</h1>
      <TypeForm Types={Type} onSubmit={handleAddType} loading={loading} />
      <ul className="mt-4">
        <h1 className="text-2xl font-semibold my-4">Type List</h1>
        {Type.length === 0 ? (
          <p>No Type found.</p>
        ) : (
          Type.map((Type, index) => (
            <li key={index} className="mb-4">
              <div className="flex justify-between items-center bg-gray-100 p-2 rounded-md">
                <span className="text-lg font-semibold">{Type.name}</span>
                <button
                  className="text-red-500"
                  onClick={() => handleDeleteType(Type._id)}
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default ManageTypes;
