import axios from "axios";
import React, { useEffect, useState } from "react";
import getDateString from "../../Helpers/DateString";
import { Link } from "react-router-dom";
import client from "../../Api/client";

const MostRead = () => {
  const [mostReadArticles, setMostReadArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMostReadArticles = async () => {
      try {
        const response = await client.get("/most-read");
        setMostReadArticles(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch most read articles", error);
        setLoading(false);
      }
    };

    fetchMostReadArticles();
  }, []);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      {mostReadArticles && mostReadArticles?.length > 0 && (
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4 border-b-4 border-red-600 pb-2 w-32">
            Most read
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            {mostReadArticles?.map((article, index) => (
              <div key={index} className="flex flex-col justify-start mb-4">
                <span className="text-red-600 text-xl font-bold">
                  {index + 1}
                </span>
                <Link
                  to={`/${getDateString(article?.createdAt)}/${
                    article?.newsCategory
                  }/${article?._id}`}
                  className="ml-4 text-base leading-tight text-black hover:text-red-600 transition-colors border-b"
                >
                  {article?.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MostRead;
